import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DsvsAuthorizationService } from '../services/authorization.service';

@Injectable()
export class DsvsAuthorizationGuard implements CanActivate {

  constructor(private auth: DsvsAuthorizationService, private router: Router) {
  }

  canActivate(): boolean {
    if (!this.auth.checkLogin()) {
      // console.log ('checklogin:' + this.auth.checkLogin());
      const win = window.open('https://apodev.dsv-service.de/login', '_blank');
      win.focus();
      return false;
    }
    return true;
  }
}
