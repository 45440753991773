import { Injectable } from '@angular/core';
import { forOwn } from 'lodash';
import { WorkflowActionExtension } from '../../interfaces/WorkflowActionExtension';

@Injectable({
  providedIn: 'root'
})
export class WorkflowActionExtensionService {

  private extensions: { [key: string]: WorkflowActionExtension } = {};

  extend(extension: WorkflowActionExtension) {
    this.extensions[extension.identifier] = extension;
  }

  clear() {
    this.extensions = {};
  }

  public get(key: string): WorkflowActionExtension {
    return this.extensions[key];
  }

  public all(): WorkflowActionExtension[] {
    const all = [];
    forOwn(this.extensions, (value /*, key, object*/) => {
      all.push(value);
    });
    return all;
  }

  constructor() {
  }
}
