import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import { Observable, Observer } from 'rxjs';
import { DsvsSettingsComponent } from '../components/settings/settings.component';

@Injectable()
export class DsvsSettingsDeactivateGuard implements CanDeactivate<DsvsSettingsComponent> {

  constructor(private confirmationService: ConfirmationService) {
  }

  canDeactivate(component: DsvsSettingsComponent) {
    if (!component.settingsForm.dirty) {
      return true;
    }

    return new Observable((observer: Observer<boolean>) => {
      this.confirmationService.confirm({
        message: 'Die letzten Ãnderungen wurden noch nicht gespeichert.<br>Wollen Sie diese Seite jetzt verlassen?',
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        }
      });
    });

  }
}
