import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DsvsBreadcrumbService, DsvsTableColumn} from '@dsvs/dsvs-shared-ui-lib';
import {RoleHalPage, RoleUserHalItem, UserHalItem, UserHalPage, WorkflowRole, WorkflowRoleUser} from '@dsvs/workflow-generator-dto';
import {isNil} from 'lodash';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs';
import {DtoHelper} from '../../../../classes/dto-helper';
import {WorkflowGeneratorConfigurationService} from '../../../../services/config/workflow-generator-configuration.service';
import {WorkflowGeneratorRoutingService} from '../../../../services/config/workflow-generator-routing.service';
import {WorkflowPermissions} from '../../../../services/config/workflow-permissions.enum';
import {WorkflowCrewServiceImpl} from '../../../../services/data/workflow-crew.service';
import {WorkflowRoleUserServiceImpl} from '../../../../services/data/workflow-role-users.service';
import {WorkflowRoleServiceImpl} from '../../../../services/data/workflow-role.service';
import {WorkflowUserServiceImpl} from '../../../../services/data/workflow-user.service';
import {WorkflowGroupServiceImpl} from '../../../../services/data/workflow-group.service';

export enum CrewDetailViewState {
  DETAIL, NEW, EDIT
}

@Component({
  selector: 'wfg-crew-detail',
  template: `<p-confirmDialog header="Confirmation"
                 icon=""
                 width="425"
                 acceptLabel="Ja"
                 rejectLabel="Nein"
                 responsive="true">
</p-confirmDialog>


<p-dialog
  header="LÃ¶schen"
  *ngIf="showDeleteDialog"
  [(visible)]="showDeleteDialog"
  [modal]="true"
  [dismissableMask]="true"
  [closable]="true"
  appendTo="body"
>
  MÃ¶chten Sie den Eintrag wirklich lÃ¶schen?
  <p-footer>
    <button
      type="button"
      pButton
      icon="fa ui-icon-check"
      (click)="deleteRow(deleteObject)"
      label="Ja"
    >
    </button>
    <button
      type="button"
      pButton
      icon="fa ui-icon-close"
      (click)="showDeleteDialog=false"
      label="Nein"
      class="ui-button-secondary"
    >
    </button>
  </p-footer>
</p-dialog>


<div class="ui-g-12" *ngIf="(crew && crew.data) || (newCrew && newCrew.data)">

  <div class="ui-g">
    <div class="ui-g-6">
      <dsvs-inline-edit
        label="Name"
        type="text"
        [(value)]="isNew ? newCrew.data.displayName : crew.data.displayName"
        [onSave]="save"
        [disabled]="!isEditable"
      ></dsvs-inline-edit>
    </div>

    <div class="ui-g-6 flex-align-center flex-justify-end">
      <i class="material-icons" (click)="showCreateModal = true" *ngIf="isEditable"
         pTooltip="Neue Benutzer-Rollen-Zuweisung">add_box</i>
    </div>
  </div>

  <div class="crew-detail-container" *ngIf="(crew && crew.roleusers) || newRoleUsers">


    <div class="ui-g-12">
      <div class="ui-g-3" style="width:20%"><h4>Rolle</h4></div>
      <div class="ui-g-6" style="width: 50%"><h4>Gruppen und Benutzer</h4></div>
      <div class="ui-g-3" style="width: 30%"><h4>Hauptansprechpartner</h4></div>
    </div>

    <div class="crew-detail-container-row"
         *ngFor="let roleuser of crew ? crew?.roleusers?.sync.content : newRoleUsers">

      <div class="crew-detail-container-role ui-lg-3">
        <ng-container *ngIf="(roleuser.role && roleuser.role.sync && !roleuser.role.sync._loading) || isNew">
          <wfg-autocomplete-dialog
            [model]="roleuser"
            [disabled]="!isEditable"
            [value]="isNew ? roleuser.role : roleuser.role.sync.data"
            [onFetch]="fetchRoles"
            [onSave]="saveRole"
            [displayValue]="'displayName'"
            [isMultipleSelect]="false"
            [fetchService]="roleService"></wfg-autocomplete-dialog>
        </ng-container>
      </div>

      <wfg-crew-detail-role-user class="ui-lg-6" [isNew]="isNew" [isEditable]="isEditable"
                                 [roleuser]="roleuser"
                                 (reloadRoleUsers)="reloadRoleUsers()"></wfg-crew-detail-role-user>

      <div class="crew-detail-container-mainuser ui-lg-3">
        <wfg-autocomplete-dialog
          [model]="roleuser"
          [disabled]="!isEditable"
          [value]="(isNew || !roleuser.data.mainUserId || !roleuser?.mainUser.sync) ? roleuser.mainUser : roleuser?.mainUser.sync.data"
          [onFetch]="fetchSelectedRowUsers"
          [onSave]="saveMainUser"
          [displayValue]="'displayName'"
          [isMultipleSelect]="false"
          [fetchServiceHalRelationPage]="roleuser.users"></wfg-autocomplete-dialog>
      </div>

      <div class="crew-detail-container-delete">
        <i class="material-icons"
           (click)="onDeleteRow(roleuser)"
           *ngIf="isEditable">delete</i>
      </div>
    </div>
  </div>

</div>

<p-footer class="footer">
  <div class="footer-buttons">
    <button
      (click)="cancel()"
      pButton
      type="button"
      class="ui-button-text dsvs-btn ui-corner-all"
      label="Schliessen"
      icon="fa ui-icon-close">
    </button>
    <button
      (click)="saveButtonClick()"
      pButton
      type="button"
      class="ui-button-text dsvs-btn ui-corner-all"
      label="Speichern"
      icon="fa ui-icon-save"
      [disabled]="!isEditable"
    >
    </button>
  </div>
</p-footer>


<p-dialog
  [header]="'Neue Benutzer-Rollen-Zuweisung hinzufÃ¼gen'"
  *ngIf="showCreateModal"
  [(visible)]="showCreateModal"
  width="1200"
  height="auto"
  [positionTop]="30"
  [modal]="true"
  [maximizable]="true"
  [dismissableMask]="true"
  [closable]="true"
  (visibleChange)="showCreateModal = false"
  appendTo="body"
>
  <div class="ui-g-12" style="display: flex; align-items: center">
    <wfg-autocomplete-dialog
      class="ui-g-6"
      label="Rolle"
      [model]="newRoleUser"
      [disabled]="!isEditable"
      [value]="roleSelected"
      [onFetch]="fetchRoles"
      [onSave]="saveRoleOfNew"
      [displayValue]="'displayName'"
      [isMultipleSelect]="false"
      [fetchService]="roleService"></wfg-autocomplete-dialog>

    <wfg-autocomplete-dialog
      *ngIf="usersSelected.length !== 0"
      [ngClass]="{'ui-g-5': mainUserSelected, 'ui-g-6': !mainUserSelected}"
      label="Hauptverantwortlicher"
      [model]="newRoleUser"
      [disabled]="!isEditable"
      [value]="mainUserSelected"
      [selectValues]="usersSelected"
      [onSave]="saveMainUserOfNew"
      [displayValue]="'displayName'"
      [isMultipleSelect]="false"></wfg-autocomplete-dialog>


    <i
      *ngIf="mainUserSelected"
      class="ui-g-1 material-icons delete-button-top"
      (click)="resetMainUserSelected()"
    >clear</i>
  </div>

  <wfg-crew-detail-role-user class="ui-lg-6" [isNew]="true" [isEditable]="isEditable"
                             [roleuser]="newRoleUser" [isCreateDialog]="true"
                             [newUsersArray]="usersSelected"
                             (reloadRoleUsers)="reloadRoleUsersOfNew($event)"></wfg-crew-detail-role-user>


  <p-footer class="footer">
    <div class="footer-buttons">
      <button
        (click)="showCreateModal = false"
        pButton
        type="button"
        class="ui-button-text dsvs-btn ui-corner-all"
        label="Schliessen"
        icon="fa ui-icon-close">
      </button>
      <button
        (click)="onUserRoleAddButtonClicked()"
        pButton
        type="button"
        class="ui-button-text dsvs-btn ui-corner-all"
        label="HinzufÃ¼gen"
        icon="fa ui-icon-save"
        *ngIf="isUserRoleAddButtonActive()"
      >
      </button>
    </div>
  </p-footer>

</p-dialog>
`,
  styles: [`:host ::ng-deep body .ui-dialog .ui-dialog-content{height:100%}.inline-block-width-100{display:inline-block;width:100%}.crew-detail-table{max-height:400px;overflow:auto}.flex-align-center{display:flex;align-items:center}.flex-align-end{display:flex;align-items:flex-end}.delete-button{margin-left:20px}.flex-justify-end{display:flex;justify-content:flex-end}.assignments-header{border-bottom:1px solid #a9a9a9}.footer{display:block;width:100%}.footer .footer-buttons{display:flex;justify-content:flex-end}.crew-detail-container h2{margin-left:15px}.crew-detail-container .crew-detail-container-row{display:flex;justify-content:space-between;padding:8px;border-bottom:1px solid #ebecf0}.crew-detail-container .crew-detail-container-row .crew-detail-container-role{width:20%;display:flex;align-items:center}.crew-detail-container .crew-detail-container-row .crew-detail-container-user{width:50%;display:flex;flex-direction:column}.crew-detail-container .crew-detail-container-row .crew-detail-container-mainuser{width:25%;display:flex;align-items:center}.crew-detail-container .crew-detail-container-row .crew-detail-container-delete{width:5%;display:flex;justify-content:flex-end;align-items:center}.crew-detail-container .crew-detail-container-row .material-icons{cursor:pointer;display:none}.crew-detail-container .crew-detail-container-row:hover .material-icons{display:block;color:#85a0d9}.crew-detail-container .crew-detail-container-row:hover .material-icons:hover{color:#4f70b6}.user-edit{margin-top:-40px}.material-icons{cursor:pointer}`]
})
export class CrewDetailComponent implements OnInit {
  /** Working object */
  @Input() crew: any;

  @Output() showEditModalEvent = new EventEmitter<boolean>();

  /** Form / UI */
  form: FormGroup;
  viewState: CrewDetailViewState = CrewDetailViewState.NEW;

  usersSelected: UserHalItem[] = [];
  groupSelected = {
    data: null
  };
  mainUserSelected: any;

  /** role dropdown */
  roles: WorkflowRole[];
  roleSelected: WorkflowRole;

  /** delete dialog */
  showDeleteDialog = false;
  deleteObject: any;

  isNew = false;
  newCrew: any;
  newRoleUser = {};
  newRoleUsers: any[] = [];
  isEditable = false;

  tableColumnOptions: DsvsTableColumn[] = [];

  showCreateModal = false;

  /**
   *
   * @param route
   * @param wfgRouting
   * @param crewService
   * @param groupService
   * @param roleService
   * @param userService
   * @param messageService
   * @param breadcrumbService
   * @param confirmationService
   * @param roleUserService
   * @param configService
   */
  constructor(private route: ActivatedRoute,
              private wfgRouting: WorkflowGeneratorRoutingService,
              private crewService: WorkflowCrewServiceImpl,
              public groupService: WorkflowGroupServiceImpl,
              public roleService: WorkflowRoleServiceImpl,
              public userService: WorkflowUserServiceImpl,
              private messageService: MessageService,
              private breadcrumbService: DsvsBreadcrumbService,
              private confirmationService: ConfirmationService,
              public roleUserService: WorkflowRoleUserServiceImpl,
              private configService: WorkflowGeneratorConfigurationService) {
  }

  /**
   *
   */
  ngOnInit() {

    this.isEditable = this.isNew || this.configService.hasPermission(WorkflowPermissions.CREW_UPDATE);

    if (isNil(this.crew)) {
      this.isNew = true;
      this.viewState = CrewDetailViewState.NEW;
      this.newCrew = DtoHelper.getCrew();
    } else {
      this.viewState = CrewDetailViewState.DETAIL;
    }

    this.tableColumnOptions = [
      {field: 'displayName', header: 'Name', sort: true, filter: '', hidden: false}
    ];
  }

  /********************************************
   * UI
   *******************************************/

  save = (value, component): Observable<any> => {
    if (this.isNew) {
      this.newCrew.data.displayName = component.value;
      return Observable.of(this.newCrew);
    } else {
      return this.crewService.update(this.crew.data);
    }
  };

  public saveButtonClick() {

    if (this.isNew) {
      this.crewService.create(this.newCrew.data).subscribe(resultCrew => {
        this.crew = resultCrew;

        if (this.newRoleUsers.length > 0) {
          this.newRoleUsers.forEach(roleUser => {
            this.roleUserService.create(<WorkflowRoleUser>{
              roleId: roleUser.role.id,
              crewId: this.crew.data.id,
              groupId: roleUser.group ? roleUser.group.data.id : null,
              mainUserId: this.mainUserSelected ?
                (this.mainUserSelected.data ? this.mainUserSelected.data.id : this.mainUserSelected.id) :
                roleUser.mainUser ? roleUser.mainUser.id : null,
              id: null,
              displayName: null
            })
              .subscribe((result: RoleUserHalItem) => {

                roleUser.users.forEach(user => {
                  result.users.add(user).subscribe(() => {
                    this.showEditModalEvent.emit(false);
                  }, error2 => {
                    console.error(error2);
                  });
                  this.crew.roleusers.reload();
                });

              }, error2 => {
                console.error('error while saving new relation - {}', error2);
              });
          });
        } else {
          this.showEditModalEvent.emit(false);
        }

      }, crewError => {
        console.error(crewError);
      });
    } else {
      this.showEditModalEvent.emit(false);
    }
  }

  reloadRoleUsers() {
    this.crew.roleusers.reload();
  }

  reloadRoleUsersOfNew(event) {
    this.usersSelected = event.users;
    this.groupSelected = event.group;
  }

  saveUsers = (value, component): Observable<any> => {
    const users = component.value;
    const roleuserHalItem = component.model;
    return roleuserHalItem.users.update(users);
  };

  saveRole = (value, component): Observable<any> => {
    component.model.data.roleId = value.data.id;
    return component.model.save();
  };

  saveRoleOfNew = (value, component): Observable<any> => {
    this.roleSelected = value.data;
    return Observable.of(null);
  };

  saveMainUser = (value, component): Observable<any> => {
    component.model.data.mainUserId = value.data.id;
    return component.model.save();
  };

  saveMainUserOfNew = (value, component): Observable<any> => {
    this.mainUserSelected = value.data;
    return Observable.of(null);
  };

  public cancel() {
    this.showEditModalEvent.emit(false);
  }

  /********************************************
   * User/Role List
   *******************************************/

  public onUserRoleAddButtonClicked() {
    /** add combination */

    if (this.isNew) {
      const newRoleUser = {
        role: this.roleSelected,
        users: this.usersSelected,
        mainUser: this.mainUserSelected,
        group: this.groupSelected
      };
      this.newRoleUsers.push(newRoleUser);
      this.roleSelected = null;
      this.usersSelected = [];
      this.mainUserSelected = null;
      this.groupSelected = null;
      this.showCreateModal = false;
    } else {
      this.roleUserService.create(
        <WorkflowRoleUser>{
          roleId: this.roleSelected.id,
          crewId: this.crew.data.id,
          groupId: (this.groupSelected && this.groupSelected.data) ? this.groupSelected.data.id : null,
          mainUserId: this.mainUserSelected ?
            (this.mainUserSelected.data ? this.mainUserSelected.data.id : this.mainUserSelected.id) : null,
          id: null,
          displayName: null
        })
        .subscribe((result: RoleUserHalItem) => {

          this.usersSelected.forEach(user => {
            result.users.add(user).subscribe(
              () => {
                this.roleSelected = null;
                this.usersSelected = [];
                this.mainUserSelected = null;
              }, error2 => {
                console.error(error2);
              });
          });
          this.showCreateModal = false;
          this.crew.roleusers.reload();
        }, error2 => {
          console.error('error while saving new relation - {}', error2);
        });
    }
  }

  /**
   *
   * @returns
   */
  public isUserRoleAddButtonActive(): boolean {
    return !!(this.usersSelected && this.usersSelected.length > 0 && this.roleSelected);
  }

  /**
   *
   * @param roleUsers
   */
  public onDeleteRow(roleUsers) {
    this.deleteObject = roleUsers;
    this.showDeleteDialog = true;
  }

  public deleteRow(roleUsers) {
    this.roleUserService.delete(roleUsers.data).subscribe(() => {
      this.showDeleteDialog = false;
      this.crew.roleusers.reload();
    }, error2 => {
      console.error(error2);
    });
  }

  public resetMainUserSelected() {
    this.mainUserSelected = null;
  }

  public resetMainUser(component) {
    component.data.mainUserId = null;
    component.save();
    this.crew.roleusers.reload();
  }

  /********************************************
   * Fetch data
   *******************************************/

  fetchRoles = ($event): Observable<RoleHalPage> => {
    return this.roleService.search($event.query);
  };

  fetchUsers = ($event): Observable<UserHalPage> => {
    return this.userService.search($event.query);
  };

  fetchSelectedUsers = ($event): Observable<UserHalItem[]> => {
    if (!$event.query) {
      $event.query = '';
    }
    return Observable.of(this.usersSelected.filter(a => a.data.displayName.toLowerCase().indexOf($event.query.toLowerCase()) > -1));
  };

  fetchSelectedRowUsers = ($event, component): Observable<UserHalPage> => {
    return component.model.users.async;
  };

  fetchSelectedRowUsersOfNew = ($event, component): Observable<UserHalPage> => {
    return component.model.users.async;
  };

}
