import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsvsUnderConstructionComponent } from './components/under-construction/under-construction.component';
import { SharedModule } from 'primeng/shared';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CardModule,
    PanelModule
  ],
  declarations: [DsvsUnderConstructionComponent],
  exports: [DsvsUnderConstructionComponent]
})
export class DsvsUnderConstructionModule { }
