import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dsvs-panel',
  template: `<div class="dsvs-panel-container">

  <h2>{{title}}</h2>

  <i class="material-icons section-indicator">{{icon}}</i>

  <div class="content">
  <ng-content></ng-content>
  </div>
</div>
`,
  styles: [`.dsvs-panel-container{width:100%;display:flex;flex-direction:column;background:#f4f6fa;padding:10px 20px;position:relative;border-radius:2px;height:100%;margin-bottom:16px}.dsvs-panel-container ::ng-deep h2{color:#21428a;font-size:13pt!important;font-weight:400}.dsvs-panel-container .section-indicator{position:absolute;top:24px;right:24px;font-size:2em;color:#cdd9f2}`]
})
export class DsvsPanelComponent implements OnInit {

  @Input() title: string;
  @Input() icon: string;

  constructor(
  ) {

  }

  ngOnInit() {
  }

}

