import { Component, Input } from '@angular/core';

@Component({
  selector: 'dsvs-status',
  template: `<div class="dsvs-status-wrapper">
  <div class="dsvs-dot" [ngStyle]="{'background-color': color}"></div>
  <span *ngIf="tag != null">
      {{tag}}
    </span>
</div>
`,
  styles: [`.dsvs-status-wrapper{display:flex;align-items:center;flex-direction:row}.dsvs-dot{border-radius:4px;width:8px;height:8px;margin:6px}`]
})
export class StatusComponent {

  _color = '#264b94';

  @Input() tag: string = null;

  // TODO: Replace with colors from SASS
  @Input() set color(color: string) {
    if (color) {
      switch (color.toLowerCase()) {
        case 'aktiv':
        case 'active' :
        case 'published' :
        case 'released' :
          this._color = '#7ED321';
          break;
        case 'unpublished' :
        case 'uploaded' :
          this._color = '#F3E401';
          break;
        case 'inactive' :
        case 'canceled' :
        case 'inaktiv':
          this._color = '#FC3A28';
          break;
        case 'new' :
        default:
          this._color = '#42A5F5';
          break;
      }
    }
  }

  get color(): string {
    return this._color;
  }
}
