import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { ActionHalItem, ActionHalPage, WorkflowAction } from '@dsvs/workflow-generator-dto';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowActionService extends DsvsSearchableCrudEntityService<WorkflowAction> {

}

// @dynamic
@Injectable()
export class WorkflowActionServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowAction, ActionHalItem, ActionHalPage>
  implements WorkflowActionService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'action', collection: 'actions'},
      WorkflowGeneratorTypeMappingService.WorkflowAction,
      typeResolver
    );
  }
}

@Injectable()
export class WorkflowActionServiceMock
  extends DsvsSearchableCrudEntityServiceMock<WorkflowAction>
  implements WorkflowActionService {

  constructor() {
    super();

    this.data = [];

    for (let i = 1; i <= 100; i++) {
      this.data.push(
        <WorkflowAction>{
          id: String(i),
          alias: 'wg-comment-add',
          displayName: 'Action ' + i,
          required: false,
          resetOnStepEntry: false,
          deleted: false,
          requiredRights: [],
          requiredVisibility: [],
          uploadParts: []
        }
      );
    }

  }
}
