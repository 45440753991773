import {Component, ComponentFactoryResolver, ComponentRef, HostBinding, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Message} from 'primeng/api';
import {Observable, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {DsvsUserData} from '../../../authentication/interfaces/user-data';
import {DsvsAuthorizationService} from '../../../authentication/services/authorization.service';
import {DsvsSharedUiLibConfigService} from '../../../dsvs-shared-ui-lib-config.service';
import {DsvsErrorHandler} from '../../../error/classes/dsvs-error-handler';
import {DsvsNotificationData} from '../../../notification/interfaces/notification-data';
import {DsvsNotificationService} from '../../../notification/services/notification.service';
import {DsvsAppSettings} from '../../../settings/interfaces/app-settings';
import {DsvsSettings} from '../../../settings/interfaces/dsvs-settings';
import {DsvsSettingsService} from '../../../settings/services/settings-service';
import {DsvsBreadcrumbService} from '../../services/breadcrumb.service';
import {DsvsBaseLayoutService} from '../../services/layout-base.service';
import {DsvsLayoutStateService} from '../../services/layout-state.service';
import {DsvsFooterComponent} from '../footer/footer.component';
import {DsvsMenuComponent} from '../menu/menu.component';
import {DsvsTopbarComponent} from '../topbar/topbar.component';
import {DsvsMenuService} from '../../services/menu.service';

@Component({
  selector: 'dsvs-start',
  template: `<div class="layout-wrapper" (click)="layoutState.onWrapperClick()"
     [ngClass]="{'layout-wrapper-static': layoutState.layoutStatic,
                'layout-wrapper-active': layoutState.mobileMenuActive}">
  <p-growl [(value)]="msgs"></p-growl>

  <!--<dsvs-menu></dsvs-menu>-->

  <ng-container #menuContainer></ng-container>

  <div class="layout-main">

    <!--<ng-container #topbarContainer></ng-container>-->
    <!--<ng-container #breadcrumbContainer></ng-container>-->

    <!--<dsvs-topbar></dsvs-topbar>-->
    <!--<dsvs-breadcrumb></dsvs-breadcrumb>-->

    <div *ngIf="!performLogin" class="layout-content">
      <div *ngIf="isLoggedIn; then granted else denied"></div>
      <ng-template #granted>
        <router-outlet
          *ngIf="userSettingsLoadedInitially; else notInitiallyLoaded"></router-outlet>
        <ng-template #notInitiallyLoaded>
          <router-outlet
            *ngIf="userSettingsLoaded"></router-outlet>
        </ng-template>
      </ng-template>
      <ng-template #denied>
        <dsvs-login-via-apo [errorHandler]="errorHandler"></dsvs-login-via-apo>
      </ng-template>
    </div>


    <div class="layout-main-mask" *ngIf="layoutState.mobileMenuActive"></div>

  </div>

</div>

<ng-container #footerContainer></ng-container>
`,
  styles: [`.layout-wrapper{flex:1}`]
})
export class DsvsStartComponent implements OnInit, OnDestroy {

  @ViewChild('footerContainer', {read: ViewContainerRef}) footerViewRef;
  @ViewChild('menuContainer', {read: ViewContainerRef}) menuViewRef;
  @ViewChild('breadcrumbContainer', {read: ViewContainerRef}) breadcrumbViewRef;
  @ViewChild('topbarContainer', {read: ViewContainerRef}) topbarViewRef;
  topbarComponentRef: ComponentRef<DsvsTopbarComponent>;
  breadcrumbComponentRef: ComponentRef<DsvsTopbarComponent>;
  menuComponentRef: ComponentRef<DsvsTopbarComponent>;
  userMenuComponentRef: ComponentRef<DsvsTopbarComponent>;
  footerComponentRef: ComponentRef<DsvsTopbarComponent>;

  @HostBinding('style.display') display = 'flex';
  @HostBinding('style.minHeight') minHeight = '100vh';
  @HostBinding('style.flexDirection') flexDirection = 'column';

  /** Error-Handler */
  errorHandler: DsvsErrorHandler = new DsvsErrorHandler();

  /* Messages */
  msgs: Message[] = [];

  /** MenÃ¼-Steuerung fÃ¼r Primeng-UI-Template */

  /** Flags fÃ¼r Authentifizierung */
  performLogin = false;
  isLoggedIn: boolean;
  loginViaSSOID = false;

  /** Anwenderdaten */
  userData: DsvsUserData;

  /** JSON-Webtoken fÃ¼r Service-Authentifizierung */
  jwt: string;

  /** Subscriptions fÃ¼r die SSO-Authentifizierung */
  qpSub1: Subscription;  // Query-Parameter-Subscription 1;
  qpSub2: Subscription;  // Query-Parameter-Subscription 2;

  /** Subscription fÃ¼r die C2C-Communication */
  msgSub: Subscription;  // Message-Service Subscription

  /** Subscriptions fÃ¼r den Notification-Service */
  notificationRefresh: Subscription;
  notificationAlert: Subscription;

  /** Liste der neu eingegangenen Nachrichten zum Einblenden als Growl-Messages */
  newNotifications: DsvsNotificationData[] = [];

  /** flags for determining if user settings have been fetched **/
  userSettingsLoaded = false;
  userSettingsLoadedInitially = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public layoutState: DsvsLayoutStateService,
              public breadcrumbService: DsvsBreadcrumbService,
              private authService: DsvsAuthorizationService,
              private optService: DsvsSettingsService,
              public fesSettings: DsvsSettings,
              private renderer: Renderer2,
              private dsvsConfigService: DsvsSharedUiLibConfigService,
              protected componentFactoryResolver: ComponentFactoryResolver,
              public notificationService: DsvsNotificationService,
              private layoutViewService: DsvsBaseLayoutService,
              private menuService: DsvsMenuService) {


  }

  ngOnInit() {

    this.setDefaultViewComponents();

    this.extendOrOverrideComponents();

    this.initalizeLogin().subscribe(() => {
      this.handleLogin().subscribe((result) => {
        this.fesSettings.initUser(result);
        this.userData = this.authService.userData;
        this.userSettingsLoaded = true;
        this.buildUserData();
      });
    });


    this.initNotificationService();

    this.renderer.addClass(document.body, 'body-image');

  }

  /** Destroy-Methode der Komponente,
   * alle Subscriptions werden storniert
   */
  ngOnDestroy() {
    if (this.qpSub1) {
      this.qpSub1.unsubscribe();
    }
    if (this.qpSub2) {
      this.qpSub1.unsubscribe();
    }
    if (this.msgSub) {
      this.msgSub.unsubscribe();
    }
    this.renderer.removeClass(document.body, 'body-image');

  }

  /** Anrede aus den Userdaten zusammensetzen und
   * JSON-Webtoken bereitstellen
   */
  private buildUserData(): void {
    if (this.userData) {
      this.jwt = this.userData.result.jwt;
    }
  }

  /** Nachrichten des Messageservices auflÃ¶sen und ggf. bearbeiten
   * (wenn Receiver = 'start' oder 'all' ist)
   */

  /*
  messageResolver(msg: Comp2compMessage): void {
    // TODO
  }
  */

  protected initalizeLogin(): Observable<any> {

    /** Application-Settings aus dem Resolver Ã¼bergeben */
    this.msgs = [];
    if (this.route.snapshot.data['options']) {
      const settings: DsvsAppSettings = this.route.snapshot.data['options'];
      if (settings.success === true) {
        return this.fesSettings.initApp(settings);
      } else {
        this.msgs.push({
          severity: 'error',
          summary: 'ACHTUNG!',
          // tslint:disable-next-line
          detail: 'Die Programmeinstellungen (Settings) konnten nicht ausgelesen werden.<br>Bitte beenden Sie ' + this.dsvsConfigService.config.layout.appName + ' und wenden Sie sich an den Support.'
        });
        return Observable.of(null);
      }
    } else {
      return Observable.of(null);
    }
    /*
    this.msgSub = this.msgService.getMessage().subscribe(msg => {
      this.messageResolver(msg);
    });
    */
  }

  protected handleLogin(): Observable<any> {
    this.loginViaSSOID = false;

    /** PrÃ¼fung auf angemeldeten User bei Initialisierung der Komponente */
    this.isLoggedIn = this.authService.checkLogin();
    /* Zuerst die User-Settings laden */
    if (this.isLoggedIn) {
      if (this.fesSettings.success) {
        return this.optService.getUserSettings();
      } else {
        return Observable.of([]);
      }
    } else {
      /** PrÃ¼fung auf SSOID als Query-Parameter in der URL
       * wenn der User nicht angemeldet ist*/
      this.qpSub1 = this.route.queryParams.subscribe(data => {
        if (data.ssoid == null) {
          this.errorHandler.hasError = true;
          this.errorHandler.errType = 'info';
          this.errorHandler.msgTitle = 'Information zum Single-Sign-On Verfahren.';
          this.errorHandler.msgBody = 'Bitte melden Sie sich in der Formularfabrik an und starten Sie ' +
            this.dsvsConfigService.config.layout.appName + ' Ã¼ber das AnwendungsmenÃ¼...' +
            '<br>Bei RÃ¼ckfragen stehen wir Ihnen unter...';
          this.router.navigate(['/landing/nosso']);
        } else {
          this.errorHandler = new DsvsErrorHandler();
        }
      });
      /** Falls beim Aufruf des Auth-Services kein Fehler aufgetreten ist, dann
       * kann der Login-Prozess angestoÃen werden  */
      if (this.authService.errorHandler.hasError) {
        /*
        this.errorHandler = this.authService.errorHandler;
         */
      } else {
        this.qpSub2 = this.route.queryParams
          .pipe(filter(params => params.ssoid))
          .subscribe(params => {
            if (params.ssoid !== '') {
              this.performLogin = true;
              this.loginViaSSOID = true;
              this.authService.login(params.ssoid);
            }
          });
      }
      /** getLoginDone ist ein Observable des Auth-Services
       * nach Abschluss des Login-Prozesses wird der Login-Status
       * zurÃ¼ckgeliefert */
      this.authService.getLoginDone().subscribe(ls => {
        this.performLogin = false;
        if (ls === true) {
          if (!this.authService.errorHandler.hasError) {
            this.isLoggedIn = this.authService.checkLogin();
          }
          if (this.authService.errorHandler.hasError) {
            /** Beim Login-Prozess ist ein Fehler aufgetreten */
            this.errorHandler = this.authService.errorHandler;
            this.msgs.push({
              severity: 'error',
              summary: 'Fehler beim Single-Sign-On',
              detail: this.authService.errorHandler.msgTitle + '<br>' + this.authService.errorHandler.msgBody
            });
            setTimeout(() => {
              this.router.navigate(['/landing/ssoerr']);
            }, 2000);
          } else {
            /** Der Login-Prozess war erfolgreich */
            this.userData = this.authService.userData;
            this.buildUserData();
            this.qpSub1.unsubscribe();
            this.qpSub2.unsubscribe();
            /* User-Settings abrufen, an App-Settings Ã¼bergeben und zu Home verzweigen */
            this.optService.getUserSettings().subscribe(udata => {
              this.fesSettings.initUser(udata);
              this.userSettingsLoadedInitially = true;
              // console.log(this.fesSettings);
              if (this.loginViaSSOID) {
                this.router.navigate([], {replaceUrl: true, queryParams: {ssoid: null}, queryParamsHandling: 'merge'});
                // this.router.navigate(['/home'], {replaceUrl: true, {queryParams: {page: null}, queryParamsHandling: 'merge'}});
              }
              this.loginViaSSOID = false;

            });
          }
        }
      });
      return Observable.of([]);
      // this.router.navigate(['/home'], {replaceUrl: true});
    }
  }

  protected extendOrOverrideComponents() {

    this.layoutViewService.onReplaceTopbar.subscribe(newComponent => {
      this.setTopbarComponent(newComponent);
    });
    this.layoutViewService.onReplaceFooter.subscribe(newComponent => {
      this.setFooterComponent(newComponent);
    });
    this.layoutViewService.onReplaceMenu.subscribe(newComponent => {
      this.setMenuComponent(newComponent);
    });
    this.layoutViewService.onReplaceBreadcrumb.subscribe(newComponent => {
      this.setBreadcrumbComponent(newComponent);
    });
    this.layoutViewService.onExtendTopbar.subscribe(component => {
      this.topbarComponentRef.instance.setExtensionComponent(component);
    });
    this.layoutViewService.onExtendMenu.subscribe(component => {
      this.menuComponentRef.instance.setExtensionComponent(component);
    });

    this.layoutViewService.startComponentIsInitialized();
  }

  protected setDefaultViewComponents() {
    // Set View-Components
    // this.topbarComponentRef = this.setTopbarComponent(DsvsTopbarComponent);
    this.menuComponentRef = this.setMenuComponent(DsvsMenuComponent);
    // this.breadcrumbComponentRef = this.setBreadcrumbComponent(DsvsBreadcrumbComponent);
    this.footerComponentRef = this.setFooterComponent(DsvsFooterComponent);
  }

  protected setComponent(newComponent: any, container: ViewContainerRef): any {
    container.clear();
    return container.createComponent(this.componentFactoryResolver.resolveComponentFactory(newComponent));
  }

  protected setTopbarComponent(newComponent: any): any {
    return this.setComponent(newComponent, this.topbarViewRef);
  }

  protected setMenuComponent(newComponent: any): any {
    return this.setComponent(newComponent, this.menuViewRef);
  }

  protected setBreadcrumbComponent(newComponent: any): any {
    return this.setComponent(newComponent, this.breadcrumbViewRef);
  }

  protected setFooterComponent(newComponent: any): any {
    return this.setComponent(newComponent, this.footerViewRef);
  }

  private initNotificationService() {

    /** Notificatione Refresh-Trigger abonnieren um festzustellen ob
     * nach der Initialisierung neue oder ungelesene Notifications verfÃ¼bgar sind
     * Falls es die erste Initialisierung ist muss auch noch der Benachrichtigungs-
     * Alarm abonniert werden*/
    this.notificationRefresh = this.notificationService.notificationRefresh.subscribe(result => {
      if (result === true) {
        this.msgs = [];
        /** Ungelesene Nachricht(en) (einzahl/mehrzahl) */
        if (this.notificationService.notificationListUnread.length > 0) {
          let singleMulti = 'Benachrichtigung';
          if (this.notificationService.notificationListUnread.length > 1) {
            singleMulti += 'en';
          }
          this.msgs.push({
            severity: 'info',
            summary: 'FES-Anwenderinformation',
            // tslint:disable-next-line
            detail: 'Sie haben insgesamt ' + this.notificationService.notificationListUnread.length + ' ungelesene ' + singleMulti
          });
        }
        /** Neue Nachricht(en) (einzahl/mehrzahl) */
        if (this.notificationService.newNotificationCount > 0) {
          let singleMulti = 'Benachrichtigung';
          if (this.notificationService.newNotificationCount > 1) {
            singleMulti += 'en';
          }
          this.msgs.push({
            severity: 'success',
            summary: 'Benachrichtigungsservice',
            detail: 'Sie haben ' + this.notificationService.newNotificationCount + ' neue ' + singleMulti
          });
        }
        if (!this.notificationAlert) {
          /** Notificatione Alarm abonnieren um festzustellen ob neue Notifications verfÃ¼bgar sind */
          this.notificationAlert = this.notificationService.notificationAlert.subscribe(result2 => {
            // console.log('Subscribe auf Notification alert');
            if (result2) {
              /** Die neu eingegangenen Nachrichten abholen */
              // console.log('Neue Nachrichten abholen');
              this.notificationService.getNewNotifications(this.authService.getJWT()).subscribe(data => {
                /** Einzelne Notifications anzeigen */

                // console.log('Nachrichten:');
                // console.log(data);
                if (data.length > 0) {
                  /** Polling stoppen */
                  this.notificationService.stopPolling();
                  /** Neue Nachrichten als abgehlt markieren */
                  this.notificationService.markNotificationsAsFetched(this.authService.getJWT());
                  this.newNotifications = [];
                  /** Die einzelnen neuen Nachrichten je nach Typ anzeigen */
                  for (const notification of data) {
                    /** als ungelesen markieren und zur Liste hunzufÃ¼gen */
                    notification.readType = 0;
                    this.newNotifications.push(notification);
                    const detail: string = notification.msg_text;
                    /** HTML Injection funktioniert nicht mit Sicherheitsrelevanten Tags/Script
                     if (notification.msg_has_link) {
                      // Je nach Notification-Type wird ein anderer Link aufgebaut (Download, router-Link...)
                      switch (notification.type) {
                        case 'DOWN':
                          if (notification.file) {
                          // tslint:disable-next-line
                            detail = detail.concat('<br><br>Direkt zum Dateidownload:<br>', '<a href="" routerLink="/notifications/',
                            notification.file.id.toString(), '">', notification.msg_link_text, '</a>');
                            console.log(detail);
                          }
                          break;
                      }
                    }
                     */
                    this.msgs.push({
                      severity: 'info',
                      summary: notification.msg_title,
                      detail: detail
                    });
                  }
                  /** Die neuen Nachrichten zu den Listen hinzufÃ¼gen */
                  this.notificationService.addNewNotifications(this.newNotifications);
                  this.menuService.addUserMenu();
                  /** Polling wieder starten */
                  this.notificationService.startPolling(this.authService.getJWT());
                }
              });
            }
          });
        }
        this.notificationRefresh.unsubscribe();
      } else {
        if (this.notificationService.errorHandler.hasError) {
          this.msgs.push({
            severity: this.notificationService.errorHandler.errType,
            summary: this.notificationService.errorHandler.msgTitle,
            detail: this.notificationService.errorHandler.msgBody
          });
          this.notificationService.errorHandler.clear();
          if (this.notificationAlert) {
            this.notificationAlert.unsubscribe();
            this.notificationService.stopPolling();
          }
        }
      }
    });

  }
}
