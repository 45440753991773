import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DsvsBreadcrumbService, DsvsTableColumn} from '@dsvs/dsvs-shared-ui-lib';
import {isNil} from 'lodash';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs';
import {DtoHelper} from '../../../../classes/dto-helper';
import {WorkflowGeneratorConfigurationService} from '../../../../services/config/workflow-generator-configuration.service';
import {WorkflowGeneratorRoutingService} from '../../../../services/config/workflow-generator-routing.service';
import {WorkflowPermissions} from '../../../../services/config/workflow-permissions.enum';
import {WorkflowRoleServiceImpl} from '../../../../services/data/workflow-role.service';
import {WorkflowUserServiceImpl} from '../../../../services/data/workflow-user.service';
import {WorkflowGroupServiceImpl} from '../../../../services/data/workflow-group.service';
import {UserHalPage} from '@dsvs/workflow-generator-dto';

export enum GroupDetailViewState {
  DETAIL, NEW, EDIT
}

@Component({
  selector: 'wfg-group-detail',
  template: `<p-confirmDialog header="Confirmation"
                 icon=""
                 width="425"
                 acceptLabel="Ja"
                 rejectLabel="Nein"
                 responsive="true">
</p-confirmDialog>

<div class="ui-g-12" *ngIf="group || newGroup">

  <div class="ui-g">
    <div class="ui-g-6">
      <dsvs-inline-edit
        label="Name"
        type="text"
        [(value)]="isNew ? newGroup.data.displayName : group.data.displayName"
        [onSave]="save"
        [disabled]="!isEditable"
      ></dsvs-inline-edit>
    </div>
  </div>

  <wfg-autocomplete-dialog
    *ngIf="(!isNew && group &&  !group.users.setParams({size: 20000}).sync._loading) || newGroupUsers"
    label="Nutzer"
    [model]="isNew ? newGroup : group"
    [disabled]="!isEditable"
    [value]="isNew ? newGroupUsers : group.users.setParams({size: 20000}).sync.content"
    [onFetch]="fetchUsers"
    [onSave]="saveUsers"
    [displayValue]="'displayName'"
    [isMultipleSelect]="true"
    [useUserBadges]="true"
    [fetchService]="userService"></wfg-autocomplete-dialog>

</div>

<p-footer class="footer">
  <div class="footer-buttons">
    <button
      (click)="cancel()"
      pButton
      type="button"
      class="ui-button-text dsvs-btn ui-corner-all"
      label="Schliessen"
      icon="fa ui-icon-close">
    </button>
    <button
      (click)="saveButtonClick()"
      pButton
      type="button"
      class="ui-button-text dsvs-btn ui-corner-all"
      label="Speichern"
      icon="fa ui-icon-save"
      [disabled]="!isEditable"
    >
    </button>
  </div>
</p-footer>
`,
  styles: [`:host ::ng-deep body .ui-dialog .ui-dialog-content{height:100%}.inline-block-width-100{display:inline-block;width:100%}.crew-detail-table{max-height:400px;overflow:auto}.flex-align-center{display:flex;align-items:center}.flex-align-end{display:flex;align-items:flex-end}.delete-button{margin-left:20px}.flex-justify-end{display:flex;justify-content:flex-end}.assignments-header{border-bottom:1px solid #a9a9a9}.footer{display:block;width:100%}.footer .footer-buttons{display:flex;justify-content:flex-end}.crew-detail-container h2{margin-left:15px}.crew-detail-container .crew-detail-container-row{display:flex;justify-content:space-between;padding:8px;border-bottom:1px solid #ebecf0}.crew-detail-container .crew-detail-container-row .crew-detail-container-role{width:20%;display:flex;align-items:center}.crew-detail-container .crew-detail-container-row .crew-detail-container-user{width:50%;display:flex;align-items:center}.crew-detail-container .crew-detail-container-row .crew-detail-container-mainuser{width:25%;display:flex;align-items:center}.crew-detail-container .crew-detail-container-row .crew-detail-container-delete{width:5%;display:flex;justify-content:flex-end;align-items:center}.crew-detail-container .crew-detail-container-row .material-icons{cursor:pointer;display:none}.crew-detail-container .crew-detail-container-row:hover .material-icons{display:block;color:#85a0d9}.crew-detail-container .crew-detail-container-row:hover .material-icons:hover{color:#4f70b6}.user-edit{margin-top:-40px}`]
})
export class GroupDetailComponent implements OnInit {
  /** Working object */
  @Input() group: any;

  @Output() showEditModalEvent = new EventEmitter<boolean>();

  /** Form / UI */
  form: FormGroup;
  viewState: GroupDetailViewState = GroupDetailViewState.NEW;

  isNew = false;
  newGroup: any;
  newGroupUsers: any[] = [];
  isEditable = false;

  tableColumnOptions: DsvsTableColumn[] = [];

  /**
   *
   * @param route
   * @param wfgRouting
   * @param groupService
   * @param roleService
   * @param userService
   * @param messageService
   * @param breadcrumbService
   * @param confirmationService
   * @param configService
   */
  constructor(private route: ActivatedRoute,
              private wfgRouting: WorkflowGeneratorRoutingService,
              private groupService: WorkflowGroupServiceImpl,
              private roleService: WorkflowRoleServiceImpl,
              public userService: WorkflowUserServiceImpl,
              private messageService: MessageService,
              private breadcrumbService: DsvsBreadcrumbService,
              private confirmationService: ConfirmationService,
              private configService: WorkflowGeneratorConfigurationService) {
  }

  /**
   *
   */
  ngOnInit() {

    this.isEditable = this.isNew || this.configService.hasPermission(WorkflowPermissions.GROUP_UPDATE);

    if (isNil(this.group)) {
      this.isNew = true;
      this.viewState = GroupDetailViewState.NEW;
      this.newGroup = DtoHelper.getGroup();
    } else {
      this.viewState = GroupDetailViewState.DETAIL;
    }

    this.tableColumnOptions = [
      {field: 'displayName', header: 'Name', sort: true, filter: '', hidden: false}
    ];
  }

  /********************************************
   * UI
   *******************************************/

  save = (value, component): Observable<any> => {
    if (this.isNew) {
      this.newGroup.data.displayName = component.value;
      return Observable.of(this.newGroup);
    } else {
      return this.groupService.update(this.group.data);
    }
  };

  public saveButtonClick() {

    if (this.isNew) {
      this.groupService.create(this.newGroup.data).subscribe(resultCrew => {
        this.group = resultCrew;
        this.group.users.update(this.newGroupUsers).subscribe(() => {
          this.showEditModalEvent.emit(false);
        }, error => {
          console.error(error);
        });
      }, groupError => {
        console.error(groupError);
      });
    } else {
      this.showEditModalEvent.emit(false);
    }
  }

  public cancel() {
    this.showEditModalEvent.emit(false);
  }

  fetchUsers = ($event): Observable<UserHalPage> => {
    return this.userService.search($event.query);
  };

  saveUsers = (value, component): Observable<any> => {
    if (!this.isNew) {
      const users = component.value;
      const groupHalItem = component.model;
      return groupHalItem.users.update(users);
    } else {
      return Observable.of(null);
    }
  };

}
