import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreOptions, RequestCallback, Response } from 'request';
import { AbortHandle } from './abort-handle';

type HttpClientOptions = {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'response' | 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json' | 'text';
  withCredentials?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class TraversonNgAdapter /*implements RequestAPI<Request, CoreOptions, RequiredUriUrl>*/ {
  constructor(
    private client: HttpClient
  ) {
  }

  get(uri: string, options?: CoreOptions, callback?: RequestCallback): AbortHandle {
    let clientOptions = this.mapOptions(options);
    let subscription = this.client
      .get(uri, <any>clientOptions)
      .subscribe(this.handleResponse(callback), this.handleError(callback));
    return new AbortHandle(subscription);
  }

  post(uri: string, options?: CoreOptions, callback?: RequestCallback): AbortHandle {
    let clientOptions = this.mapOptions(options);
    let data = this.extractData(clientOptions, options);
    let subscription = this.client
      .post(uri, data, <any>clientOptions)
      .subscribe(this.handleResponse(callback), this.handleError(callback));
    return new AbortHandle(subscription);
  };

  put(uri: string, options?: CoreOptions, callback?: RequestCallback): AbortHandle {
    let clientOptions = this.mapOptions(options);
    let data = this.extractData(clientOptions, options);
    let subscription = this.client
      .put(uri, data, <any>clientOptions)
      .subscribe(this.handleResponse(callback), this.handleError(callback));
    return new AbortHandle(subscription);
  };

  patch(uri: string, options?: CoreOptions, callback?: RequestCallback): AbortHandle {
    let clientOptions = this.mapOptions(options);
    let data = this.extractData(clientOptions, options);
    let subscription = this.client
      .patch(uri, data, <any>clientOptions)
      .subscribe(this.handleResponse(callback), this.handleError(callback));
    return new AbortHandle(subscription);
  };

  del(uri: string, options?: CoreOptions, callback?: RequestCallback): AbortHandle {
    let clientOptions = this.mapOptions(options);
    let subscription = this.client
      .delete(uri, <any>clientOptions)
      .subscribe(this.handleResponse(callback), this.handleError(callback));
    return new AbortHandle(subscription);
  };

  private mapOptions(options: CoreOptions): HttpClientOptions {
    options = options || {};
    let mappedOptions: HttpClientOptions = {
      observe: 'response'
    };
    this.mapQuery(mappedOptions, options);
    this.mapHeaders(mappedOptions, options);
    this.mapAuth(mappedOptions, options);
    
      // do not parse JSON automatically, this will trip up Traverson
    mappedOptions.responseType = 'text';

    return mappedOptions;
  }

  private mapQuery(mappedOptions: HttpClientOptions, options: CoreOptions) {
    if (options.qs) {
      mappedOptions.params = options.qs;
    }
  }

  private mapHeaders(mappedOptions: HttpClientOptions, options: CoreOptions) {
    if (options.headers) {
      mappedOptions.headers = options.headers;
    }
  }

  private mapAuth(mappedOptions: HttpClientOptions, options: CoreOptions) {
    let auth = options.auth;
    if (auth) {
      let username = auth.user || auth.username;
      let password = auth.pass || auth.password;
      mappedOptions.headers = mappedOptions.headers || {};
      mappedOptions.headers['Authorization'] = 'Basic ' + btoa(username + ':' + password);
    }
  }

  private extractData(mappedOptions: HttpClientOptions, options: CoreOptions): any {
    let form = options.form;
    if (form) {
      mappedOptions.headers = mappedOptions.headers || {};
      mappedOptions.headers['Content-Type'] =
        'application/x-www-form-urlencoded';
      return form;
    }

    let body = options.body;
    if (body) {
      return body;
    }
  }

  private mapResponse(response: HttpResponse<Object> | HttpErrorResponse): Response {
    let headers = {};
    for (let key of response.headers.keys()) {
      headers[key] = response.headers.getAll(key);
    }

    (<any>response).headers = headers;
    (<any>response).statusCode = response.status;

    if (response instanceof HttpErrorResponse) {
      (<any>response).body = response.error;
    }
    return <Response>(<any>response);
  }

  private handleResponse(callback) {
    let mapResponse = this.mapResponse;
    return function (response) {
      return callback(null, mapResponse(response));
    };
  }

  private handleError(callback) {
    let mapResponse = this.mapResponse;
    return function (response) {
      if (response.status >= 100 && response.status < 600) {
        // This happens on a completed HTTP request with a status code outside
        // of the 2xx range. In the context of Traverson, this is not an
        // error, in particular, if this is the last request in a traversal.
        // Thus, we re-route it to the successCallback. Handling 4xx and 5xx
        // errors during the traversal is the responsibility of traverson, not
        // traverson-angular.
        return callback(null, mapResponse(response));
      } else {
        // This happens on network errors, timeouts etc. In this case,
        // AngularJS sets the status property to 0. In the context of
        // Traverson, only these are to be interpreted as errors.
        return callback(response);
      }
    };
  }
}

