import { Injectable } from '@angular/core';
import 'rxjs/add/operator/delay';
import { Observable } from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { of } from 'rxjs/observable/of';
import { DsvsItemResult } from '../interfaces/ItemResult';
import { DsvsPage, DsvsPaged } from '../interfaces/Paged';
import { DsvsPagedResult } from '../interfaces/PagedResult';

export interface DsvsSearchableReadEntityService<T> {
  getById(id: string): Observable<DsvsItemResult<T>>;

  search(searchTerm: string, options?: DsvsPage): Observable<DsvsPagedResult<T>>;

}

export interface Item<T> {
  data: T;
}

@Injectable()
export class DsvsSearchableReadEntityServiceImpl<T> implements DsvsSearchableReadEntityService<T> {

  constructor() {
  }

  getById(id: string): Observable<DsvsItemResult<T>> {
    return null;
  }

  search(searchTerm: string, options?: DsvsPage): Observable<DsvsPagedResult<T>> {
    return null;
  }

}

@Injectable()
export class DsvsSearchableReadEntityServiceMock<T> implements DsvsSearchableReadEntityService<T> {

  protected data: any[];

  constructor() {
  }

  getById(id: string): Observable<DsvsItemResult<T>> {
    let role: T = null;

    this.data.forEach(r => {
      if (r['id'] === id) {
        role = r;
        return;
      }
    });

    if (role) {
      return Observable.of(<DsvsItemResult<T>>{data: role}).delay(500);
    } else {
      return ErrorObservable.create('Not found').delay(500);
    }
  }

  getAll(): Observable<DsvsPagedResult<T>> {

    const page: DsvsPaged = {
      size: this.data.length,
      number: 0,
      sort: [],
      totalElements: this.data.length,
      totalPages: 1
    };
    const content: Array<DsvsItemResult<T>> = this.data.map((d) => {
      return {data: d};
    });
    const response: DsvsPagedResult<T> = {
      page,
      content
    };
    return of(response).delay(500);
  }

  search(searchTerm: string, page?: DsvsPage): Observable<DsvsPagedResult<T>> {

    if (!page) {
      page = {};
    }

    if (!page.size) {
      page.size = 10;
    }
    if (!page.number) {
      page.number = 0;
    }

    const content: Array<DsvsItemResult<T>> = [];

    const start = page.number * page.size;
    const end = start + page.size;
    for (let i = start; i < end; i++) {
      if (i < this.data.length && this.data[i].displayName.toLowerCase().includes(searchTerm.toLowerCase())) {
        content.push(<DsvsItemResult<T>>{data: this.data[i]});
      }
    }

    const paged: DsvsPaged = {
      totalElements: this.data.length,
      totalPages: Math.ceil(this.data.length / page.size),
      size: page.size,
      number: page.number,
      sort: []
    };

    return of(<DsvsPagedResult<T>>{
      content: content,
      page: paged
    }).delay(500);
  }

}
