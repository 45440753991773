import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dsvs-showcase-example-code',
  template: `<h3>Code</h3>
<pre>
<code class="language-markup" pCode>
  <ng-content></ng-content>
</code>
</pre>
`,
  styles: [``]
})
export class ShowcaseExampleCodeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
