import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationType'
})
export class DsvsNotificationTypePipe implements PipeTransform {

  /** Pipe zur Transformation der Notification-Types fÃ¼r die tabellenanzeige
   * @param  value
   * @returns
   */
  transform(value: string): string {
    if ( value === 'DOWN') {
      return 'Download';
    } else if (value === 'SYS') {
      return 'Systemnachricht';
    }
  }

}
