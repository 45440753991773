import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { DsvsAuthorizationService } from '../services/authorization.service';
import { DsvsPermissionService, PermissionOperator } from '../services/permission.service';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
  private currentUser;
  private permissions = [];
  private logicalOp: PermissionOperator = 'AND';
  private isHidden = true;

  constructor(private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: DsvsPermissionService,
    private authService: DsvsAuthorizationService) {

  }

  ngOnInit() {

    this.updateView();

    this.authService.getLoginDone().subscribe(result => {
      this.updateView();
    });

  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set hasPermissionOperation(permop) {
    this.logicalOp = permop;
    this.updateView();
  }

  private updateView() {
    if (this.permissionService.hasPermissionWithOperator(this.permissions, this.logicalOp)) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }
}
