import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortener'
})
export class DsvsShortenerPipe implements PipeTransform {

  /** Pipe um bei einem String nur eine Maximal-LÃ¤nge anzuzeigen
   * @param value
   * @param max length
   * @returns
   */
  transform(value: string, max: number = 10): string {
    return (value && typeof value === 'string' && value.length > max) ? value.substr(0, max - 1) + '...' : value;
  }

}
