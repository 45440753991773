import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WorkflowGeneratorConfigurationService } from './workflow-generator-configuration.service';

@Injectable({
              providedIn: 'root'
            })
export class WorkflowGeneratorRoutingService {

  constructor(private router: Router,
              private config: WorkflowGeneratorConfigurationService) {
  }

  private navigateTo(url: string) {
    this.router.navigate([this.config.config.ui_url + url]);
  }

  navigateToCrews() {
    this.navigateTo('/crews');
  }

  navigateToWorkflows() {
    this.navigateTo('/crews');
  }

  navigateToRoles() {
    this.navigateTo('/roles');
  }

  navigateToCategories() {
    this.navigateTo('/categories');
  }

}
