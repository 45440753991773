import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PanelModule } from 'primeng/panel';
import { ConfirmDialogModule, DropdownModule } from 'primeng/primeng';
import { DsvsSettingsComponent } from './components/settings/settings.component';
import { DsvsSettingsDeactivateGuard } from './guards/settings-deactivate.guard';
import { DsvsSettings } from './interfaces/dsvs-settings';
import { DsvsSettingsResolver } from './resolvers/settings-resolver';
import { DsvsSettingsService } from './services/settings-service';

@NgModule({
  imports: [
    CommonModule,
    ConfirmDialogModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    PanelModule,
    HttpClientModule
  ],
  declarations: [DsvsSettingsComponent],
  exports: [DsvsSettingsComponent],
  providers: [
    DsvsSettingsService,
    DsvsSettingsResolver,
    DsvsSettings,
    DsvsSettingsDeactivateGuard
  ]
})
export class DsvsSettingsModule {
}
