import { Component, OnInit } from '@angular/core';
import { DsvsAuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'dsvs-logout',
  template: `<dsvs-landing-page [subMessage]="logoutMessage"></dsvs-landing-page>
`,
  styles: [``]
})
export class DsvsLogoutComponent implements OnInit {

  constructor(private authService: DsvsAuthorizationService) { }

  logoutMessage: string;

  ngOnInit() {
    const salutation = this.authService.getSalutation();
    this.authService.logout().then(loginState => {
      this.logoutMessage = 'Auf Wiedersehen ' + salutation + ', Sie haben sich erfolgreich abgemeldet.';
    });
  }

}
