import { HttpClient } from '@angular/common/http';
import { Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DsvsAuthorizationService } from '../../../authentication/services/authorization.service';
import { DsvsSharedUiLibConfigService } from '../../../dsvs-shared-ui-lib-config.service';
import { DsvsNotificationData } from '../../../notification/interfaces/notification-data';
import { DsvsNotificationService } from '../../../notification/services/notification.service';
import { DsvsLayoutStateService } from '../../services/layout-state.service';

@Component({
  selector: 'dsvs-topbar',
  template: `<p-growl [(value)]="msgs"></p-growl>
<div class="layout-topbar">
  <img alt="logo" src="{{configService.config.layout.topbar.logoUrl}}" style="width: 260px" class="mobile-logo"/>

  <a href="#" class="menu-btn" (click)="layoutState.onMenuButtonClick($event)">
    <i class="material-icons">&#xE5D2;</i>
  </a>

  <a href="#" class="topbar-menu-btn" (click)="layoutState.onTopbarMobileMenuButtonClick($event)">
    <i class="material-icons">&#xE853;</i>
  </a>

  <div class="layout-topbar-menu-wrapper">
    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-active': layoutState.topbarMenuActive}"
        (click)="layoutState.onTopbarMenuClick($event)">
      <li class="logo-colored">
        <a routerLink="{{configService.config.routing.home}}" href="#" style="vertical-align: middle">
          <img src="{{configService.config.layout.topbar.logoUrl}}" style="width: 260px"/>
        </a>
      </li>
      <li #profile class="profile-item" [ngClass]="{'active-topmenuitem': layoutState.activeTopbarItem === profile}"
          (click)="layoutState.onTopbarRootItemClick($event, profile)">
        <a href="#" style="vertical-align: middle">
          <i class="material-icons">account_circle</i>
          <span class="topbar-item-name profile-name"> {{salutation}} </span>
        </a>
        <ul class="fadeInDown">
          <li role="menuitem">
            <a routerLink="{{configService.config.routing.userinfo}}">
              <i class="material-icons">account_circle</i>
              <span>Benutzer</span>
            </a>
          </li>
          <li role="menuitem">
            <a routerLink="{{configService.config.routing.settings}}">
              <i class="material-icons">settings_application</i>
              <span>Einstellungen</span>
            </a>
          </li>
          <li role="menuitem">
            <a routerLink="{{configService.config.routing.logout}}">
              <i class="material-icons">exit_to_app</i>
              <span>Abmelden</span>
            </a>
          </li>
        </ul>
      </li>
      <li #notifications [ngClass]="{'active-topmenuitem':layoutState.activeTopbarItem === notifications}"
          (click)="layoutState.onTopbarRootItemClick($event, notifications)">
        <a href="#">
          <i class="topbar-icon material-icons">notifications</i>
          <span class="topbar-badge animated rubberBand">{{ notificationService.notificationListUnread.length }}</span>
          <span class="topbar-item-name">Notifications</span>
        </a>
        <ul *ngIf="notificationService.notificationListUnread.length === 0" class="fadeInDown">
          <li role="menuitem">
            <p class="text-dsvs" style="margin: 1rem">Sie haben zurzeit keine ungelesenen Benachrichtigungen.</p>
          </li>
        </ul>
        <ul *ngIf="notificationService.notificationListUnread.length > 0" class="fadeInDown">
          <li>
            <ul>
              <li *ngFor="let notify of notificationService.notificationListUnread" role="menuitem" class="notification-item">
                <a href="" (click)="onNotificationClick(notify.id)" [pTooltip]="notify.menu_title" tooltipZIndex="1008"
                   tooltipPosition="left">
                  <i class="material-icons">{{ notify.menu_icon }}</i>
                  <span class="item-text">{{ notify.menu_title }}</span>
                </a>
                <!--
                <button pButton type="button" icon="ui-icon-visibility" pTooltip="Nachricht als gelesen kennzeichnen" tooltipZIndex="1008" class="dsvs-btn-notify"></button>
                -->
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <a [routerLink]="" (click)="showSearch()">
          <i class="topbar-icon material-icons">search</i>
          <span class="topbar-item-name">Search</span>
        </a>
      </li>
      <!--<li class="search-item">-->
      <!--<span class="md-inputfield">-->
      <!--<input type="text" pInputText/>-->
      <!--<label>Suchen</label>-->
      <!--<i class="topbar-icon material-icons">search</i>-->
      <!--</span>-->
      <!--</li>-->
      <li>
        <ng-container #extensionContainer></ng-container>
      </li>
    </ul>
  </div>
</div>

<p-dialog [(visible)]="displaySearch" [showHeader]="false"
          [modal]="true"
          [width]="700"
          minHeight="auto"
          [dismissableMask]="true">
  <div class="global-search-input-container">
    <span class="material-icons">search</span>
    <input type="text" #globalSearchInput class="global-search-input" pInputText [(ngModel)]="searchValue"
           placeholder="Suche nach..."/>
  </div>

  <table class="global-search-results" *ngIf="searchValue != ''">
    <tr *ngFor="let result of searchResults">
      <td class="type">{{result.type}}</td>
      <td class="number">{{result.number}}</td>
      <td class="description">{{result.description}}</td>
      <td class="result">{{result.results}} Vorkommen</td>
    </tr>
  </table>

  <p-footer *ngIf="searchValue != ''">
    <button (click)="openSearchPage(searchValue)" class="dsvs-btn-primary">Alle <strong>XY</strong> Ergebnisse anzeigen
    </button>
  </p-footer>


</p-dialog>
`,
  styles: [`.global-search-input-container{display:flex;align-items:center}.global-search-input-container .global-search-input{width:100%;padding-left:40px!important;margin-left:-26px;color:#04173f}.global-search-input-container .material-icons{z-index:1;color:#4f70b6;padding-left:10px;font-size:28px}.global-search-results{width:100%;border-collapse:collapse}.global-search-results td{border:none}.global-search-results tr{border-bottom:1px solid #ebecf0}.global-search-results .type{color:#8c8f95;font-size:12pt}.global-search-results .description,.global-search-results .number{color:#21428a;font-size:13pt}.global-search-results .result{color:#bdbfc3;font-size:12pt;text-align:end}`]
})
export class DsvsTopbarComponent implements OnInit, OnDestroy {

  @ViewChild('extensionContainer', {read: ViewContainerRef}) extensionContainer;

  /* Messages */
  msgs: Message[] = [];

  salutation: string;

  /** Subscriptions fÃ¼r den Notification-Service */
  notificationRefresh: Subscription;
  notificationAlert: Subscription;

  /** Liste der neu eingegangenen Nachrichten zum Einblenden als Growl-Messages */
  newNotifications: DsvsNotificationData[] = [];

  /** Suche anzeigen */
  @ViewChild('globalSearchInput') globalSearchInput: ElementRef;
  displaySearch = false;
  searchValue = '';
  searchResults = [
    {
      type: 'Formular',
      number: '490 280 342',
      description: 'Lorem ipsum dolor sit',
      results: 4241
    },
    {
      type: 'Formular',
      number: '490 280 342',
      description: 'Lorem ipsum dolor sit',
      results: 4241
    }
  ];

  constructor(public layoutState: DsvsLayoutStateService,
    private router: Router,
    private authService: DsvsAuthorizationService,
    public notificationService: DsvsNotificationService,
    public configService: DsvsSharedUiLibConfigService,
    private http: HttpClient,
    private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {

    /** Notificatione Refresh-Trigger abonnieren um festzustellen ob
     * nach der Initialisierung neue oder ungelesene Notifications verfÃ¼bgar sind
     * Falls es die erste Initialisierung ist muss auch noch der Benachrichtigungs-
     * Alarm abonniert werden*/
    this.notificationRefresh = this.notificationService.notificationRefresh.subscribe(result => {
      if (result === true) {
        this.msgs = [];
        /** Ungelesene Nachricht(en) (einzahl/mehrzahl) */
        if (this.notificationService.notificationListUnread.length > 0) {
          let singleMulti = 'Benachrichtigung';
          if (this.notificationService.notificationListUnread.length > 1) {
            singleMulti += 'en';
          }
          this.msgs.push({
            severity: 'info',
            summary: 'FES-Anwenderinformation',
            // tslint:disable-next-line
            detail: 'Sie haben insgesamt ' + this.notificationService.notificationListUnread.length + ' ungelesene ' + singleMulti
          });
        }
        /** Neue Nachricht(en) (einzahl/mehrzahl) */
        if (this.notificationService.newNotificationCount > 0) {
          let singleMulti = 'Benachrichtigung';
          if (this.notificationService.newNotificationCount > 1) {
            singleMulti += 'en';
          }
          this.msgs.push({
            severity: 'success',
            summary: 'Benachrichtigungsservice',
            detail: 'Sie haben ' + this.notificationService.newNotificationCount + ' neue ' + singleMulti
          });
        }
        if (!this.notificationAlert) {
          /** Notificatione Alarm abonnieren um festzustellen ob neue Notifications verfÃ¼bgar sind */
          this.notificationAlert = this.notificationService.notificationAlert.subscribe(result2 => {
            // console.log('Subscribe auf Notification alert');
            if (result2) {
              /** Die neu eingegangenen Nachrichten abholen */
              // console.log('Neue Nachrichten abholen');
              this.notificationService.getNewNotifications(this.authService.getJWT()).subscribe(data => {
                /** Einzelne Notifications anzeigen */

                // console.log('Nachrichten:');
                // console.log(data);
                if (data.length > 0) {
                  /** Polling stoppen */
                  this.notificationService.stopPolling();
                  /** Neue Nachrichten als abgehlt markieren */
                  this.notificationService.markNotificationsAsFetched(this.authService.getJWT());
                  this.newNotifications = [];
                  /** Die einzelnen neuen Nachrichten je nach Typ anzeigen */
                  for (const notification of data) {
                    /** als ungelesen markieren und zur Liste hunzufÃ¼gen */
                    notification.readType = 0;
                    this.newNotifications.push(notification);
                    const detail: string = notification.msg_text;
                    /** HTML Injection funktioniert nicht mit Sicherheitsrelevanten Tags/Script
                     if (notification.msg_has_link) {
                      // Je nach Notification-Type wird ein anderer Link aufgebaut (Download, router-Link...)
                      switch (notification.type) {
                        case 'DOWN':
                          if (notification.file) {
                          // tslint:disable-next-line
                            detail = detail.concat('<br><br>Direkt zum Dateidownload:<br>', '<a href="" routerLink="/notifications/',
                            notification.file.id.toString(), '">', notification.msg_link_text, '</a>');
                            console.log(detail);
                          }
                          break;
                      }
                    }
                     */
                    this.msgs.push({
                      severity: 'info',
                      summary: notification.msg_title,
                      detail: detail
                    });
                  }
                  /** Die neuen Nachrichten zu den Listen hinzufÃ¼gen */
                  this.notificationService.addNewNotifications(this.newNotifications);
                  /** Polling wieder starten */
                  this.notificationService.startPolling(this.authService.getJWT());
                }
              });
            }
          });
        }
        this.notificationRefresh.unsubscribe();
      } else {
        if (this.notificationService.errorHandler.hasError) {
          this.msgs.push({
            severity: this.notificationService.errorHandler.errType,
            summary: this.notificationService.errorHandler.msgTitle,
            detail: this.notificationService.errorHandler.msgBody
          });
          this.notificationService.errorHandler.clear();
          if (this.notificationAlert) {
            this.notificationAlert.unsubscribe();
            this.notificationService.stopPolling();
          }
        }
      }
    });

    this.salutation = this.authService.getSalutation();

    this.authService.getLoginDone().subscribe(value => {
      this.salutation = this.authService.getSalutation();
    });
  }

  ngOnDestroy() {
    if (this.notificationRefresh) {
      this.notificationRefresh.unsubscribe();
    }
    if (this.notificationAlert) {
      this.notificationAlert.unsubscribe();
    }
  }

  /** Eine Notification wurde im MenÃ¼ angewÃ¤hlt */
  onNotificationClick(nfID: number) {
    const notification = this.notificationService.notificationListTotal.find(item => item.id === nfID);
    /** je nach Art der Notification werden andere Actions ausgelÃ¶st */
    if (notification) {
      /** Notification Growls ausblenden) */
      this.msgs = [];
      /** Notification als gelesen markieren */
      const nfIDs: number[] = [nfID];
      this.notificationService.markNotificationAsRead(this.authService.getJWT(), nfIDs);
      /** Falls die Notification-View Komponente aktiv ist muss kein Routing mehr erfolgen,
       * aber der Click auf das MenuItem muss getriggert werden, damit die Notification-View
       * aktualisiert werden kann (Item in der Tabelle wird selected und expanded)
       */
      if (this.notificationService.isInNotificationView) {
        this.notificationService.triggerMenuItemClick(nfID);
      } else {
        switch (notification.type) {
          case 'SYS':
          case 'DOWN':
            this.router.navigate(['/notifications/' + nfID]);
            break;
        }
      }
    }
  }

  /** Service-Implementierung zum direkten Download von Dateien aus der
   * Notification - Ã¼ber den generierten Direktlink
   */
  directDownload(fileID: number) {
    // nach Backend-Anpassung wieder aus FMS Ã¼bernehmen
    // this.downloadService.downloadFile(this.authService.getJWT(), fileID, 'route');
  }

  public setExtensionComponent(view: any) {
    this.extensionContainer.createComponent(this.componentFactoryResolver.resolveComponentFactory(view));
  }

  showSearch() {
    this.displaySearch = true;
  }

  focusInput() {
    this.globalSearchInput.nativeElement.focus();
  }

  openSearchPage(searchValue: string) {
    this.router.navigate(['/search/' + searchValue]);
    this.displaySearch = false;
  }

}
