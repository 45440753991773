import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {GrowlModule} from 'primeng/growl';
import {DialogModule, InputTextModule, TooltipModule} from 'primeng/primeng';
import {DsvsAuthenticationModule} from '../authentication/authentication.module';
import {DsvsBreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {DsvsFooterComponent} from './components/footer/footer.component';
import {AppSubMenuComponent, DsvsMenuComponent} from './components/menu/menu.component';
import {DsvsStartComponent} from './components/start/start.component';
import {DsvsTopbarComponent} from './components/topbar/topbar.component';
import {DsvsToolbarToolDirective} from './directives/toolbar-tool.directive';
import {DsvsBreadcrumbService} from './services/breadcrumb.service';
import {DsvsBaseLayoutService} from './services/layout-base.service';
import {DsvsLayoutStateService} from './services/layout-state.service';
import {DsvsMenuService} from './services/menu.service';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule,
    GrowlModule,
    HttpClientModule,
    DsvsAuthenticationModule,
    DialogModule,
    InputTextModule,
    FormsModule
  ],
  declarations: [
    DsvsFooterComponent,
    DsvsBreadcrumbComponent,
    DsvsToolbarToolDirective,
    DsvsMenuComponent,
    AppSubMenuComponent,
    DsvsTopbarComponent,
    DsvsStartComponent
  ],
  exports: [
    DsvsFooterComponent,
    DsvsBreadcrumbComponent,
    DsvsMenuComponent,
    DsvsTopbarComponent
  ],
  providers: [
    DsvsBreadcrumbService,
    DsvsLayoutStateService,
    DsvsMenuService,
    DsvsBaseLayoutService
  ],
  entryComponents: [
    DsvsTopbarComponent,
    DsvsFooterComponent,
    DsvsMenuComponent,
    DsvsBreadcrumbComponent
  ]
})
export class DsvsLayoutModule {
}
