import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Message } from 'primeng/api';
import { DsvsAuthorizationService } from '../../../authentication/services/authorization.service';
import { DsvsBreadcrumbService } from '../../../layout/services/breadcrumb.service';
import { DsvsAppSettings } from '../../interfaces/app-settings';
import { DsvsSettings } from '../../interfaces/dsvs-settings';
import { DsvsUserSettings } from '../../interfaces/user-settings';
import { DsvsSettingsService } from '../../services/settings-service';

@Component({
             selector: 'dsvs-settings',
             template: `<p-confirmDialog header="Achtung" icon="" acceptLabel="Ja" rejectLabel="Nein" [closable]="false"></p-confirmDialog>
<div class="ui-g">
  <div class="ui-lg-6 ui-md-8 ui-sm-10 ui-lg-offset-3 ui-md-offset-2 ui-sm-offset-1">
    <div class="card">
      <p-panel header="Benutzereinstellungen">
        <form #settingsForm="ngForm" (ngSubmit)="updateSettings()">
          <div class="mt-5 mb-5">
            <div class="ui-g ui-fluid">
              <div class="ui-g-12">
                <table>
                  <tr>
                    <th>Beschreibung</th>
                    <th>Wert</th>
                  </tr>
                  <tr *ngFor="let setting of settings.settings">
                    <td>{{ setting.desc }}</td>
                    <td>
                      <p-dropdown [options]="setting.options" [(ngModel)]="setting.selectvalue" optionLabel="desc"
                                  [name]="setting.key"></p-dropdown>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <button pButton type="submit" class="dsvs-btn ui-corner-all" label="Ãbernehmen" icon="fa ui-icon-input"
                    [disabled]="settingsForm.invalid || !settingsForm.form.dirty"></button>
            <button pButton type="button" (click)="resetSettings()" class="dsvs-btn ui-corner-all" label="ZurÃ¼cksetzen"
                    icon="fa ui-icon-undo" [disabled]="!settingsForm.form.dirty"></button>
          </div>
        </form>
      </p-panel>
    </div>
  </div>
</div>
`,
             styles: [`table{width:100%;border-collapse:collapse}th{background-color:#dbdbdb}td,th{padding:6px;border:0;text-align:left}`]
           })
export class DsvsSettingsComponent implements OnInit {

  @ViewChild('settingsForm') settingsForm: NgForm;
  settings: DsvsAppSettings;
  msgs: Message[] = [];

  constructor(private breadcrumbService: DsvsBreadcrumbService,
              private authService: DsvsAuthorizationService,
              private optSettings: DsvsSettingsService,
              private apoSettings: DsvsSettings) {
  }

  ngOnInit() {
    this.breadcrumbService.setMenuItems([
                                          {label: 'Einstellungen'}
                                        ]);
    this.settings = this.apoSettings.getSettings();
  }

  /** Cookie-Settings mÃ¼ssen immer in den LocalStorage zurÃ¼ckgeschrieben werden. */
  updateSettings() {
    this.msgs = [];
    const uid: number = +this.authService.getUserData().result.id;
    const userSettings: DsvsUserSettings[] = [];
    for (const setting of this.apoSettings.settings) {
      setting.value = setting.selectvalue.key;
      userSettings.push({
                          scope: this.apoSettings.scope,
                          user_id: uid,
                          key: setting.key,
                          value: setting.value
                        });
      if (setting.persist === 'COOKIE') {
        localStorage.setItem('fes_' + setting.key, setting.value);
      }
    }
    this.optSettings.updateUserSettings(userSettings).subscribe(res => {
      if (res.success) {
        this.msgs.push({
                         severity: 'success',
                         summary: 'Benutzereinstellungen',
                         detail: 'Ihre persÃ¶nlichen Einstellungen wurden aktualisiert.'
                       });
        this.settingsForm.form.markAsPristine();
      } else {
        this.msgs.push({
                         severity: 'warn',
                         summary: 'Benutzereinstellungen',
                         detail: 'Beim aktualisieren der Einstellungen ist ein Fehler aufgetreten:' + '<br>' + res.msg
                       });
      }
    }, (e: HttpErrorResponse) => {
      this.msgs.push({
                       severity: 'warn',
                       summary: 'Benutzereinstellungen',
                       detail: 'Beim aktualisieren der Einstellungen ist ein Fehler aufgetreten:' + '<br>' + e.error
                     });
    });

  }

  resetSettings() {
    this.optSettings.getAppSettings().subscribe(data => {
      this.settingsForm.reset();
      this.apoSettings.initApp(data);
      this.optSettings.getUserSettings().subscribe(data2 => {
        this.apoSettings.initUser(data2);
        this.settings = this.apoSettings.getSettings();
      });
    });
  }

}
