import { Injectable } from '@angular/core';
import { DsvsBreadcrumbService } from './breadcrumb.service';

@Injectable({
  providedIn: 'root'
})
export class DsvsLayoutStateService {

  constructor(private breadcrumbService: DsvsBreadcrumbService) {
  }

  public sidebarActive: boolean;
  public mobileMenuActive: boolean;
  public menuClick: boolean;
  public menuButtonClick: boolean;
  public topbarMenuButtonClick: boolean;
  public topbarMenuClick: boolean;
  public topbarMenuActive: boolean;
  public activeTopbarItem: Element;
  public layoutStatic = true;

  onWrapperClick() {
    if (!this.menuClick && !this.menuButtonClick) {
      this.mobileMenuActive = false;
    }

    if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
      this.topbarMenuActive = false;
      this.activeTopbarItem = null;
    }

    this.menuClick = false;
    this.menuButtonClick = false;
    this.topbarMenuClick = false;
    this.topbarMenuButtonClick = false;

    /*Toolbar-SubmenÃ¼-Steuerung*/
    this.breadcrumbService.resetSubmenu(true);
  }

  onMenuButtonClick(event: Event) {
    this.menuButtonClick = true;

    if (this.isMobile()) {
      this.mobileMenuActive = !this.mobileMenuActive;
    }

    event.preventDefault();
  }

  onTopbarMobileMenuButtonClick(event: Event) {
    this.topbarMenuButtonClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;
    event.preventDefault();
  }

  onTopbarRootItemClick(event: Event, item: Element) {
    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onTopbarMenuClick(event: Event) {
    this.topbarMenuClick = true;
  }

  onSidebarClick(event: Event) {
    this.menuClick = true;
  }

  onToggleMenuClick(event: Event) {
    this.layoutStatic = !this.layoutStatic;
  }

  /** UI-Breakpoint fÃ¼r den Mobile-Mode */
  isMobile() {
    return window.innerWidth < 1240;
  }
}
