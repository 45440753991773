import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { TagHalItem, TagHalPage, WorkflowTag } from '@dsvs/workflow-generator-dto';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowTagService extends DsvsSearchableCrudEntityService<WorkflowTag> {

}

// @dynamic
@Injectable()
export class WorkflowTagServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowTag, TagHalItem, TagHalPage>
  implements WorkflowTagService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'tag', collection: 'tags'},
      WorkflowGeneratorTypeMappingService.WorkflowTag,
      typeResolver
    );
  }
}
