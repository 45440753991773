import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DsvsAuthorizationService } from '../../authentication/services/authorization.service';
import { DsvsSharedUiLibConfigService } from '../../dsvs-shared-ui-lib-config.service';

@Injectable()
export class DsvsAuthInterceptor implements HttpInterceptor {

  private notauthNotificationFired = false;

  constructor(private authService: DsvsAuthorizationService,
              private configService: DsvsSharedUiLibConfigService,
              private router: Router,
              private messageService: MessageService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedRequest = req;

    /** Set header only if there is no authorization-header */
    if (req.headers.get('authorization') === null && this.authService.getJWT() !== null) {
      clonedRequest = req.clone({
        setHeaders: {
          'authorization': `Bearer ${this.authService.getJWT()}`
        }
      });
    }

    return next
      .handle(clonedRequest)
      .pipe(tap((event: HttpEvent<any>) => {
        }, (err: any) => {
          this.handleError(err);
        }
      ));
  }

  private handleError(err: any) {
    if (err instanceof HttpErrorResponse) {
      // if (err.status === 401) {
      //   // redirect to the login route
      //   // or show a modal
      //   this.authService.logout().then(loginState => {
      //     window.location.href = this.configService.config.communication.apourl + '/login';
      // ?returnurl=' + encodeURIComponent(window.location.href);
      //   });
      // }

      if (err.status === 401) {

        if (!this.notauthNotificationFired) {

          this.messageService.add({
            severity: 'error',
            summary: 'Session ist ungÃ¼ltig oder abgelaufen',
            detail: 'Sie werden automatisch abgemeldet.'
          });
          this.notauthNotificationFired = true;
          setTimeout(() => {
            this.notauthNotificationFired = false;
            this.router.navigate(['logout']);
          }, 3500);
        }
      }
    }
  }

}
