import { DsvsSort } from '@dsvs/dsvs-shared-ui-lib';
import { Observable } from 'rxjs/Observable';
import { HalClientFactory } from '../client-factory.interface';
import { HalClient, HalItemConstructor } from '../client.interface';
import { Page } from '../data/page.typedef';
import { HalItemImpl } from './item.impl';
import { HalItem } from './item.interface';
import { HalPage } from './page.interface';

export class HalPageImpl<T, R extends HalItem<T>> extends HalItemImpl<Page> implements HalPage<T, R> {
    public readonly content: Array<R>;
    public page: Page;

    constructor(data: Page,
        client: HalClient,
        factory: HalClientFactory,
        protected readonly contentRel: string,
        private readonly itemConstructor?: HalItemConstructor<T, R>) {
        super(<any>data, client, factory);
        this.content = this.getTypedEmbedded<T, R>(this.itemConstructor, this.contentRel);
        this.page = this.data;
    }

    getTotalElements(): number {
        return this.page.totalElements;
    }

    getTotalPages(): number {
        return this.page.totalPages;
    }

    getPageNumber(): number {
        return this.page.number;
    }

    getPageSize(): number {
        return this.page.size;
    }

    getSortInfo(): DsvsSort[] {
        return this.page.sort;
    }

    getQueryInfo(): string[] {
        return null;
    }

    push(el: T) {
        return null;
    }

    size(): number {
        return this.content.length;
    }

    nextPage(): Observable<HalPage<T, R>> {
        return <any>this.client.typedResources<T, R, any>((<any>this.constructor), this.itemConstructor, 'next');
    }

    prevPage(): Observable<HalPage<T, R>> {
        return <any>this.client.typedResources<T, R, any>((<any>this.constructor), this.itemConstructor, 'prev');
    }

    firstPage(): Observable<HalPage<T, R>> {
        return <any>this.client.typedResources<T, R, any>((<any>this.constructor), this.itemConstructor, 'first');
    }

    lastPage(): Observable<HalPage<T, R>> {
        return <any>this.client.typedResources<T, R, any>((<any>this.constructor), this.itemConstructor, 'last');
    }
}
