import {DsvsAppSettings} from '../interfaces/app-settings';
import {DsvsOption} from '../interfaces/option';
import {DsvsUserSettings} from './user-settings';
import {Observable} from 'rxjs';

export class DsvsSettings implements DsvsAppSettings {

  clientid: string;
  scope: string;
  success = false;
  msg?: string;
  settings: [{
    key: string;
    desc: string;
    type: string;
    default: string;
    persist: string;
    options?: DsvsOption[];
    value?: string;
    selectvalue?: DsvsOption;
  }];

  /** Initialisierung der Settings fÃ¼r die App hier werden
   * alle fÃ¼r diese App mÃ¶glichen Settings vor dem Login eines Users ausgelesen.
   * In diesem Fall mÃ¼ssen die Werte der Settings die mit persist=COOKIE
   * gekennzeichnet sind, falls vorhanden, aus dem LocalStorage (Cookie) ausgelesen werden.
   * Dies wird fÃ¼r alle Werte benÃ¶tigt die schon vor dem Login eines Users
   * greifen mÃ¼ssen (z.B. die bevorzugte Login-Art (E-Mail oder User-ID)
   * @param  data
   */
  initApp(data: DsvsAppSettings): Observable<boolean> {
    this.clientid = data.clientid;
    this.scope = data.scope;
    this.success = data.success;
    this.msg = data.msg;
    for (const setting of data.settings) {
      setting.value = setting.default;
      if (setting.persist === 'COOKIE') {
        if (localStorage.getItem('fes_' + setting.key)) {
          setting.value = localStorage.getItem('fes_' + setting.key);
        }
      }
      if (setting.type === 'BOOL') {
        setting.options = [
          {key: 'true', desc: 'ja'},
          {key: 'false', desc: 'nein'}
        ];
      }
      setting.selectvalue = setting.options.find(item => item.key === setting.value);
    }
    this.settings = data.settings;
    return Observable.of(true);
  }

  /** ggf. die Settings mit den vom Anwender geÃ¤nderten Werten Ã¼berschreiben
   * @param  us
   */
  initUser(us: DsvsUserSettings[]) {
    for (const setting of us) {
      const app_s = this.settings.find(item => item.key === setting.key);
      if (app_s) {
        app_s.value = setting.value;
        app_s.selectvalue = app_s.options.find(item => item.key === setting.value);
      }
    }
  }

  /** Einstellung aus den Settings Ã¼ber den Key auslesen
   * @param key
   * @param _default
   * @returns
   */
  getOptionValue(key: string, _default: any): any {
    // console.log(this.settings);
    const setting = this.settings.find(item => item.key === key);
    if (setting) {
      return setting.value;
    } else {
      return _default;
    }
  }

  /** Optionen einer Einstellungen (bei Auswahlfeldern) Ã¼ber den Key auslesen
   * @param  key
   * @param def
   * @returns
   */
  getOptionParameters(key: string, def: any[]): any[] {
    const setting = this.settings.find(item => item.key === key);
    if (setting) {
      const params: any[] = [];
      for (const param of setting.options) {
        params.push(param.key);
      }
      return params;
    } else {
      return def;
    }
  }

  /** Methode zum Abruf der Settings
   * @returns
   */
  getSettings(): DsvsAppSettings {
    return {
      clientid: this.clientid,
      scope: this.scope,
      success: this.success,
      msg: this.msg,
      settings: this.settings
    };
  }
}
