import { FormControl, FormGroup } from '@angular/forms';

export class DsvsFormHelper {

  static validateAllFormFields(formGroup: FormGroup) {
    if (formGroup) {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({onlySelf: true});
        } else if (control instanceof FormGroup) {
          this.validateAllFormFields(control);
        }
      });
    }

  }
}
