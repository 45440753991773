import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DsvsSharedModule } from '@dsvs/dsvs-shared-ui-lib';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule, ProgressSpinnerModule } from 'primeng/primeng';
import { ActionHostDirective } from './components/action-host.directive';
import { WorkflowActionFactoryComponent } from './components/workflow-action-factory/workflow-action-factory.component';
/* tslint:disable:max-line-length */
import { WorkflowActionHistoryFactoryComponent } from './components/workflow-action-history-factory/workflow-action-history-factory.component';
import { WorkflowActionHistoryEntryFileComponent } from './components/workflow-action-history/workflow-action-history-entry-file/workflow-action-history-entry-file.component';
import { WorkflowActionHistoryEntryComponent } from './components/workflow-action-history/workflow-action-history-entry/workflow-action-history-entry.component';
/* tslint:enable:max-line-length */
import { WorkflowActionHistoryComponent } from './components/workflow-action-history/workflow-action-history.component';
import { WorkflowActionExtensionService } from './services/config/workflow-action-extension.service';
import { WorkflowTypeMappingService } from './services/config/workflow-type-mapping.service';
import { WorkflowActionContextServiceImpl } from './services/data/workflow-action-context.service';
import { WorkflowContextServiceImpl } from './services/data/workflow-context.service';

import { WorkflowStepContextServiceImpl } from './services/data/workflow-step-context.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    /* PrimeNG */
    ButtonModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    DsvsSharedModule
  ],
  declarations: [
    ActionHostDirective,
    WorkflowActionHistoryComponent,
    WorkflowActionFactoryComponent,
    WorkflowActionHistoryFactoryComponent,
    WorkflowActionHistoryEntryComponent,
    WorkflowActionHistoryEntryFileComponent
  ],
  providers: [
    WorkflowTypeMappingService,
    WorkflowActionExtensionService,
    {provide: WorkflowActionContextServiceImpl, useClass: WorkflowActionContextServiceImpl},
    {provide: WorkflowContextServiceImpl, useClass: WorkflowContextServiceImpl},
    {provide: WorkflowStepContextServiceImpl, useClass: WorkflowStepContextServiceImpl}
  ],
  exports: [
    WorkflowActionFactoryComponent,
    WorkflowActionHistoryFactoryComponent,
    WorkflowActionHistoryComponent,
    WorkflowActionHistoryEntryComponent,
    WorkflowActionHistoryEntryFileComponent
  ],
  entryComponents: [
    WorkflowActionHistoryComponent
  ]
})
export class WorkflowModule {
}
