import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ClickOutsideModule} from 'ng-click-outside';
import {AutoCompleteModule, DropdownModule} from 'primeng/primeng';
import {HalAsyncLoaderComponent} from './components/hal-async-loader.component';
import {NgxContentLoadingModule} from 'ngx-content-loading';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    AutoCompleteModule,
    ClickOutsideModule,
    NgxContentLoadingModule,
  ],
  declarations: [
    HalAsyncLoaderComponent
  ],
  exports: [
    HalAsyncLoaderComponent
  ]
})
export class DsvsHalAsyncLoaderModule { }
