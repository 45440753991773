import { formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable, Subject } from 'rxjs';
import { DsvsSharedUiLibConfigService } from '../../dsvs-shared-ui-lib-config.service';
import { DsvsErrorHandler } from '../../error/classes/dsvs-error-handler';
import { InstituteSendData } from '../interfaces/institute-send-data';

@Injectable()
export class DsvsFileDownloadService {

  errorHandler: DsvsErrorHandler = new DsvsErrorHandler;

  constructor(private http: HttpClient, private configService: DsvsSharedUiLibConfigService) {
  }

  /** Services-URL fÃ¼r Backend */
  private getEndpoint(): string {
    return this.configService.config.communication.backend;
  }

  /** Service zum Download einer Formular-/Vorlagendatei
   * @param jwt
   * @param fileID
   * @param instData
   * @returns
   */
  downloadFormFile(jwt: string, fileID: number, instData: InstituteSendData): Observable<boolean> {
    const success = new Subject<boolean>();
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    const body = JSON.stringify(instData);  // Institutsdaten des angemeldeten Users
    this.http.put(this.getEndpoint() + 'form/user/getfile/' + fileID, body, {
      headers,
      responseType: 'blob',
      observe: 'response'
    }).subscribe(response => {
      success.next(this.saveToDisk(response, fileID));
    }, (e: HttpErrorResponse) => {
      this.errorHandler.hasError = true;
      this.errorHandler.errType = 'error';
      this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
      // tslint:disable-next-line
      this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>File-ID:' + fileID + '<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
      // console.log(e);
      success.next(false);
    });
    return success.asObservable();
  }

  /** Service zum Download einer allgemeinen (temporÃ¤ren) Datei (z.B. Dokumentationspaket)
   * @param jwt
   * @param fileID
   * @param serviceRoot
   * @returns
   */
  downloadFile(jwt: string, fileID: number, serviceRoot: string = 'file/company/getfile/'): Observable<boolean> {
    const success = new Subject<boolean>();
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    // console.log(this.getEndpoint() + serviceRoot + fileID);
    this.http.get(this.getEndpoint() + serviceRoot + fileID, {headers, responseType: 'blob', observe: 'response'}).subscribe(
      response => {
        // console.log('response');
        // console.log(response);
        success.next(this.saveToDisk(response, fileID));
      }, (e: HttpErrorResponse) => {
        this.errorHandler.hasError = true;
        this.errorHandler.errType = 'error';
        this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
        // tslint:disable-next-line
        this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>File-ID:' + fileID + '<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
        // console.log(e);
        success.next(false);
      });
    return success.asObservable();
  }

  downloadExcelFileAsync(jwt: string, endpoint: string): Observable<boolean> {
    const success = new Subject<boolean>();
    this.http.get(endpoint, {
      headers: {
        'authorization': `Bearer ${jwt}`,
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    }).subscribe(
      response => {
        success.next(true);
      }, (e: HttpErrorResponse) => {
        this.errorHandler.hasError = true;
        this.errorHandler.errType = 'error';
        this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
        // tslint:disable-next-line
        this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
        // console.log(e);
        success.next(false);
      });
    return success.asObservable();
  }

  /** Service zum Download einer Excel-Datei
   * @param jwt
   * @param fileID
   * @param serviceRoot
   * @returns
   */
  downloadExcelFile(jwt: string, endpoint: string, type: string): Observable<boolean> {
    const success = new Subject<boolean>();
    const fileName = type + '-' + formatDate(new Date(), 'yyyy-MM-dd-ss', 'de') + '.xlsx';
    this.http.get(endpoint, {
      headers: {
        'authorization': `Bearer ${jwt}`,
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }, responseType: 'blob', observe: 'response'
    }).subscribe(
      response => {
        success.next(this.saveExcelToDisk(response, fileName));
      }, (e: HttpErrorResponse) => {
        this.errorHandler.hasError = true;
        this.errorHandler.errType = 'error';
        this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
        // tslint:disable-next-line
        this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>File-Name:' + fileName + '<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
        // console.log(e);
        success.next(false);
      });
    return success.asObservable();
  }

  /** Service zum Download einer allgemeinen (temporÃ¤ren) Datei (z.B. Dokumentationspaket)
   * @param jwt
   * @param path
   * @returns
   */
  downloadFilePath(jwt: string, path: string): Observable<boolean> {
    const success = new Subject<boolean>();
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    // console.log(this.getEndpoint() + serviceRoot + fileID);
    this.http.get(this.getEndpoint() + path, {headers, responseType: 'blob', observe: 'response'}).subscribe(
      response => {
        // console.log('response');
        // console.log(response);
        success.next(this.saveToDisk(response, null));
      }, (e: HttpErrorResponse) => {
        this.errorHandler.hasError = true;
        this.errorHandler.errType = 'error';
        this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
        // tslint:disable-next-line
        this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>File-ID:' + path + '<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
        // console.log(e);
        success.next(false);
      });
    return success.asObservable();
  }

  /** Service zum Download einer als PDF gerÃ¤nderten XDP-Vorlage/XDP-Textbaustein
   * @param jwt
   * @param rendertyp
   * @param fileID
   * @param institute
   * @returns
   */
  renderFile(jwt: string, rendertyp: number, fileID: number, institute: InstituteSendData): Observable<boolean> {
    const success = new Subject<boolean>();
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    // console.log(this.getEndpoint() + 'form/company/render/' + rendertyp + '/' + fileID);
    // console.log('institute');
    // console.log(institute);
    // console.log('rendertyp');
    // console.log(rendertyp);
    this.http.put(this.getEndpoint() + 'form/company/render/' + rendertyp + '/' + fileID, institute, {
      headers,
      responseType: 'blob',
      observe: 'response'
    }).subscribe(response => {
      // console.log('response');
      // console.log(response);
      success.next(this.saveToDisk(response, fileID));
    }, (e: HttpErrorResponse) => {
      this.errorHandler.hasError = true;
      this.errorHandler.errType = 'error';
      this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
      // tslint:disable-next-line
      this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>File-ID:' + fileID + '<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
      // console.log(e);
      success.next(false);
    });
    return success.asObservable();
  }

  /** Support-Methode zum Speichern der Downloads
   * @param response
   * @param  fileID
   * @returns
   **/
  private saveToDisk(response: any, fileID: number): boolean {
    try {
      const contentDispositionHeader: string = response.headers.get('Content-Disposition');
      const parts: string[] = contentDispositionHeader.split(';');
      const filename = parts[1].split('=')[1].replace(/"/g, '');
      const fileBlob = response.body.slice(0);
      const blob = new Blob([fileBlob]);
      saveAs(blob, filename);
      return true;
    } catch (e) {
      this.errorHandler.hasError = true;
      this.errorHandler.errType = 'error';
      this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
      // tslint:disable-next-line
      this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>File-ID:' + fileID + '<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
      // console.log(e);
      return false;
    }

  }

  /** Support-Methode zum Speichern der Excel-Dateien
   * @param response
   * @param  fileName
   * @returns
   **/
  private saveExcelToDisk(response: any, fileName: string): boolean {
    try {
      const fileBlob = response.body.slice(0);
      const blob = new Blob([fileBlob]);
      saveAs(blob, fileName);
      return true;
    } catch (e) {
      this.errorHandler.hasError = true;
      this.errorHandler.errType = 'error';
      this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
      // tslint:disable-next-line
      this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>File-Name:' + fileName + '<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
      // console.log(e);
      return false;
    }

  }

}
