import { SelectItem } from 'primeng/api';

export class DsvsPrimengSelectHelper {

  static mapObjectsToSelectItems(objects: any[], labelProperty: string, withEmptyAtBeginning: boolean): SelectItem[] {
    const items: SelectItem[] = [];
    if (withEmptyAtBeginning) {
      items.push({label: '', value: null});
    }
    objects.forEach(obj => {
      if (obj !== undefined) {
        items.push(<SelectItem> {
          label: obj[labelProperty],
          value: obj
        });
      }
    });
    return items;
  }

}
