import {Injectable} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {WorkflowGeneratorConfig} from '../../config/workflow-generator-config';
import {WorkflowPermissions} from '../config/workflow-permissions.enum';

@Injectable()
export class WorkflowGeneratorConfigurationService {

  public config: WorkflowGeneratorConfig = <WorkflowGeneratorConfig>{
    api_url: '/workflowgenerator',
    ui_url: '/workflowgenerator'
  };

  // "Using"-System can set the permissions of the workflow-generator by overriding/implementing this method
  externalPermissions: (WorkflowPermissions) => boolean;

  constructor(/*@Inject('config') private config: WorkflowGeneratorConfig*/) {
    // console.log('####');
    // console.log(config);
  }

  getMenu(): MenuItem {
    const menuitems: MenuItem[] = [];

    if (this.hasPermission(WorkflowPermissions.CATEGORY_READ)) {
      menuitems.push({label: 'Kategorien', icon: 'local_activity', routerLink: [this.config.ui_url + '/categories']});
    }
    if (this.hasPermission(WorkflowPermissions.WORKFLOW_READ)) {
      menuitems.push({label: 'Workflows', icon: 'swap_calls', routerLink: [this.config.ui_url + '/workflows']});
    }
    if (this.hasPermission(WorkflowPermissions.CREW_READ)) {
      menuitems.push({label: 'Ansprechpartner', icon: 'group', routerLink: [this.config.ui_url + '/crews']});
    }
    if (this.hasPermission(WorkflowPermissions.GROUP_READ)) {
      menuitems.push({label: 'Gruppen', icon: 'group', routerLink: [this.config.ui_url + '/groups']});
    }
    if (this.hasPermission(WorkflowPermissions.ROLE_READ)) {
      menuitems.push({label: 'Roles', icon: 'people_outline', routerLink: [this.config.ui_url + '/roles']});
    }
    return {label: 'Workflowgenerator', icon: 'all_inclusive', items: menuitems};
  }

  hasPermission(permission: WorkflowPermissions): boolean {

    if (this.externalPermissions) {
      return this.externalPermissions(permission);
    } else {
      return true;
    }

  }

}
