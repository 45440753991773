import { Input } from '@angular/core';
import { ActionContextHalItem, ActionHalItem, ContextHalItem, StepContextHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowActionDataDto } from '../../interfaces/WorkflowActionData.dto';

export abstract class WorkflowActionComponent<ACTION extends ActionHalItem = ActionHalItem,
  CONTEXT extends ContextHalItem = ContextHalItem,
  STEP_CONTEXT extends StepContextHalItem = StepContextHalItem,
  ACTION_CONTEXT extends ActionContextHalItem = ActionContextHalItem> {

  @Input()
  action: ACTION;

  @Input()
  context: CONTEXT;

  @Input()
  stepContext: STEP_CONTEXT;

  @Input()
  actionContext: ACTION_CONTEXT;

  saveAction: () => void;

  public isProcessing = false;

  public abstract collectActionData(): WorkflowActionDataDto;

  abstract validate();

  abstract isValid(): boolean;

  abstract disableControls();

  abstract enableControls();

  abstract onSuccess(actionContext: ACTION_CONTEXT);

  abstract onError(error: any);

  abstract resetForm();

}
