import forOwn from 'lodash/forOwn';
import isArray from 'lodash/isArray';
import { VndError } from '../data';
import { HalLinkImpl } from './link.impl';
import { HalLink } from './link.interface';
import { VndErrorItem } from './vnd-error-item.interface';

export class VndErrorItemImpl implements VndErrorItem {
    protected _halLinks: any;
    protected _halEmbedded: any;
    protected links: Map<string, Array<HalLink>>;

    constructor(
        public readonly data: VndError
    ) {
        this.extractHalData();
    }

    public get name(): string {
        return 'VndError';
    }

    getError(): VndError {
        return this.data;
    }

    getAllLinks(): Map<string, Array<HalLink>> {
        return this.getHalLinks();
    }

    getLink(rel: string): HalLink {
        const relArray = this.getHalLinks()[rel];
        if (!relArray || relArray.length !== 1) {
            throw new Error('HalItemImpl#getLink: Link ' + rel + ' is not present');
        }
        return relArray[0];
    }

    getErrors(): Array<VndErrorItem> {
        const embeddedItems: any[] = this._halEmbedded ? this._halEmbedded['errors'] : [];
        const halItems: Array<VndErrorItem> = [];

        for (let i = 0; i < embeddedItems.length; i++) {
            halItems.push(new VndErrorItemImpl(embeddedItems[i]));
        }

        return halItems;
    }

    isErrorMessage(): boolean {
        return !!this.data.message;
    }

    hasSubErrors(): boolean {
        return !!this.data.total && this.data.total > 0;
    }

    getHelpLink(): HalLink {
        return this.getLink('help');
    }

    getDescribesLink(): HalLink {
        return this.getLink('describes');
    }

    getAboutLink(): HalLink {
        return this.getLink('about');
    }

    private extractHalData() {
        // links
        this._halLinks = (<any>this.data)._links;
        delete (<any>this.data)._links;

        // embedded
        this._halEmbedded = (<any>this.data)._embedded;
        delete (<any>this.data)._embedded;
    }

    private getHalLinks(): Map<string, Array<HalLink>> {
        if (!this.links) {
            const links: any = {};

            if (!this._halLinks) {
                throw new Error('no hal-links found, this should never happen!');
            }

            forOwn(this._halLinks, (val, key) => {
                const linkEntries = [];

                if (isArray(val)) {
                    val.forEach(valEntry => {
                        linkEntries.push(new HalLinkImpl(valEntry));
                    });
                } else {
                    linkEntries.push(new HalLinkImpl(val));
                }

                links[key] = linkEntries;
            });

            this.links = links;
        }

        return this.links;
    }
}
