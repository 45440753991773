import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolToYesNo'
})
export class DsvsBoolToYesNoPipe implements PipeTransform {

  /** Pipe zur Transformation true,false -> ja,nein fÃ¼r Tabellen
   * @param  value
   * @returns
   */
  transform(value: boolean): string {
    if (value) {
      return 'ja';
    } else {
      return 'nein';
    }
  }

}
