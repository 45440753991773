import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// TODO: Still needed?/Add Type

export interface HistoryFile {
  displayName: string;
  id: string;
  downloadText?: string;
}

@Component({
  selector: 'wfg-workflow-action-history-entry-file',
  template: `<div *ngFor="let file of files">

  <div class="download-item">
    <div>{{file.displayName}}</div>

    <p-button
      (onClick)="onDownloadSelected(file)"
      icon="fa ui-icon-file-download"
      [label]="file.downloadText ? file.downloadText : 'Download'"
    ></p-button>
  </div>

</div>
`,
  styles: [`.download-item{display:flex;flex-direction:row;align-items:center;justify-content:space-between;background-color:#fff;border-radius:4px;margin-top:12px;padding:8px}.download-item p-button{padding:4px}`]
})
export class WorkflowActionHistoryEntryFileComponent implements OnInit {

  @Input() files: HistoryFile[];

  @Output() downloadClicked: EventEmitter<HistoryFile> = new EventEmitter<HistoryFile>();

  constructor() {
  }

  ngOnInit() {
  }

  onDownloadSelected(file) {
    this.downloadClicked.emit(file);
  }

}
