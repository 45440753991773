import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DsvsBreadcrumbService, DsvsTableColumn, DsvsToolbarItem} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {GroupHalPage, RoleHalItem, WorkflowGroup} from '@dsvs/workflow-generator-dto';
import {MessageService} from 'primeng/components/common/messageservice';
import {WorkflowGeneratorConfigurationService} from '../../../../services/config/workflow-generator-configuration.service';
import {WorkflowPermissions} from '../../../../services/config/workflow-permissions.enum';
import {GroupDetailComponent} from '../group-detail/group-detail.component';
import {WorkflowGroupServiceImpl} from '../../../../services/data/workflow-group.service';

export enum GroupListActions {
  ADD = 'ADD'
}

@Component({
  selector: 'wfg-group-list',
  template: `<wfg-list
  [data]="data"
  [tableColumnOptions]="tableColumnOptions"
  (reloadData)="onReloadData($event)"
  (rowDetailClicked)="onRowDetailClicked($event)"
  (rowDeleteClicked)="onRowDeleteClicked($event)"
  (pageChanged)="reloadData('', $event)"
  (sortChanged)="reloadData('', $event)"
  (searchChanged)="reloadData($event.searchTerm, $event)"
  [isLoading]="isLoading"
  [isDeletable]="isDeletable"
  title="Gruppen"
  [toolbarItems]="initToolbarItems()"
></wfg-list>

<p-dialog
  [header]="editGroup ? 'Gruppe Ã¤ndern' : 'Gruppe erstellen'"
  [className]="'in-dialog-footer'"
  *ngIf="showEditModal"
  [(visible)]="showEditModal"
  width="1200"
  height="auto"
  [positionTop]="30"
  [modal]="true"
  [maximizable]="true"
  [dismissableMask]="true"
  [closable]="true"
>
  <wfg-group-detail [group]="editGroup" (showEditModalEvent)="receiveShowEditModal($event)"></wfg-group-detail>
</p-dialog>
`,
  styles: [`.col-label{display:flex;align-items:center}.label{font-weight:700}`]
})
export class GroupListComponent implements OnInit {

  @ViewChild('detailView') detailView: GroupDetailComponent;

  tableColumnOptions: DsvsTableColumn[] = [];

  isLoading = false;
  data: GroupHalPage = null;
  editGroup: WorkflowGroup;
  showEditModal = false;
  roles: RoleHalItem[] = [];
  searchTerm: string;
  isDeletable = false;

  constructor(private router: Router,
              private groupService: WorkflowGroupServiceImpl,
              private breadcrumbService: DsvsBreadcrumbService,
              private messageService: MessageService,
              private configService: WorkflowGeneratorConfigurationService) {
  }

  ngOnInit() {

    this.isDeletable = this.configService.hasPermission(WorkflowPermissions.GROUP_DELETE);

    this.reloadData('');

    this.tableColumnOptions = [
      {field: 'displayName', header: 'Name', sort: true, filter: '', hidden: false}
    ];

  }

  onReloadData($event: any) {
    this.searchTerm = $event.searchTerm;
    delete $event.searchTerm;

    this.reloadData(this.searchTerm, <Page>{
      size: $event.pagesize,
      number: $event.page
    });
  }

  reloadData(searchTerm: string, page?: Page) {
    this.isLoading = true;

    this.groupService
      .search(searchTerm, page)
      .subscribe(result => {
        this.data = result;
        this.isLoading = false;
      }, error2 => {
        console.error(error2);
      });
  }

  onRowDetailClicked(object) {
    this.editGroup = object;
    this.showEditModal = true;
  }

  onRowDeleteClicked(object) {
    this.groupService.delete(object.data).subscribe(() => {

      this.messageService.add({
        severity: 'success',
        summary: 'Erfolg',
        detail: '\"' + object.data.displayName + '\" wurde gelÃ¶scht'
      });
      this.reloadData(this.searchTerm);

    }, error2 => {
      console.error(error2);
      this.messageService.add(
        {
          severity: 'error',
          summary: 'Fehler',
          detail: error2.message
        });
    });
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;
    this.reloadData(this.searchTerm);
  }

  /********************************************
   * Toolbar
   *******************************************/

  /** Initialisierung der Toolbar items*/
  initToolbarItems(): DsvsToolbarItem[] {
    const tbItems: DsvsToolbarItem[] = [];

    if (this.configService.hasPermission(WorkflowPermissions.GROUP_CREATE)) {

      tbItems.push({
        id: GroupListActions.ADD,
        icon: 'add_box',
        disabled: false,
        tooltip: 'Gruppe hinzufÃ¼gen',
        faicon: true,
        callback: () => {
          this.editGroup = null;
          this.showEditModal = true;
        }
      });
    }

    return tbItems;
  }

}
