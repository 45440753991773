import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DsvsSharedUiLibConfigService } from '../dsvs-shared-ui-lib-config.service';
import { DsvsLandingPageModule } from '../landing-page/landing-page.module';
import { DsvsLoginViaApoComponent } from './components/login-via-apo/login-via-apo.component';
import { DsvsLogoutComponent } from './components/logout/logout.component';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { DsvsAuthorizationGuard } from './guards/authorization.guard';
import { DsvsAuthorizationService } from './services/authorization.service';
import { DsvsPermissionService } from './services/permission.service';

@NgModule({
  imports: [
    CommonModule,
    DsvsLandingPageModule,
    HttpClientModule
  ],
  declarations: [
    DsvsLoginViaApoComponent,
    DsvsLogoutComponent,
    HasPermissionDirective
  ],
  exports: [
    DsvsLoginViaApoComponent,
    DsvsLogoutComponent,
    HasPermissionDirective
  ],
  providers: [
    DsvsSharedUiLibConfigService,
    DsvsAuthorizationService,
    DsvsAuthorizationGuard,
    DsvsPermissionService
  ]
})
export class DsvsAuthenticationModule {
}
