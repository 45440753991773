import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { RightsHalItem, RightsHalPage, WorkflowRights } from '@dsvs/workflow-generator-dto';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowRightService extends DsvsSearchableCrudEntityService<WorkflowRights> {

}

// @dynamic
@Injectable()
export class WorkflowRightServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowRights, RightsHalItem, RightsHalPage>
  implements WorkflowRightService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'right', collection: 'rights'},
      WorkflowGeneratorTypeMappingService.WorkflowRight,
      typeResolver
    );
  }

}

@Injectable()
export class WorkflowRightServiceMock
  extends DsvsSearchableCrudEntityServiceMock<WorkflowRights>
  implements WorkflowRightService {

  constructor() {
    super();

    this.data = [];

    for (let i = 1; i <= 100; i++) {
      this.data.push(
        <WorkflowRights>{
          id: String(i),
          displayName: 'Workflow Right ' + i,
          required: false,
          resetOnStepEntry: false,
          requiredRights: [],
          requiredVisibility: []
        }
      );
    }

  }

}
