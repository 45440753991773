import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { WorkflowCategory } from '@dsvs/workflow-generator-dto';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { WorkflowCategoryServiceImpl } from '../services/data/workflow-category.service';

@Injectable()
export class WorkflowCategoryResolver implements Resolve<WorkflowCategory> {

  constructor(private categoryService: WorkflowCategoryServiceImpl) {

  }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<WorkflowCategory> | Promise<WorkflowCategory> | WorkflowCategory {
    const id = route.params.id;
    if (id) {
      return this.categoryService.getById(id).map(result => {
        return result.data;
      });
    }

    return Observable.of(null);
  }
}
