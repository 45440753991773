import { Inject, Injectable } from '@angular/core';
import { DsvsItemResult, DsvsPage, DsvsPagedResult, DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { StepContextHalItem, StepContextHalPage, WorkflowStepContext } from '@dsvs/workflow-generator-dto';
import { Observable } from 'rxjs';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowTypeMappingService } from '../config/workflow-type-mapping.service';

export interface WorkflowStepContextService extends DsvsSearchableCrudEntityService<WorkflowStepContext> {

}

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class WorkflowStepContextServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowStepContext, StepContextHalItem, StepContextHalPage>
  implements WorkflowStepContextService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'stepcontext', collection: 'stepcontexts'},
      WorkflowTypeMappingService.WorkflowStepContext,
      typeResolver
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class WorkflowStepContextServiceMock implements WorkflowStepContextService {

  constructor() {

  }

  create(role: WorkflowStepContext): Observable<DsvsItemResult<WorkflowStepContext>> {
    return undefined;
  }

  delete(role: WorkflowStepContext): Observable<never> {
    return undefined;
  }

  getById(id: string): Observable<DsvsItemResult<WorkflowStepContext>> {
    return undefined;
  }

  search(searchTerm: string, options?: DsvsPage): Observable<DsvsPagedResult<WorkflowStepContext>> {
    return undefined;
  }

  update(role: WorkflowStepContext): Observable<DsvsItemResult<WorkflowStepContext>> {
    return undefined;
  }

}
