export class DtoHelper {

  static getRole(): any {
    return {
      data: {
        id: '',
        displayName: 'Neue Rolle',
        description: ''
      }
    };
  }

  static getStepRole(): any {
    return {
      data: {
        id: '',
        displayName: 'Neue Rolle',
        description: '',
        stepId: null
      }
    };
  }

  static getCategory(): any {
    return {
      id: '',
      displayName: 'Neue Kategorie',
      crewId: null
    };
  }

  static getWorkflow(): any {
    return {
      data: {
        id: '',
        displayName: 'Neuer Workflow',
        categoryId: null,
        productId: null,
        steps: []
      }
    };
  }

  static getCrew(): any {
    return {
      data: {
        id: '',
        displayName: 'Neue Crew'
      }
    };
  }

  static getGroup(): any {
    return {
      data: {
        id: '',
        displayName: 'Neue Gruppe'
      }
    };
  }

}
