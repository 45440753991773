import {Component, Input} from '@angular/core';

@Component({
  selector: 'dsvs-user-badge',
  template: `<div class="dsvs-user-badge" *ngIf="tag != null">
    <span class="user-badge-circle">
      {{getInitials(tag)}}
    </span>
  <span class="user-badge-name">
      {{tag}}
    </span>
</div>
`,
  styles: [`.dsvs-tag{color:#f9fbff;padding:2px 4px}.ui-chips-token{border-radius:2px;background:#4f70b6;color:#f9fbff;font-size:12pt;padding:4px 12px;margin-bottom:8px;margin-right:8px}.ui-chips-token .ui-chips-token-icon{top:16px}.ui-chips-token .ui-chips-token-label{margin-right:16px;margin-bottom:1px}.dsvs-user-badge{height:40px;padding:5px 5px 10px 0;display:flex;align-items:center;margin-bottom:10px;width:220px}.dsvs-user-badge .user-badge-circle{background:#85a0d9;border-radius:50%;color:#f9fbff;font-size:10pt;height:30px;width:30px;display:inline-flex;align-items:center;justify-content:center}.dsvs-user-badge .user-badge-name{font-size:12pt;color:#16367d;margin-left:4px}`]
})
export class UserBadgeComponent {

  _color = '#4f70b6';

  @Input() tag: string = null;

  // TODO: Replace with colors from SASS
  @Input() set color(color: string) {
    if (color) {
      if (color.startsWith('#')) {
        this._color = color;
      } else {
        switch (color.toLowerCase()) {
          case 'active' :
            this._color = '#7ED321';
            break;
          case 'inactive' :
            this._color = '#FC3A28';
            break;
          case 'new' :
            this._color = '#42A5F5';
            break;
          case 'uploaded' :
            this._color = '#F3E401';
            break;
          case 'released' :
            this._color = '#7ED321';
            break;
          case 'canceled' :
            this._color = '#FC3A28';
            break;
          default:
            this._color = '#42A5F5';
            break;
        }
      }
    }
  }

  get color(): string {
    return this._color;
  }

  getInitials(name: string): string {
    const n = name.split(' ');
// Split the full name into an array
    let it = '';
// Create and set the intials variable to nothing
    let i = 0;

    // Set i to 0 for the while loop
    while (i < n.length) {
      it += n[i][0];	// Get the first letter from the name and add it to the it(initial) variable
      i++;			// Increment i by 1
    }
    if (it[0] !== '+') {
      return (it.toUpperCase());	// Convert the initials to uppercase and then return it
    } else {
      return '+';
    }
  }
}
