import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { DsvsAuthorizationService } from '../../authentication/services/authorization.service';
import { DsvsSharedUiLibConfigService } from '../../dsvs-shared-ui-lib-config.service';
import { DsvsAppSettings } from '../interfaces/app-settings';
import { DsvsRequestResult } from '../interfaces/request-result';
import { DsvsUserSettings } from '../interfaces/user-settings';

@Injectable()
export class DsvsSettingsService {

  private scope: string;
  private zapUrl: string;
  private zapInit: string;

  constructor(private http: HttpClient,
              private authService: DsvsAuthorizationService,
              private configService: DsvsSharedUiLibConfigService) {
    this.scope = this.configService.config.communication.scope;
    this.zapUrl = this.configService.config.communication.zapurl;
    this.zapInit = this.configService.config.communication.zapinit;
  }

  /** Service zur Abfrage der globalen Anwendungseinstellungen (Settings)
   * @returns
   */
  getAppSettings(): Observable<DsvsAppSettings> {
    return this.http.get<DsvsAppSettings>(this.zapUrl + 'getappsettings/fes/' + this.scope,
      {headers: this.getHeadersWithBasicInit()});
  }

  /** Service zur Abfrage der spezifischen Benutzereinstellungen (Settings)
   * @returns
   */
  getUserSettings(): Observable<DsvsUserSettings[]> {
    return this.http.get<DsvsUserSettings[]>(this.zapUrl + 'getusersettings/fes/' + this.authService.getUserData().result.id,
      {headers: this.getHeadersWithBearerJWT()});
  }

  /** Update-Service fÃ¼r die Benutzereinstellungen
   * @param  settings
   * @returns
   */
  updateUserSettings(settings: DsvsUserSettings[]): Observable<DsvsRequestResult> {
    return this.http.put<DsvsRequestResult>(this.zapUrl + 'updateusersettings/fes/' + this.authService.getUserData().result.id,
      settings, {headers: this.getHeadersWithBearerJWT()});
  }

  private getHeadersWithBasicInit() {
    const authKey = btoa(this.scope + ':' + this.configService.config.communication.zapinit);
    return {
      'authorization': `Basic ${authKey}`,
      'content-type': 'application/json; charset=utf-8'
    };
  }

  private getHeadersWithBearerJWT() {
    return {
      'authorization': `Bearer ${this.authService.getJWT()}`,
      'content-type': 'application/json; charset=utf-8'
    };
  }
}
