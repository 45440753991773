import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DsvsSharedUiLibConfigService } from '../../../dsvs-shared-ui-lib-config.service';

@Component({
  selector: 'dsvs-landing-page',
  template: `<!DOCTYPE html>
<html>

<head>
  <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
  <meta name="apple-mobile-web-app-capable" content="yes"/>
  <title>Die Formularfabrik logout</title>
</head>

<body class="landing-body">
<div class="landing-wrapper">
  <div id="header">
    <div>
      <img [src]="appBranding" class="logo-colored" style="margin-left: 0 !important;"/>
      <a id="landing-menu-button" href="#">
        <i class="material-icons">menu</i>
      </a>

      <!--
      <ul id="landing-menu">
        <li>
          <a href="#services">SERVICES</a>
        </li>
        <li>
          <a href="#kontakt">KONTAKT</a>
        </li>
      </ul>
      -->
    </div>
  </div>


  <div id="introduction">
    <div class="content">
      <div class="ui-g login-logout ui-g-nopad">
        <div class="ui-lg-4 ui-md-6 ui-sm-12 image-box ui-g-nopad">
          <div class="card card-nopad">
            <div class="app-logo">
              <img alt="logo-colored" [src]="appLogo" class="logo"/>
            </div>
            <div class="logout-msg">
              <span class="text-red" style="font-size: 1.4em">{{ infoTitle }}</span>
            </div>
            <div class="logout-info">
              <p [innerHTML]="infoMessage | safeHtml"></p>
            </div>
          </div>
        </div>
        <div class="ui-lg-4 ui-md-8 ui-sm-12" style="padding-top: 2%; margin-left: 3%">
          <img src="assets/lib-dsvs/images/back_text.png">
        </div>
      </div>
    </div>
  </div>

  <div id="features">
    <div>
      <div class="feature-title">
        <span>Wir sind fÃ¼r Sie da</span>
      </div>

      <div class="ui-g">
        <div class="ui-g-12 ui-md-4 feature-box">
          <div>
            <h2>Kontakt</h2>

            <p style="margin: 8px 0 0;">
              S-Management Services GmbH<br>
              Am Wallgraben 115<br>
              70565 Stuttgart<br>
              Deutschland<br>
            </p>
            <table class="table-no-border">
              <tbody>
              <tr>
                <td style="padding-left: 0">Telefon</td>
                <td style="padding-left: 0">+49 711 782 129-00</td>
              </tr>
              <tr>
                <td style="padding-left: 0">E-Mail</td>
                <td style="padding-left: 0">info@s-management-services.de</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="ui-g-12 ui-md-4 feature-box">
          <div>
            <h2>Support</h2>
<!--            <p style="margin: 8px 0 0;">-->
<!--              Die technische Hotline fÃ¼r<br>-->
<!--              Formularkunden:<br>-->
<!--              Montag bis Donnerstag 9:00-17:00 Uhr<br>-->
<!--              Freitag 9:00-15:00 Uhr-->
<!--            </p>-->
<!--            <div class="frame div-h-center mt-5">-->
<!--              <i class="material-icons">phone_in_talk</i>-->
<!--              <span style="margin-left: 0.5em;">0711 782 129-44</span>-->
<!--            </div>-->
            <div class="frame div-h-center mt-5">
              <i class="material-icons">email</i>
              <span style="margin-left: 0.5em;">{{ appSupportMail }}</span>
            </div>
          </div>
        </div>

        <div class="ui-g-12 ui-md-4 feature-box">
          <div>
            <h2>Karriere</h2>
            <p style="margin: 8px 0 0;">
              Personalmanagement<br>
              Deutscher Sparkassen Verlag GmbH<br>
              Am Wallgraben 115<br>
              70565 Stuttgart<br>
              E-Mail: personal@dsv-gruppe.de
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="footer">
    <div class="div-h-center">
      <span>Â© S-Management Services GmbH. Alle Rechte vorbehalten.<br>
VervielfÃ¤ltigung nur mit Genehmigung der S-Management Services GmbH.</span>
      <div class="footer-link">
        <a href="https://www.s-management-services.de/agb.html" target="_blank" rel=ânoopenerâ>AGB</a>
        <a href="https://www.s-management-services.de/datenschutz/" target="_blank" rel=ânoopenerâ>Datenschutz</a>
        <a href="https://www.s-management-services.de/impressum/" target="_blank" rel=ânoopenerâ>Impressum</a>
      </div>
    </div>
  </div>

</div>
</body>
</html>
`,
  styles: [`.ui-button-text{color:#fff}h2,h3,h4{color:#e22222}.logo-colored{margin-top:5px}.landing-body .card{padding:0!important}.landing-body .simple-table th,.landing-body td{border:none;padding:4px}`]
})
export class DsvsLandingPageComponent implements OnInit {

  @Input() subMessage: string;

  /* Spezielle Ressourcen fÃ¼r die jeweilige App */
  appBranding: string;
  appLogo: string;
  infoTitle: string;
  infoMessage: string;
  appSupportMail: string;
  private urlFFB: string;

  constructor(private route: ActivatedRoute, private config: DsvsSharedUiLibConfigService) {
  }

  ngOnInit() {

    this.appBranding = this.config.config.layout.topbar.logoUrl; // '../../../../assets/images/logo_ffb_fms.svg';
    this.appLogo = this.config.config.layout.appLogo; // '../../../../assets/images/logo_fms.svg';
    this.appSupportMail = 'support@die-formularfabrik.de';
    this.infoTitle = 'Sie haben sich erfolgreich abgemeldet.';
    this.urlFFB = '<a href="https://www.die-formularfabrik.de" target="_blank" rel="noopener noreferrer">Formularfabrik</a>';
    this.infoMessage = 'Alle fÃ¼r Sie freigeschalteten Apps erreichen Sie wie gewohnt Ã¼ber das AnwendungsmenÃ¼ der ' + this.urlFFB + '.';


    this.route.params.subscribe(params => {
      switch (params.mode) {
        case 'nosso':
          this.infoTitle = 'Anmeldung ist nur mit SSO-SchlÃ¼ssel mÃ¶glich.';
          this.infoMessage = 'Sie mÃ¼ssen ' + this.config.config.layout.appName + ' Ã¼ber das AnwendungsmenÃ¼ unserer ' + this.urlFFB + ' starten.';
          break;
        case 'ssoerr':
          this.infoTitle = 'Die Anmeldung am ' + this.config.config.layout.appName + ' ist fehlgeschlagen.';
          this.infoMessage = 'Der Start von ' + this.config.config.layout.appName + ' Ã¼ber das AnwendungsmenÃ¼ unserer '
            + this.urlFFB + ' ist fehlgeschlagen. Bitte wenden Sie sich an den Support.';
          break;
      }
    });

  }

}
