import { ComponentFactoryResolver, Input, ViewChild } from '@angular/core';
import { WorkflowActionExtension } from '../interfaces/WorkflowActionExtension';
import { ActionHostDirective } from './action-host.directive';

export abstract class ViewInjectableComponent<T> {

  @ViewChild(ActionHostDirective) wfgActionHost: ActionHostDirective;

  protected inputInstance: T;

  private _extension: WorkflowActionExtension;

  get extension(): WorkflowActionExtension {
    return this._extension;
  }

  @Input('extension')
  set extension(value: WorkflowActionExtension) {
    this._extension = value;
    if (this._extension) {
      this.inputInstance = this.createInputInstance(this.viewOfExtension(this._extension));
      this.assignValuesToComponent(this.inputInstance);
    }
  }

  protected constructor(
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {
  }

  protected abstract viewOfExtension(extension: WorkflowActionExtension): any;

  protected assignValuesToComponent(instance: T) {
  }

  private createInputInstance(componentType): T {

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<T>(componentType);

    const viewContainerRef = this.wfgActionHost.viewContainerRef;

    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);

    return <T>componentRef.instance;
  }
}
