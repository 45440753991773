import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'dsvs-file-download',
  template: `<div *ngIf="halItem && halItem.links; let links" style="flex-direction: row; display: flex; flex-wrap: wrap;">
  <ng-container *ngFor="let file of links.files">
    <ng-container *ngIf="file.linkData; let link">

      <button
        pButton
        style="margin-right: 3px"
        icon="fa ui-icon-file-download"
        (click)="onDownloadPathClicked(link.href)"
        [label]="!useCustomLabel ? (title ? shorten(title) : (link.title ? shorten(link.title) : 'Herunterladen')) : ' '"
        [pTooltip]="title ? title : (link.title ? link.title : 'Herunterladen')" tooltipPosition="top"
        [tooltipDisabled]="title ? !(title.length >= maxFileTextLength) :
          (link.title ? !(link.title.length >= maxFileTextLength) : true)"
      >
        <div class="custom-label" *ngIf="useCustomLabel && customLabel">
          <div *ngFor="let label of customLabel">{{label}}</div>
        </div>
      </button>

      <ng-container *ngIf="deletable">
        <button
          pButton
          class="remove-button"
          icon="fa ui-icon-clear"
          (click)="deleteClick(file)"
        >
        </button>
      </ng-container>

    </ng-container>
  </ng-container>
</div>
`,
  styles: [`.custom-label{display:flex;flex-direction:column;text-align:left;margin:8px}.custom-label div:first-child{font-weight:700;margin-bottom:4px}button{height:auto}.flex-row-between{display:flex;flex-direction:row;justify-content:space-between;align-items:center}:host ::ng-deep .remove-button{background:0 0!important;width:10px!important;margin-right:10px}:host ::ng-deep .remove-button:hover{background:0 0!important}:host ::ng-deep .remove-button:focus{background:0 0!important}:host ::ng-deep .remove-button .fa{color:#e22122!important}`]
})
export class FileDownloadComponent implements OnInit {

  // TODO: Add halItem as Type
  @Input()
  halItem: any;

  @Input()
  title: string;

  @Input()
  maxFileTextLength = 25;

  @Input()
  customLabel: string[];

  @Input()
  useCustomLabel: boolean;

  @Input()
  deletable = false;

  @Output()
  download = new EventEmitter<string>();

  @Output()
  deleteFile = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {

  }

  onDownloadPathClicked(path: string) {
    this.download.emit(path);
  }

  deleteClick(download) {
    this.deleteFile.emit(download);
  }

  shorten(val: string) {
    if (val.length >= this.maxFileTextLength) {
      return val.substr(0, this.maxFileTextLength) + '...';
    } else {
      return val;
    }
  }

}
