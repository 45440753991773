import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Workflow, WorkflowHalItem, WorkflowHalPage } from '@dsvs/workflow-generator-dto';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowService extends DsvsSearchableCrudEntityService<Workflow> {

}

// @dynamic
@Injectable()
export class WorkflowServiceImpl
  extends MappedHalCrudServiceImpl<Workflow, WorkflowHalItem, WorkflowHalPage>
  implements WorkflowService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'workflow', collection: 'workflows'},
      WorkflowGeneratorTypeMappingService.Workflow,
      typeResolver
    );
  }
}

@Injectable()
export class WorkflowServiceMock extends DsvsSearchableCrudEntityServiceMock<Workflow> implements WorkflowService {
  constructor() {
    super();

    this.data = [];

    for (let i = 1; i <= 100; i++) {
      this.data.push(
        <Workflow>{
          id: String(i),
          displayName: 'Workflow ' + i
        }
      );
    }
  }
}
