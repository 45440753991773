import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient, Page } from '@dsvs/hal-client';
import { CategoryHalItem, CategoryHalPage, WorkflowCategory } from '@dsvs/workflow-generator-dto';
import { Observable } from 'rxjs/Observable';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowCategoryService extends DsvsSearchableCrudEntityService<WorkflowCategory> {

  searchProcessSelectable(searchTerm: string, options?: Page): Observable<CategoryHalPage>;

}

// @dynamic
@Injectable()
export class WorkflowCategoryServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowCategory, CategoryHalItem, CategoryHalPage>
  implements WorkflowCategoryService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'category', collection: 'categories'},
      WorkflowGeneratorTypeMappingService.WorkflowCategory,
      typeResolver
    );
  }

  searchProcessSelectable(searchTerm: string, options?: Page): Observable<CategoryHalPage> {
    return super.search(searchTerm, options, {name: 'processable'});
  }

}

@Injectable()
export class WorkflowCategoryServiceMock
  extends DsvsSearchableCrudEntityServiceMock<WorkflowCategory>
  implements WorkflowCategoryService {

  constructor() {
    super();

    this.data = [];

    for (let i = 1; i <= 100; i++) {
      this.data.push(
        <WorkflowCategory>{
          id: String(i),
          displayName: 'Category ' + i,
          crewId: null
        }
      );
    }

  }

  searchProcessSelectable(searchTerm: string, options?: Page): Observable<CategoryHalPage> {
    return null;
  }

}
