import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DsvsBreadcrumbService, DsvsItemResult } from '@dsvs/dsvs-shared-ui-lib';
import { WorkflowRole } from '@dsvs/workflow-generator-dto';
import { isNil } from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { DtoHelper } from '../../../../classes/dto-helper';
import { WorkflowGeneratorConfigurationService } from '../../../../services/config/workflow-generator-configuration.service';
import { WorkflowGeneratorRoutingService } from '../../../../services/config/workflow-generator-routing.service';
import { WorkflowPermissions } from '../../../../services/config/workflow-permissions.enum';
import { WorkflowRoleServiceImpl } from '../../../../services/data/workflow-role.service';

export enum RoleDetailActions {
  REFRESH = 'REFRESH', ADD = 'ADD', DELETE = 'DELETE', EDIT = 'EDIT'
}

export enum RoleDetailState {
  DETAIL = 'DETAIL', EDIT = 'EDIT', NEW = 'NEW'
}

@Component({
  selector: 'wfg-role-detail',
  template: `<p-confirmDialog header="Confirmation" icon="" width="425" acceptLabel="Ja" rejectLabel="Nein"
                 responsive="true"></p-confirmDialog>
<div class="role-detail-container" *ngIf="role && role.data">
  <div class="first-row">
    <div class="name-container ui-lg-6">
      <dsvs-inline-edit
        label="Name"
        id="name"
        [isInline]="isInline"
        type="text"
        [(value)]="role.data.displayName"
        [disabled]="!isEditable"
      >
      </dsvs-inline-edit>
    </div>

    <div class="name-container ui-lg-6">
      <dsvs-inline-edit
        label="Key"
        id="identifier"
        [isInline]="isInline"
        type="text"
        [(value)]="role.data.identifier"
        [disabled]="!isEditable"
      >
      </dsvs-inline-edit>
    </div>
  </div>

  <div class="name-container ui-lg-12">
    <dsvs-inline-edit
      label="Beschreibung"
      id="description"
      [isInline]="isInline"
      type="textarea"
      [(value)]="role.data.description"
      [disabled]="!isEditable"
    >
    </dsvs-inline-edit>
  </div>

</div>

<p-footer style="display: block; width: 100%; padding-top: 40px">
  <div class="footer-buttons" style="display: flex; justify-content: flex-end">
    <button
      (click)="cancelModal()"
      pButton
      type="button"
      class="ui-button-text dsvs-btn ui-corner-all"
      label="Schliessen"
      icon="fa ui-icon-close">
    </button>
    <button
      (click)="addWorkflowRole()"
      pButton
      type="button"
      class="ui-button-text dsvs-btn ui-corner-all"
      label="Speichern"
      icon="fa ui-icon-save"
      *ngIf="isEditable">
    </button>
  </div>
</p-footer>
`,
  styles: [`.role-detail-container{padding:1em}.role-detail-container .first-row{display:flex}`]
})
export class RoleDetailComponent implements OnInit {

  @Input() role: any;
  @Input() isInline = false;
  @Input() showSaveButtonGroup = false;

  @Output() showEditModalEvent = new EventEmitter<boolean>();

  public RoleDetailState = RoleDetailState;
  public viewState: RoleDetailState = RoleDetailState.NEW;

  public roles: DsvsItemResult<WorkflowRole>[] = [];
  isEditable = false;

  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;

  constructor(private roleService: WorkflowRoleServiceImpl,
    private route: ActivatedRoute,
    private wfgRouting: WorkflowGeneratorRoutingService,
    private breadcrumbService: DsvsBreadcrumbService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private configService: WorkflowGeneratorConfigurationService) {
  }

  ngOnInit() {

    this.roleService.search('').subscribe(result => {
      this.roles = result.content;
    });

    // role object initialisieren
    if (isNil(this.role)) {
      this.viewState = RoleDetailState.NEW;
      this.role = DtoHelper.getRole();
      this.isInline = false;
    } else {
      this.viewState = RoleDetailState.DETAIL;
      this.isInline = true;
    }

    this.isEditable = this.viewState === RoleDetailState.NEW || this.configService.hasPermission(WorkflowPermissions.ROLE_UPDATE);

  }

  addWorkflowRole() {

    if (this.role.data.id === '') {
      this.roleService.create(this.role.data).subscribe(result => {
        this.cancelModal();
      }, error => {
        console.error('Error creating role - {}', error);
        this.messageService.add({
          severity: 'error',
          summary: error.data.logref,
          detail: error.data.message
        });
        // this.cancelModal();
      });
    } else {
      this.roleService.update(this.role.data).subscribe(result => {
        this.cancelModal();
      }, error => {
        console.error('Error updating role - {}', error);
        this.messageService.add({
          severity: 'error',
          summary: error.data.logref,
          detail: error.data.message
        });
      });
    }
  }

  /** Beim klick auf den LÃ¶schen Button wird zunÃ¤chst der Confirmation Service ausgefÃ¼hrt*/
  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Wollen Sie die Rolle "' + this.role.displayName + '" lÃ¶schen?',
      header: 'BestÃ¤tigung',
      icon: '',

      accept: () => {
        this.roleService.delete(this.role);
        this.wfgRouting.navigateToRoles();
      }
    });
  }

  onInputChange() {
    this.breadcrumbService.setToolbarItemDisabled(RoleDetailActions.ADD, false);
  }

  cancelModal() {
    this.showEditModalEvent.emit(false);
  }

}
