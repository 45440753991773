import { Component, Input } from '@angular/core';

@Component({
  selector: 'dsvs-hal-async-loader',
  template: `<div *ngIf="halObj && halObj._halLinks && halObj._halLinks[linkName]">
  <div *ngIf="halObj[linkName] && !halObj[linkName].sync._loading; else loading">
    <span *ngIf="isArray; else noArray">
      <span *ngFor="let it of halObj[linkName]._data.content">

        <span *ngIf="childLinkName && it._halLinks[childLinkName]; else noChild">

            <span *ngIf="!it[childLinkName].sync._loading; else loading">
              {{resolvePath(it[childLinkName]._data.data, displayProperty)}}
            </span>

        </span>

        <ng-template #noChild>
          {{resolvePath(it.data, displayProperty)}}
        </ng-template>

        </span>
    </span>

    <ng-template #noArray>

      <span *ngIf="childLinkName && halObj[linkName]._data._halLinks[childLinkName]; else noChild">
        <span *ngIf="!halObj[linkName]._data[childLinkName].sync._loading; else loading">
          {{resolvePath(halObj[linkName]._data[childLinkName]._data.data, displayProperty)}}
        </span>
      </span>

      <ng-template #noChild>
        {{resolvePath(halObj[linkName]._data.data, displayProperty)}}
      </ng-template>

    </ng-template>

  </div>
  <ng-template #loading>
    <ngx-content-loading
      [speed]="'1500ms'"
      [width]="100"
      [height]="5"
      [primaryColor]="'#F9FBFF'"
      preserveAspectRatio="none"
      [secondaryColor]="'#CDD9F2'">
      <svg:g ngx-rect width="50" height="5" viewbox="0 0 100 15" y="0" x="0" rx="1" ry="1"></svg:g>
    </ngx-content-loading>
  </ng-template>
</div>
`,
  styles: [``]
})
export class HalAsyncLoaderComponent {

  @Input() halObj: any;
  @Input() linkName: string;
  @Input() childLinkName: string;
  @Input() displayProperty: string;
  @Input() isArray: boolean;

  log(obj) {
    // console.log(obj);
  }

  resolvePath = (object, path, defaultValue = 'data') => path
  .split('.')
  .reduce((o, p) => o ? o[p] : defaultValue, object);
}
