import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { DsvsSharedUiLibConfigService } from '../../dsvs-shared-ui-lib-config.service';

@Injectable()
export class DsvsTrackingHeaderInterceptor implements HttpInterceptor {

  constructor(private configService: DsvsSharedUiLibConfigService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    // X-User-Id is never used in front facing apis and is always extracted from the Bearer-Token set by DsvsAuthHeaderInterceptor
    const headers = req.headers
      .set(
        'X-Trace-Id',
        `${this.configService.config.communication.loggerSystemId}-${uuid()}`
      );

    return next.handle(req.clone({
      headers: headers
    }));
  }
}
