import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {WorkflowGroup} from '@dsvs/workflow-generator-dto';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import {WorkflowGroupServiceImpl} from '../services/data/workflow-group.service';

@Injectable()
export class WorkflowGroupResolver implements Resolve<WorkflowGroup> {

  constructor(private crewService: WorkflowGroupServiceImpl) {

  }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<WorkflowGroup> | Promise<WorkflowGroup> | WorkflowGroup {
    const id = route.params.id;
    if (id) {
      return this.crewService.getById(id).map(result => {
        return result.data;
      });
    }

    return Observable.of(null);
  }
}
