export enum WorkflowPermissions {

  CATEGORY_CREATE = 'CATEGORY_CREATE',
  CATEGORY_READ = 'CATEGORY_READ',
  CATEGORY_UPDATE = 'CATEGORY_UPDATE',
  CATEGORY_DELETE = 'CATEGORY_DELETE',

  WORKFLOW_CREATE = 'WORKFLOW_CREATE',
  WORKFLOW_READ = 'WORKFLOW_READ',
  WORKFLOW_UPDATE = 'WORKFLOW_UPDATE',
  WORKFLOW_DELETE = 'WORKFLOW_DELETE',

  CREW_CREATE = 'CREW_CREATE',
  CREW_READ = 'CREW_READ',
  CREW_UPDATE = 'CREW_UPDATE',
  CREW_DELETE = 'CREW_DELETE',

  GROUP_OVERVIEW_READ = 'GROUP_OVERVIEW_READ',
  GROUP_CREATE = 'GROUP_CREATE',
  GROUP_READ = 'GROUP_READ',
  GROUP_UPDATE = 'GROUP_UPDATE',
  GROUP_DELETE = 'GROUP_DELETE',

  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_READ = 'ROLE_READ',
  ROLE_UPDATE = 'ROLE_UPDATE',
  ROLE_DELETE = 'ROLE_DELETE',

}
