import {Component, OnInit, ViewChild} from '@angular/core';
import {DsvsBreadcrumbService, DsvsTableColumn, DsvsToolbarItem} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {CategoryHalPage, WorkflowCategory} from '@dsvs/workflow-generator-dto';
import {MessageService} from 'primeng/components/common/messageservice';
import {WorkflowGeneratorConfigurationService} from '../../../../services/config/workflow-generator-configuration.service';
import {WorkflowPermissions} from '../../../../services/config/workflow-permissions.enum';
import {WorkflowCategoryServiceImpl} from '../../../../services/data/workflow-category.service';
import {CategoryDetailComponent} from '../category-detail/category-detail.component';

export enum CategoryListActions {
  ADD = 'ADD'
}

@Component({
  selector: 'wfg-category-list',
  template: `<wfg-list
  [data]="data"
  [tableColumnOptions]="tableColumnOptions"
  (reloadData)="onReloadData($event)"
  (rowDetailClicked)="onRowDetailClicked($event)"
  (rowDeleteClicked)="onRowDeleteClicked($event)"
  (pageChanged)="onReloadData('', $event)"
  (sortChanged)="onReloadData('', $event)"
  (searchChanged)="onReloadData($event.searchTerm, $event)"
  [isLoading]="isLoading"
  [isDeletable]="isDeletable"
  title="Kategorien"
  [toolbarItems]="initToolbarItems()"
>
</wfg-list>

<p-dialog
  [header]="editCategory ? 'Kategorie Ã¤ndern' : 'Kategorie erstellen'"
  [className]="'in-dialog-footer'"
  *ngIf="showEditModal"
  [(visible)]="showEditModal"
  width="800"
  height="auto"
  [modal]="true"
  [maximizable]="true"
  [dismissableMask]="true"
  [closable]="true"
>
  <wfg-category-detail
    [categoryHalItem]="editCategory"
    (showEditModalEvent)="receiveShowEditModal($event)"
    #detailView
  >
  </wfg-category-detail>
</p-dialog>

`,
  styles: [`.col-label{display:flex;align-items:center}`]
})
export class CategoryListComponent implements OnInit {

  @ViewChild('detailView') detailView: CategoryDetailComponent;

  isLoading = false;
  isDeletable = false;

  tableColumnOptions: DsvsTableColumn[] = [];
  editCategory: WorkflowCategory;
  showEditModal = false;
  data: CategoryHalPage = null;
  searchTerm = '';

  constructor(private categoryService: WorkflowCategoryServiceImpl,
              private breadcrumbService: DsvsBreadcrumbService,
              private messageService: MessageService,
              private configService: WorkflowGeneratorConfigurationService) {
  }

  ngOnInit() {

    this.isDeletable = this.configService.hasPermission(WorkflowPermissions.CATEGORY_DELETE);

    this.onReloadData(this.searchTerm);
    this.tableColumnOptions = [
      {field: 'displayName', header: 'Name', sort: true, filter: '', hidden: false, width: '65%'},
      {field: 'crew', header: 'Crew', sort: false, filter: '', hidden: false, width: '30%', format: 'CREW'}
    ];
  }

  onReloadData(searchTerm: string, page?: Page) {
    this.isLoading = true;
    if (searchTerm !== '') {
      this.searchTerm = searchTerm;
    }
    this.categoryService.search(this.searchTerm, page).subscribe(result => {
      this.data = result;
      this.isLoading = false;
    }, error2 => {
      console.error(error2);
    });
  }

  onRowDetailClicked(object) {
    this.editCategory = object;
    this.showEditModal = true;
  }

  onRowDeleteClicked(object) {
    this.categoryService.delete(object.data).subscribe((result: any) => {
      if (result.statusCode && result.statusCode >= 400) {
        /*
        https://github.com/traverson/traverson-angular#how-http-status-code-are-handled

        In contrast to AngularJS' $http service, Traverson and traverson-angular do not interpret status codes outside of
        the 2xx range as an error condition. Only network problems (host not reachable, timeouts, etc.) lead to a rejection
        of the promise, that is, only those trigger the error callback. Completed HTTP requests, even those with status 4xx
        or 5xx are interpreted as a success and trigger the success callback. This applies only to the last request in a
        traversal, HTTP requests during the traversal that respond with 4xx/5xx are interpreted as an error (because the
        traversal can not continue).
         */

        switch (result.statusCode) {
          case 409:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: 'Die Kategorie kann nicht gelÃ¶scht werden, da noch AbhÃ¤ngigkeiten bestehen.'
              });
            break;

          default:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: JSON.parse(result.error).message
              });
            break;
        }

      } else {

        this.messageService.add(
          {
            severity: 'success',
            summary: 'Erfolg',
            detail: '\"' + object.data.displayName + '\" wurde gelÃ¶scht.'
          }
        );
        this.onReloadData(this.searchTerm);

      }

    }, error2 => {
      console.error(error2);
    });
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;
    this.onReloadData(this.searchTerm);
  }

  /********************************************
   * Toolbar
   *******************************************/

  /** Initialisierung der Toolbar items*/
  initToolbarItems(): DsvsToolbarItem[] {
    const tbItems: DsvsToolbarItem[] = [];

    if (this.configService.hasPermission(WorkflowPermissions.CATEGORY_CREATE)) {
      tbItems.push({
        id: CategoryListActions.ADD,
        icon: 'add_box',
        disabled: false,
        tooltip: 'Kategorie hinzufÃ¼gen',
        faicon: true,
        callback: () => {
          this.editCategory = null;
          this.showEditModal = true;
        }
      });
    }
    return tbItems;
  }

}
