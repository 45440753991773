import { Injector, NgModule } from '@angular/core';
import { TraversonNgModule } from '@dsvs/traverson-ng';
import { HAL_CLIENT_FACTORY_TOKEN } from './client-factory.interface';
import { HalClientFactoryImpl } from './client-factory.service';

export let AppInjector: Injector;

@NgModule({
    imports: [TraversonNgModule],
    declarations: [],
    providers: [
        {
            provide: HAL_CLIENT_FACTORY_TOKEN,
            useClass: HalClientFactoryImpl
        }
    ],
    exports: [TraversonNgModule]
})
export class HalClientModule {

    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }

}
