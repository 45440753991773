import { Component, ComponentFactoryResolver, Input, OnInit } from '@angular/core';
import { ActionContextHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowActionExtension } from '../../interfaces/WorkflowActionExtension';
import { WorkflowActionExtensionService } from '../../services/config/workflow-action-extension.service';
import { ViewInjectableComponent } from '../view-injectable.component';
import { WorkflowActionHistoryComponent } from '../workflow-action-history/workflow-action-history.component';

@Component({
  selector: 'wfg-workflow-action-history-factory',
  template: `<ng-template wfgActionHost></ng-template>
`,
  styles: [``]
})
export class WorkflowActionHistoryFactoryComponent extends ViewInjectableComponent<WorkflowActionHistoryComponent> implements OnInit {

  _actionContext: ActionContextHalItem;

  get actionContext(): ActionContextHalItem {
    return this._actionContext;
  }

  @Input()
  set actionContext(value: ActionContextHalItem) {
    this._actionContext = value;

    if (value.action) {
      value.action.async.subscribe(
        action => {
          if (action && action.data) {
            this.extension = this.workflowActionExtensionService.get(action.data.alias);
          }
        }
      );
    }
  }

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    private workflowActionExtensionService: WorkflowActionExtensionService
  ) {
    super(componentFactoryResolver);
  }

  ngOnInit() {
  }

  protected viewOfExtension(extension: WorkflowActionExtension): any {
    return extension.actionHistoryComponent;
  }

  protected assignValuesToComponent(instance: WorkflowActionHistoryComponent) {
    instance.actionContext = this.actionContext;
  }
}
