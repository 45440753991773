import { Subscription } from 'rxjs/Subscription';

export class AbortHandle {
  private listeners: Array<Function>;

  constructor(
    private subscription: Subscription
  ) {
    this.listeners = [];
  }

  abort() {
    this.subscription.unsubscribe();
    this.listeners.forEach((fn) => {
      fn.call(this);
    });
  }

  on(event: string, fn: Function) {
    if (event !== 'abort') {
      var error = new Error('Event ' + event + ' not supported');
      error.name = 'InvalidArgumentError';
      throw error;
    }
    this.listeners.push(fn);
  }
}
