import {NgModule} from '@angular/core';
import {WfgAutocompleteDialogComponent} from './wfg-autocomplete-dialog.component';
import {DsvsInlineEditModule} from '@dsvs/dsvs-shared-ui-lib';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    DsvsInlineEditModule,
  ],
  declarations: [
    WfgAutocompleteDialogComponent
  ],
  exports: [
    WfgAutocompleteDialogComponent
  ]
})
export class WfgAutocompleteDialogModule {
}




