import { HalClient, HalItem, HalItemConstructor, HalPage, HalPageConstructor } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { HalReadServiceImpl } from './hal.read.service';
import { HalRelation } from './hal.relation';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { WorkflowBaseReadWrite } from '@dsvs/workflow-generator-dto';
import { TypeMappingService } from './type-mapping.service';

export class HalCrudServiceImpl<T extends WorkflowBaseReadWrite, R extends HalItem<T>, U extends HalPage<T, R>>
  extends HalReadServiceImpl<T, R, U>
  implements DsvsSearchableCrudEntityService<T> {

  constructor(v1Client: Promise<HalClient>,
              relation: HalRelation,
              pageConstructor: HalPageConstructor<T, R, U>,
              itemConstructor: HalItemConstructor<T, R>) {
    super(v1Client, relation, pageConstructor, itemConstructor);
  }

  create(object: T): Observable<R> {
    return this.execute<R>((v1Client: HalClient) => v1Client
      .typedPost<T, R>(this.itemConstructor, object, this.relation.collection)
    );
  }

  update(object: T): Observable<R> {
    return this.execute<R>((v1Client: HalClient) => v1Client
      .typedPut<T, R>(this.itemConstructor, object, this.relation.single, <any>{entityId: object.id})
    );
  }

  delete(object: T): Observable<never> {
    return this.execute<never>((v1Client: HalClient) => <any>v1Client
      .delete(this.relation.single, <any>{entityId: object.id})
    );
  }
}

export class MappedHalCrudServiceImpl<T extends WorkflowBaseReadWrite, R extends HalItem<T>, U extends HalPage<T, R>>
  extends HalCrudServiceImpl<T, R, U> {
  constructor(v1Client: Promise<HalClient>,
              relation: HalRelation,
              typeString: string,
              typeResolver: TypeMappingService) {
    super(
      v1Client,
      relation,
      typeResolver.getPageConstructor<T, R, U>(typeString),
      typeResolver.getItemConstructor<T, R>(typeString)
    );
  }
}
