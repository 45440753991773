import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {DsvsSearchEvent, DsvsTableColumn, DsvsToolbarItem} from '@dsvs/dsvs-shared-ui-lib';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import {MenuItem} from 'primeng/api';
import {CrewHalItem} from '@dsvs/workflow-generator-dto';

@Component({
  selector: 'wfg-list',
  template: `<dsvs-table
  dataKey="id"
  [columns]="tableColumnOptions"
  [data]="data"
  [searchable]="true"
  [loading]="isLoading"
  [bodyTemplate]="bodyTemplate"
  [rowexpansionTemplate]="rowexpansionTemplate"
  (searchChanged)="changeSearch($event)"
  (pageChanged)="changePage($event)"
  (sortChanged)="onSortChanged($event)"
  [toolbarItems]="toolbarItems"
  [title]="title"
>
  <ng-template #bodyTemplate let-rowData="rowData" let-column="column">
    <div [ngSwitch]="column.format">
      <span *ngSwitchDefault>
      {{rowData.data[column.field] | shortener : 450}}
      </span>

      <ng-container *ngSwitchCase="'WORKFLOW_TYPE'">
        {{rowData.data[column.field] | workflowtype}}
      </ng-container>

      <ng-container *ngSwitchCase="'CREW'">
        <div class="link" (click)="showCrew($event, rowData?.crew?.sync)">
          <i *ngIf="rowData?.crew?.sync" class="material-icons">person</i>
        </div>
      </ng-container>

      <p-splitButton
        *ngSwitchCase="'ACTION'"
        class="icon-splitbutton"
        label="Bearbeiten"
        icon="fa ui-icon-edit"
        (onClick)="detailClick(rowData)"
        appendTo="body"
        [model]="getSplitButtonItems(rowData)">
      </p-splitButton>
    </div>
  </ng-template>

</dsvs-table>


<p-dialog
  header="LÃ¶schen"
  *ngIf="showDeleteDialog"
  [(visible)]="showDeleteDialog"
  [modal]="true"
  [dismissableMask]="true"
  [closable]="true"
>
  MÃ¶chten Sie den Eintrag "
  <i>
    {{
    deleteObject.data
      ? deleteObject.data.displayName
      ? deleteObject.data.displayName
      : ''
      :
      deleteObject.displayName
        ? deleteObject.displayName
        : ''
    }}
  </i>
  " wirklich lÃ¶schen?
  <p-footer>
    <button type="button" pButton icon="fa ui-icon-check" (click)="deleteItem()" label="Ja">
    </button>
    <button type="button" pButton icon="fa ui-icon-close" (click)="showDeleteDialog=false" label="Nein"
            class="ui-button-secondary">
    </button>
  </p-footer>
</p-dialog>


<p-dialog
  [header]="'Crew Ã¤ndern'"
  [className]="'in-dialog-footer'"
  *ngIf="displayedCrew != null"
  [visible]="displayedCrew != null"
  width="1000"
  height="800"
  [positionTop]="30"
  [modal]="true"
  [dismissableMask]="true"
  [closable]="true"
  (visibleChange)="receiveHideCrewModal($event)"
>
  <wfg-crew-detail
    [crew]="displayedCrew"
    (showEditModalEvent)="receiveHideCrewModal($event)"
  >
  </wfg-crew-detail>
</p-dialog>

`,
  styles: [``]
})
export class WfgListComponent {
  // TODO: type this!
  @Input()
  data: any[];

  @Input()
  title: string;

  _tableColumnOptions: DsvsTableColumn[];

  _additionalColumnOptions: DsvsTableColumn[] = [
    {field: 'id', header: '', format: 'ACTION', width: '7%'}
  ];

  @Input()
  rowexpansionTemplate: TemplateRef<any>;

  @Input()
  isLoading: boolean;

  @Input()
  set tableColumnOptions(tableColumnOptions: DsvsTableColumn[]) {
    this._tableColumnOptions = tableColumnOptions.concat(this._additionalColumnOptions);
  }

  get tableColumnOptions() {
    return this._tableColumnOptions;
  }

  @Input()
  isDeletable: boolean;

  @Output()
  reloadDataTriggered: EventEmitter<DsvsSearchEvent> = new EventEmitter<DsvsSearchEvent>();

  @Output()
  rowDetailClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  rowDeleteClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pageChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  sortChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  searchChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  toolbarItems: DsvsToolbarItem[];

  showDeleteDialog = false;
  deleteObject: any;

  displayedCrew: CrewHalItem;

  reloadData(page: DsvsSearchEvent) {
    this.reloadDataTriggered.emit(page);
  }

  detailClick(obj) {
    this.rowDetailClicked.emit(obj);
  }

  deleteClick(obj) {
    this.deleteObject = obj;
    this.showDeleteDialog = true;
  }

  deleteItem() {
    this.rowDeleteClicked.emit(this.deleteObject);
    this.showDeleteDialog = false;
  }

  /*************************
   * Search
   *************************/

  changeSearch(event) {
    // this.reloadData(event);
    this.searchChanged.emit(event);
  }

  onSortChanged(event) {
    this.sortChanged.emit(event);
  }

  changePage(event) {
    this.pageChanged.emit(event);
  }

  getSplitButtonItems(halItem: any): MenuItem[] {
    const menuItems: MenuItem[] = [
      {
        label: 'Bearbeiten', icon: 'fas fas-pencil', command: () => {
          this.detailClick(halItem);
        }
      },
      {
        label: 'LÃ¶schen',
        icon: 'fas fas-trash',
        disabled: !this.isDeletable,
        command: () => {
          this.deleteClick(halItem);
        }
      }
    ];

    return menuItems;
  }


  showCrew(event: MouseEvent, crew: CrewHalItem) {
    event.stopPropagation();
    this.displayedCrew = crew;
  }

  receiveHideCrewModal($event) {
    this.displayedCrew = null;
  }

}
