import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dsvs-showcase-example-description',
  template: `<ng-content></ng-content>
`,
  styles: [``]
})
export class ShowcaseExampleDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
