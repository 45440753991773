import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DsvsBaseLayoutService {

  public onReplaceTopbar = new Subject<any>();
  public onReplaceMenu = new Subject<any>();
  public onReplaceFooter = new Subject<any>();
  public onReplaceBreadcrumb = new Subject<any>();
  public onExtendTopbar = new Subject<any>();
  public onExtendMenu = new Subject<any>();

  public onLayoutInitFinished = new Subject<DsvsBaseLayoutService>();

  constructor() {
  }

  public startComponentIsInitialized() {
    this.onLayoutInitFinished.next(this);
  }

  public extendTopbar(newComponent: any) {
    this.onExtendTopbar.next(newComponent);
  }

  public extendMenu(newComponent: any) {
    this.onExtendMenu.next(newComponent);
  }

  public replaceTopbar(newComponent: any) {
    this.onReplaceTopbar.next(newComponent);
  }

  public replaceMenu(newComponent: any) {
    this.onReplaceMenu.next(newComponent);
  }

  public replaceFooter(newComponent: any) {
    this.onReplaceFooter.next(newComponent);
  }

  public replaceBreadcrumb(newComponent: any) {
    this.onReplaceBreadcrumb.next(newComponent);
  }

}
