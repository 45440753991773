import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InlineEditComponent } from '@dsvs/dsvs-shared-ui-lib';
import { CategoryHalItem, CrewHalItem, ProductHalItem, ProductHalPage, WorkflowCategory } from '@dsvs/workflow-generator-dto';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { DtoHelper } from '../../../../classes/dto-helper';
import { WorkflowGeneratorConfigurationService } from '../../../../services/config/workflow-generator-configuration.service';
import { WorkflowPermissions } from '../../../../services/config/workflow-permissions.enum';
import { WorkflowCategoryServiceImpl } from '../../../../services/data/workflow-category.service';
import { WorkflowCrewServiceImpl } from '../../../../services/data/workflow-crew.service';
import { WorkflowProductServiceImpl } from '../../../../services/data/workflow-product.service';

@Component({
  selector: 'wfg-category-detail',
  template: `<div class="category-detail-container">
  <div class="ui-g" *ngIf="category">

    <div class="ui-lg-6">
      <dsvs-inline-edit
        id="category-name"
        label="Name"
        type="text"
        [(value)]="category.displayName"
        [isInline]="!isNew"
        [onSave]="saveName"
        [disabled]="!isEditable"
      >
      </dsvs-inline-edit>
    </div>

    <div class="ui-lg-6" *ngIf="categoryHalItem && categoryHalItem.crew; else noCrew">
      <dsvs-inline-edit
        label="Crew"
        id="category-crew"
        [isInline]="!isNew"
        type="autocomplete"
        [multiple]="false"
        [(value)]="categoryHalItem.crew.sync.data"
        [onFetch]="fetchCrews"
        [onSave]="selectCrew"
        displayValue="displayName"
        [disabled]="!isEditable"
      >
      </dsvs-inline-edit>
    </div>

    <ng-template #noCrew>
      <div class="ui-lg-6">
        <dsvs-inline-edit
          label="Crew"
          [isInline]="!isNew"
          type="autocomplete"
          [multiple]="false"
          [onSave]="selectCrew"
          [onFetch]="fetchCrews"
          displayValue="displayName"
          [disabled]="!isEditable"

        >
        </dsvs-inline-edit>
      </div>
    </ng-template>


    <div class="ui-lg-12" *ngIf="categoryHalItem && categoryHalItem.products; else noProducts">
      <dsvs-inline-edit
        label="Formulare"
        [isInline]="!isNew"
        type="autocomplete"
        [(value)]="categoryHalItem.products.sync.content"
        listPlaceholdersAmongEachOthers="true"
        [onFetch]="fetchForms"
        displayValue="displayName"
        [onSave]="addForm"
        [disabled]="!isEditable"

      >
      </dsvs-inline-edit>
    </div>
    <ng-template #noProducts>
      <div class="ui-lg-12">
        <dsvs-inline-edit
          label="Formulare"
          [isInline]="!isNew"
          type="autocomplete"
          [onFetch]="fetchForms"
          displayValue="displayName"
          listPlaceholdersAmongEachOthers="true"
          [onSave]="addForm"
          [disabled]="!isEditable"

        >
        </dsvs-inline-edit>
      </div>
    </ng-template>

  </div>
</div>

<p-footer style="display: block; width: 100%; padding-top: 40px">
  <div class="footer-buttons" style="display: flex; justify-content: flex-end">
    <button
      (click)="cancelModal()"
      pButton
      type="button"
      class="ui-button-text dsvs-btn ui-corner-all"
      label="Schliessen"
      icon="fa ui-icon-close">
    </button>
    <button
      *ngIf="isNew"
      (click)="save()"
      pButton
      type="button"
      class="ui-button-text dsvs-btn ui-corner-all"
      label="Speichern"
      icon="fa ui-icon-save">
    </button>
  </div>
</p-footer>
`,
  styles: [`.ui-dialog-content{min-height:530px;margin-right:20px}.category-detail-container{padding:1em}`]
})
export class CategoryDetailComponent implements OnInit {

  category: WorkflowCategory;
  _categoryHalItem: CategoryHalItem;

  crew: CrewHalItem;
  products: ProductHalItem[] = [];

  @Output() showEditModalEvent = new EventEmitter<boolean>();

  isNew = false;
  isEditable = false;

  @Input()
  // should be type CategoryHalItem, but complains in template on  [(value)]="categoryHalItem.crew.sync.data "Data is a read-only property
  set categoryHalItem(categoryHalItem: any) {
    if (categoryHalItem) {
      this.category = categoryHalItem.data;
      this._categoryHalItem = categoryHalItem;
    }
  }

  get categoryHalItem(): any {
    return this._categoryHalItem;
  }

  constructor(
    private categoryService: WorkflowCategoryServiceImpl,
    private formService: WorkflowProductServiceImpl,
    private crewService: WorkflowCrewServiceImpl,
    private configService: WorkflowGeneratorConfigurationService
  ) {
  }

  ngOnInit() {

    this.isEditable = this.isNew || this.configService.hasPermission(WorkflowPermissions.CATEGORY_UPDATE);

    if (isNil(this._categoryHalItem)) {
      this.isNew = true;
      this.crewService.search('').subscribe(
        crews => {
          this.category = <WorkflowCategory>DtoHelper.getCategory();
        }, crewError => {
          console.error(crewError);
        });
    }
  }

  fetchCrews = ($event, inlineEdit): Observable<any> => {
    return this.crewService.search($event.query);
  };

  fetchForms = ($event, inlineEdit): Observable<any> => {
    return this.formService.search($event.query);
  };

  selectCrew = (value, component): Observable<any> => {
    this.crew = component.modelValue;
    this.category.crewId = this.crew.data.id;
    if (this.isNew) {
      return Observable.of(null);
    } else {
      return this.updateCategory(this.category);
    }
  };

  addForm = (value, component: InlineEditComponent): Observable<ProductHalPage> => {
    this.products = component.modelValue;
    if (this.isNew) {
      return Observable.of(null);
    } else {
      return this.updateProducts(this.products);
    }
  };

  updateProducts(products: ProductHalItem[]): Observable<ProductHalPage> {
    return this._categoryHalItem.updateRelations('products', products.map(o => o.data.id));
  }

  updateCategory(category: WorkflowCategory): Observable<CategoryHalItem> {
    return this.categoryService.update(category);
  }

  search(event) {

    // let search = <PagedSearch>{
    //   searchterm: event.query,
    //   page: 0,
    //   pagesize: 10
    // };
    //
    // console.log(search);
    // this.formService.search(search).subscribe(result => {
    //   this.forms = result.content;
    //   this.dropdownForms = DropdownHelper.mapObjectsToSelectItems(this.forms, 'displayName', true);
    //   console.log(this.dropdownForms);
    // });
  }

  saveName = (value, component): Observable<any> => {
    if (this.isNew) {
      this.category.displayName = component.value;
      return Observable.of(null);
    } else {
      return this.updateCategory(this.category);
    }
  };

  save() {
    if (this.isNew) {
      this.categoryService.create(this.category).subscribe(
        categoryHalItem => {
          this._categoryHalItem = categoryHalItem;
          this.updateProducts(this.products).subscribe(
            productHalPage => {
              this.cancelModal();
            }, error2 => {
              console.error(error2);
            });
        });
    } else {
      this.updateCategory(this.category).subscribe(
        categoryHalPage => {
          this.cancelModal();
        }, error => {
          console.error('Error updating category - {}', error);
        });
    }
  }

  cancelModal() {
    this.showEditModalEvent.emit(false);
  }
}
