import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { RoleHalItem, RoleHalPage, WorkflowRole } from '@dsvs/workflow-generator-dto';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowRoleService extends DsvsSearchableCrudEntityService<WorkflowRole> {

}

// @dynamic
@Injectable()
export class WorkflowRoleServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowRole, RoleHalItem, RoleHalPage>
  implements WorkflowRoleService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'role', collection: 'roles'},
      WorkflowGeneratorTypeMappingService.WorkflowRole,
      typeResolver
    );
  }

}

@Injectable()
export class WorkflowRoleServiceMock extends DsvsSearchableCrudEntityServiceMock<WorkflowRole> implements WorkflowRoleService {

  constructor() {
    super();

    this.data = [
      {
        id: '111',
        displayName: 'Entwickler',
        description: 'Description 1'
      },
      {
        id: '222',
        displayName: 'PrÃ¼fer',
        description: 'Description 2'
      },
      {
        id: '333',
        displayName: 'Freigebender',
        description: 'Description 3'
      }
    ];
  }

}
