export function versionComparator(a, b, direction = 1) {
  if (!a || !b) {
    throw Error('Version must not be null!');
  }

  const a1 = a.split('.');
  const b1 = b.split('.');
  const len = Math.max(a1.length, b1.length);

  for (let i = 0; i < len; i++) {
    const _a = +a1[i] || 0;
    const _b = +b1[i] || 0;
    if (_a === _b) {
      continue;
    } else {
      return (_a > _b ? -1 : 1) * direction;
    }
  }
  return 0;
}
