import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dsvs-icon-button',
  template: `
  <i (click)="onClick($event)" class="icon-button" [ngClass]="icon">
  </i>


`,
  styles: [`:host{line-height:0}.icon-button{cursor:pointer;margin-right:5px;margin-left:5px;height:100%;width:100%;color:#0099fb!important}`]
})
export class IconButtonComponent {

  @Input() icon: string = null;
  @Output() click = new EventEmitter<any>();

  onClick(event) {
    if (this.click != null) {
      event.stopPropagation();
      this.click.emit(event);
    }
  }

}
