import {animate, state, style, transition, trigger} from '@angular/animations';
import {Location} from '@angular/common';
import {AfterViewInit, Component, ComponentFactoryResolver, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/primeng';
import {DsvsAuthorizationService} from '../../../authentication/services/authorization.service';
import {DsvsLayoutStateService} from '../../services/layout-state.service';
import {DsvsMenuService} from '../../services/menu.service';

declare var jQuery: any;

@Component({
  selector: 'dsvs-menu',
  template: `<span class="sidebar-container">
  <div
    class="layout-sidebar" [ngClass]="{'layout-sidebar-active': layoutState.sidebarActive || layoutState.layoutStatic,
    'layout-sidebar-inactive': !layoutState.sidebarActive, 'full-height': !useHeader}"
    (click)="layoutState.onSidebarClick($event)" (mouseover)="layoutState.sidebarActive=true"
    (mouseleave)="layoutState.sidebarActive=false">
    <div #layoutMenuScroller class="nano">
      <div class="nano-content sidebar-scroll-content">
        <div class="logo-container">
          <div *ngIf="!layoutState.layoutStatic && !layoutState.sidebarActive; else fullLogo"
            class="icon-only">
            <img src="assets/lib-dsvs/images/logo_small.svg"/>
          </div>
          <ng-template #fullLogo>
            <div class="full-logo">
              <img class="anwendungslogo-ffb-fes" src="assets/lib-dsvs/images/anwendungslogo_ffb_fes.svg">
            </div>
          </ng-template>
        </div>
        <div class="layout-menu-container" (click)="updateNanoScroll()" *hasPermission="'LOGIN'">
          <ul app-submenu [item]="model" root="true" class="layout-menu" visible="true" [reset]="reset"></ul>
        </div>
      </div>
    </div>
    <div class="bottom-menu-container">
      <span [hidden]="!layoutState.layoutStatic && !layoutState.sidebarActive">
        <ng-container #extensionContainer></ng-container>
      </span>
      <div class="layout-menu-container" (click)="updateNanoScroll()" *hasPermission="'LOGIN'">
        <ul app-submenu [item]="userMenu" root="true" class="layout-menu" visible="true" [reset]="reset"></ul>
      </div>
      <div class="sidebar-logo" (click)="layoutState.onToggleMenuClick($event)">
        {{layoutState.layoutStatic ? 'MenÃ¼ zuklappen' : 'MenÃ¼ aufklappen'}}
        <a class="navicon-button" title="Toggle Menu" [ngClass]="{'open':layoutState.layoutStatic}"></a>
      </div>
    </div>
  </div>
</span>
`,
  styles: [`.nano{display:flex;flex-direction:column;justify-content:space-between;height:auto}`]
})
export class DsvsMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('extensionContainer', {read: ViewContainerRef}) extensionContainer;

  @Input() reset: boolean;
  @Input() useHeader = false;

  model: MenuItem[];
  userMenu: MenuItem[];

  layoutMenuScroller: HTMLDivElement;

  @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ElementRef;

  constructor(public layoutState: DsvsLayoutStateService,
              private menuService: DsvsMenuService,
              private authService: DsvsAuthorizationService,
              private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    /** MenÃ¼-Model initialisieren bzw. nach Login abonnieren */
    // console.log('MenuComponent.init');

    if (this.authService.isLoggedIn) {
      this.menuService.requestMenu.next(true);
    }

    this.authService.getLoginDone().subscribe(ls => {
      if (ls) {
        // this.model = this.menuService.getMenu();
        this.menuService.requestMenu.next(true);
      }
    });

    this.menuService.menuChanged.subscribe(value => {
      this.model = this.menuService.getMenu();
    });

    this.menuService.userMenuChanged.subscribe(value => {
      this.userMenu = this.menuService.getUserMenu();
    });
  }

  ngAfterViewInit() {
    this.layoutMenuScroller = <HTMLDivElement>this.layoutMenuScrollerViewChild.nativeElement;

    setTimeout(() => {
      jQuery(this.layoutMenuScroller).nanoScroller({flash: true});
    }, 10);
  }

  changeTheme(theme) {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
    themeLink.href = 'assets/theme/theme-' + theme + '.css';
  }

  changeLayout(theme) {
    const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
  }

  updateNanoScroll() {
    setTimeout(() => {
      jQuery(this.layoutMenuScroller).nanoScroller();
    }, 500);
  }

  ngOnDestroy() {
    jQuery(this.layoutMenuScroller).nanoScroller({flash: true});
  }

  public setExtensionComponent(view: any) {
    this.extensionContainer.createComponent(this.componentFactoryResolver.resolveComponentFactory(view));
  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
    <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
      <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
        <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
           [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
           (mouseenter)="hover=true" (mouseleave)="hover=false" class="ripplelink">
          <i class="material-icons">{{child.icon}}</i>
          <span class="menuitem-text">{{child.label}}</span>
          <i class="material-icons layout-submenu-toggler" *ngIf="child.items">keyboard_arrow_down</i>
          <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>

        <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
           [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
           [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null"
           [attr.target]="child.target"
           (mouseenter)="hover=true" (mouseleave)="hover=false" class="ripplelink">
          <i class="material-icons">{{child.icon}}</i>
          <span class="menuitem-text">{{child.label}}</span>
          <i class="material-icons layout-submenu-toggler" *ngIf="child.items">>keyboard_arrow_down</i>
          <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>
        <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset"
            [@children]="isActive(i) ? 'visible' : 'hidden'"></ul>
      </li>
    </ng-template>
  `,
  animations: [
    trigger('children', [
      state('visible', style({
        height: '*'
      })),
      state('hidden', style({
        height: '0px'
      })),
      transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppSubMenuComponent {

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _reset: boolean;

  activeIndex: number;

  hover: boolean;

  constructor(public layoutState: DsvsLayoutStateService, public router: Router, public location: Location) {
  }

  itemClick(event: Event, item: MenuItem, index: number) {
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    if (item.routerLink || item.items || item.command || item.url) {
      this.activeIndex = (this.activeIndex === index) ? null : index;
    }

    // execute command
    if (item.command) {
      item.command({originalEvent: event, item: item});
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.layoutState.isMobile()) {
        this.layoutState.sidebarActive = false;
        this.layoutState.mobileMenuActive = false;
      }
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;
  }

}
