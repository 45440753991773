import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { ShowcaseExampleCodeComponent } from './components/showcase-example-code/showcase-example-code.component';
import { ShowcaseExampleDemoComponent } from './components/showcase-example-demo/showcase-example-demo.component';
import { ShowcaseExampleDescriptionComponent } from './components/showcase-example-description/showcase-example-description.component';
import { ShowcaseExampleComponent } from './components/showcase-example/showcase-example.component';
import { ShowcaseLayoutComponent } from './components/showcase-layout/showcase-layout.component';

@NgModule({
  imports: [
    CommonModule,
    PanelModule
  ],
  declarations: [
    ShowcaseLayoutComponent,
    ShowcaseExampleComponent,
    ShowcaseExampleDemoComponent,
    ShowcaseExampleCodeComponent,
    ShowcaseExampleDescriptionComponent
  ],
  exports: [
    ShowcaseLayoutComponent,
    ShowcaseExampleComponent,
    ShowcaseExampleDemoComponent,
    ShowcaseExampleCodeComponent,
    ShowcaseExampleDescriptionComponent
  ]
})
export class DsvsShowcaseModule {
}
