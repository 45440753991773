import {Injectable} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {BehaviorSubject} from 'rxjs';
import {DsvsAuthorizationService} from '../../authentication/services/authorization.service';
import {DsvsSharedUiLibConfigService} from '../../dsvs-shared-ui-lib-config.service';
import {DsvsNotificationService} from '../../notification/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class DsvsMenuService {

  private menu: MenuItem[] = [];
  private userMenu: MenuItem[] = [];

  public requestMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public menuChanged: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  public userMenuChanged: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  unreadNotificationsCount = 0;

  constructor(private authService: DsvsAuthorizationService,
              public configService: DsvsSharedUiLibConfigService,
              private notificationService: DsvsNotificationService) {
  }

  public getMenu(): MenuItem[] {
    return this.menu;
  }

  public clearMenu() {
    this.menu = [];
    this.menuChanged.next(this.menu);
  }

  /** Add **/

  public addMenuItem(menuItem: MenuItem) {
    if (menuItem) {
      this.menu.push(menuItem);
      this.menuChanged.next(this.menu);
    }
  }

  public addMenuItemHome() {
    this.addMenuItem(this.createMenuItemHome());
  }

  public addMenuItemNotifications() {
    this.addMenuItem(this.createMenuItemNotifications());
  }

  public addMenuItemAdministration() {
    this.addMenuItem(this.createMenuItemAdministration());
  }

  public addMenuItemRequirements() {
    this.addMenuItem(this.createMenuItemRequirements());
  }

  public addMenuItemServiceupdates() {
    this.addMenuItem(this.createMenuItemServiceupdates());
  }

  public addMenuItemSearch() {
    this.addMenuItem(this.createMenuItemSearch());
  }

  /** Create **/

  public createMenuItemHome(): MenuItem {
    return <MenuItem>{label: 'Home', icon: 'home', routerLink: ['/home']};
  }

  public createMenuItemNotifications(): MenuItem {
    return <MenuItem>{label: 'Benachrichtigungen', icon: 'notifications', routerLink: ['/notifications']};
  }

  public createMenuItemAdministration(): MenuItem {

    const menuModel: MenuItem[] = [];
    let subItems: MenuItem[] = [];

    /** Administration
     * ---------------*/
    subItems = [];
    if (this.authService.hasRight('FES_CONFIG_SETTINGS')) {
      subItems.push({label: 'Allgem. Einstellungen', icon: 'perm_data_settings', routerLink: ['/uc']});
    }
    if (this.authService.hasRight('FES_DEVTEST_NOTIFICATION_DUMMY')) {
      subItems.push({
        label: 'Dummy-Notification', icon: 'build', command: (event) => {
          // this.createDummyNotification();
        }
      });
    }
    if (this.authService.hasRight('FES_CONFIG')) {
      return {label: 'Administration', icon: 'settings_application', items: subItems};
    }
    return null;
  }

  public createMenuItemRequirements(): MenuItem {
    return <MenuItem>{label: 'Anforderungen', icon: 'assignment_turned_in', routerLink: ['/requirements']};
  }

  public createMenuItemServiceupdates(): MenuItem {
    return <MenuItem>{label: 'Serviceupdates', icon: 'update', routerLink: ['/serviceupdates']};
  }

  public createMenuItemSearch(): MenuItem {
    return <MenuItem>{label: 'Suche', icon: 'search', routerLink: ['/search']};
  }

//  user menu
  public getUserMenu(): MenuItem[] {
    return this.userMenu;
  }

  public clearUserMenu() {
    this.userMenu = [];
    this.userMenuChanged.next(this.userMenu);
  }

  /** Add **/

  public addUserMenuItem(menuItem: MenuItem) {
    if (menuItem) {
      this.userMenu.push(menuItem);
      this.userMenuChanged.next(this.userMenu);
    }
  }

  public addUserMenu() {

    if (this.unreadNotificationsCount !== this.notificationService.notificationListUnread.length) {
      this.updateUserMenu();
    }
    this.notificationService.notificationRefresh.subscribe(result => {
      this.updateUserMenu();
    }, error => {
      console.error(error);
    });

  }

  updateUserMenu() {
    this.unreadNotificationsCount = this.notificationService.notificationListUnread.length;

    this.clearUserMenu();
    this.addUserMenuItemUser();
    this.addUserMenuItemNotifications();
  }

  public addUserMenuItemUser() {
    this.addUserMenuItem(this.createUserMenuItemUser());
  }

  public addUserMenuItemNotifications() {
    this.addUserMenuItem(this.createUserMenuItemNotifications());
  }

  public addUserMenuItemSettings() {
    this.addUserMenuItem(this.createUserMenuItemSettings());
  }

  public addUserMenuItemLogout() {
    this.addUserMenuItem(this.createUserMenuItemLogout());
  }

  /** Create **/

  public createUserMenuItemUser(): MenuItem {

    const subItems: MenuItem[] = [];
    subItems.push(this.createUserMenuItemSettings());
    subItems.push(this.createUserMenuItemLogout());

    return <MenuItem>{
      label: this.authService.getSalutation(), icon: 'person', items: subItems
    };
  }

  public createUserMenuItemNotifications(): MenuItem {
    return <MenuItem>{
      label: this.unreadNotificationsCount + ' Benachrichtigungen',
      icon: 'notifications',
      routerLink: ['/notifications'],
      badge: this.unreadNotificationsCount > 0 ? '.' : ''
    };
  }

  public createUserMenuItemSettings(): MenuItem {
    return <MenuItem>{label: 'Einstellungen', icon: 'settings_outline', routerLink: [this.configService.config.routing.settings]};
  }

  public createUserMenuItemLogout(): MenuItem {
    return <MenuItem>{label: 'Abmelden', icon: 'power_settings_new', routerLink: [this.configService.config.routing.logout]};
  }

}
