import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { TransitionHalItem, TransitionHalPage, WorkflowTransition } from '@dsvs/workflow-generator-dto';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowTransitionService extends DsvsSearchableCrudEntityService<WorkflowTransition> {

}

// @dynamic
@Injectable()
export class WorkflowTransitionServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowTransition, TransitionHalItem, TransitionHalPage>
  implements WorkflowTransitionService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'transition', collection: 'transitions'},
      WorkflowGeneratorTypeMappingService.WorkflowTransition,
      typeResolver
    );
  }

}

@Injectable()
export class WorkflowTransitionServiceMock extends DsvsSearchableCrudEntityServiceMock<WorkflowTransition>
  implements WorkflowTransitionService {

  constructor() {
    super();

    this.data = [];

    // for (let i = 1; i <= 100; i++) {
    //   this.data.push(
    //     <WorkflowTransition>{
    //       id: String(i),
    //       displayName: 'Transition ' + i,
    //       actions: []
    //     }
    //   );
    // }

  }

}
