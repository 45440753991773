import { Injectable } from '@angular/core';
import {
  ActionContextHalItem,
  ActionContextHalPage,
  ContextHalItem,
  ContextHalPage,
  StepContextHalItem,
  StepContextHalPage
} from '@dsvs/workflow-generator-dto';
import { TypeMappingServiceImpl } from '../../../hal/type-mapping-impl.service';

// Needed to register Service Types to corresponding Workflow-Models
@Injectable({
  providedIn: 'root'
})
export class WorkflowTypeMappingService extends TypeMappingServiceImpl {
  public static readonly WorkflowContext = 'WorkflowContext';
  public static readonly WorkflowStepContext = 'WorkflowStepContext';
  public static readonly WorkflowActionContext = 'WorkflowActionContext';

  constructor() {
    super();
    this.registerType(WorkflowTypeMappingService.WorkflowContext, ContextHalItem, ContextHalPage);
    this.registerType(WorkflowTypeMappingService.WorkflowStepContext, StepContextHalItem, StepContextHalPage);
    this.registerType(WorkflowTypeMappingService.WorkflowActionContext, ActionContextHalItem, ActionContextHalPage);
  }
}
