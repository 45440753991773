import { Component, Input, OnInit } from '@angular/core';
import { ProcessedActionHalItem } from '@dsvs/workflow-generator-dto';

@Component({
  selector: 'wfg-workflow-action-history-entry',
  template: `<div class="history-entry">
  <div class="history-title">{{title}}</div>
  <div class="small">
    <span class="time">{{processedAction?.data.createdAt | date: 'dd.MM.yy, HH:mm'}}</span>
    <span
      class="name">{{processedAction?.data.createdByName ? processedAction?.data.createdByName : processedAction?.data.createdBy}}</span>
  </div>
</div>
<ng-content></ng-content>
`,
  styles: [`.history-entry{display:flex;flex-direction:column;align-items:flex-start;justify-content:center;padding-top:4px;padding-bottom:4px;font-size:10pt}.history-title{font-weight:700;margin-bottom:4px;color:#0c307d;font-size:11pt}.small{margin-bottom:4px}.small .time{color:#454a54}.small .name{color:#71767f;margin-left:8px}`]
})
export class WorkflowActionHistoryEntryComponent implements OnInit {

  @Input() title: string;

  @Input() processedAction: ProcessedActionHalItem;

  constructor() {
  }

  ngOnInit() {
  }

}
