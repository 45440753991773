import {CommonModule, registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Routes} from '@angular/router';
import {DsvsInlineEditModule, DsvsSharedModule, DsvsTableModule} from '@dsvs/dsvs-shared-ui-lib';
import {HalClientModule} from '@dsvs/hal-client';
import {OrderModule} from 'ngx-order-pipe';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {MessageService} from 'primeng/components/common/messageservice';
import {DialogModule} from 'primeng/dialog';
import {GrowlModule} from 'primeng/growl';
import {InplaceModule} from 'primeng/inplace';
import {MessageModule} from 'primeng/message';
import {AccordionModule, AutoCompleteModule, CheckboxModule, ConfirmDialogModule, DropdownModule, PaginatorModule, PanelModule, ProgressSpinnerModule, SplitButtonModule, TabViewModule, TooltipModule} from 'primeng/primeng';
import {TableModule} from 'primeng/table';
import {WorkflowCategoryGuard} from '../workflowgenerator/guards/workflow-category.guard';
import {WorkflowCrewGuard} from '../workflowgenerator/guards/workflow-crew.guard';
import {WorkflowRoleGuard} from '../workflowgenerator/guards/workflow-role.guard';
import {WorkflowWorkflowGuard} from '../workflowgenerator/guards/workflow-workflow.guard';
import {WorkflowtypePipe} from '../workflowgenerator/pipes/workflow-type.pipe';
import {WorkflowGeneratorTypeMappingService} from '../workflowgenerator/services/config/workflow-generator-type-mapping.service';
import {WorkflowStepRoleServiceImpl} from '../workflowgenerator/services/data/workflow-step-role.service';
import {WorkflowStepServiceImpl} from '../workflowgenerator/services/data/workflow-step.service';
import {WorkflowTagServiceImpl} from '../workflowgenerator/services/data/workflow-tag.service';
import {WorkflowTransitionServiceImpl} from '../workflowgenerator/services/data/workflow-transition.service';
import {WorkflowUploadPartServiceImpl} from '../workflowgenerator/services/data/workflow-upload-part.service';
import {CategoryDetailComponent} from './components/content/category/category-detail/category-detail.component';
import {CategoryListComponent} from './components/content/category/category-list/category-list.component';
import {CrewDetailComponent} from './components/content/crew/crew-detail/crew-detail.component';
import {CrewListComponent} from './components/content/crew/crew-list/crew-list.component';
import {RoleDetailComponent} from './components/content/role/role-detail/role-detail.component';
import {RoleListComponent} from './components/content/role/role-list/role-list.component';
import {WorkflowDetailComponent} from './components/content/workflow/workflow-detail/workflow-detail.component';
import {WorkflowListComponent} from './components/content/workflow/workflow-list/workflow-list.component';
import {WfgListComponent} from './components/wfg-list/wfg-list.component';
import {WorkflowCategoryResolver} from './resolver/workflow-category-resolver';
import {WorkflowCrewResolver} from './resolver/workflow-crew-resolver';
import {WorkflowResolver} from './resolver/workflow-resolver';
import {WorkflowRoleResolver} from './resolver/workflow-role-resolver';
import {WorkflowGeneratorConfigurationService} from './services/config/workflow-generator-configuration.service';
import {WorkflowGeneratorRoutingService} from './services/config/workflow-generator-routing.service';
import {WorkflowActionServiceImpl} from './services/data/workflow-action.service';
import {WorkflowCategoryServiceImpl} from './services/data/workflow-category.service';
import {WorkflowCrewServiceImpl} from './services/data/workflow-crew.service';
import {WorkflowProductServiceImpl} from './services/data/workflow-product.service';
import {WorkflowRightServiceImpl} from './services/data/workflow-right.service';
import {WorkflowRoleUserServiceImpl} from './services/data/workflow-role-users.service';
import {WorkflowRoleServiceImpl} from './services/data/workflow-role.service';
import {WorkflowStateServiceImpl} from './services/data/workflow-state.service';
import {WorkflowUserServiceImpl} from './services/data/workflow-user.service';
import {WorkflowServiceImpl} from './services/data/workflow.service';
import {WfgAutocompleteDialogModule} from './components/wfg-autocomplete-dialog/wfg-autocomplete-dialog.module';
import {GroupListComponent} from './components/content/group/group-list/group-list.component';
import {GroupDetailComponent} from './components/content/group/group-detail/group-detail.component';
import {WorkflowGroupResolver} from './resolver/workflow-group-resolver';
import {WorkflowGroupGuard} from './guards/workflow-group.guard';
import {WorkflowGroupServiceImpl} from './services/data/workflow-group.service';
import {CrewDetailRoleUserComponent} from './components/content/crew/crew-detail/crew-detail-role-user/crew-detail-role-user.component';

registerLocaleData(localeDE);

export let AppInjector: Injector;

@NgModule({
  imports: [
    CommonModule,
    GrowlModule,
    ConfirmDialogModule,
    DialogModule,
    TableModule,
    DropdownModule,
    CardModule,
    ProgressSpinnerModule,
    BrowserModule,
    FormsModule,
    TabViewModule,
    ReactiveFormsModule,
    MessageModule,
    AutoCompleteModule,
    InplaceModule,
    PanelModule,
    AccordionModule,
    CheckboxModule,
    PaginatorModule,
    HttpClientModule,
    DsvsInlineEditModule,
    HalClientModule,
    DsvsTableModule,
    DsvsSharedModule,
    ButtonModule,
    BrowserAnimationsModule,
    OrderModule,
    SplitButtonModule,
    WfgAutocompleteDialogModule,
    TooltipModule
  ],
  declarations: [
    CategoryDetailComponent,
    CategoryListComponent,
    CrewListComponent,
    CrewDetailComponent,
    CrewDetailRoleUserComponent,
    GroupListComponent,
    GroupDetailComponent,
    RoleDetailComponent,
    RoleListComponent,
    WfgListComponent,
    WorkflowDetailComponent,
    WorkflowListComponent,
    WorkflowtypePipe

  ],
  exports: [
    CategoryDetailComponent,
    CategoryListComponent,
    CrewListComponent,
    CrewDetailComponent,
    GroupListComponent,
    GroupDetailComponent,
    WorkflowDetailComponent,
    WorkflowListComponent,
    RoleDetailComponent,
    RoleListComponent,
    WfgListComponent,
    WorkflowtypePipe
  ],
  providers: [
    WorkflowGeneratorTypeMappingService,
    WorkflowGeneratorConfigurationService,
    WorkflowGeneratorRoutingService,
    WorkflowRoleResolver,
    WorkflowCrewResolver,
    WorkflowGroupResolver,
    MessageService,
    WorkflowServiceImpl,
    WorkflowRoleServiceImpl,
    WorkflowStepRoleServiceImpl,
    WorkflowCrewServiceImpl,
    WorkflowGroupServiceImpl,
    WorkflowCategoryServiceImpl,
    WorkflowUserServiceImpl,
    WorkflowProductServiceImpl,
    WorkflowActionServiceImpl,
    WorkflowRightServiceImpl,
    WorkflowStateServiceImpl,
    WorkflowRoleUserServiceImpl,
    WorkflowStepServiceImpl,
    WorkflowTagServiceImpl,
    WorkflowUploadPartServiceImpl,
    WorkflowTransitionServiceImpl,
    WorkflowCategoryGuard,
    WorkflowCrewGuard,
    WorkflowGroupGuard,
    WorkflowRoleGuard,
    WorkflowWorkflowGuard,
    {provide: LOCALE_ID, useValue: 'de'}
  ]
})

export class WorkflowGeneratorModule {

  static config: any = null;

  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }

  /*
  static forRoot(config: WorkflowGeneratorConfig): ModuleWithProviders {
    return {
      ngModule: WorkflowGeneratorModule,
      providers: [
        {
          provide: WorkflowGeneratorConfigProvider, useValue: config
        }
      ]
    };
  }
*/
  static getRoutes(): Routes {
    return [
      {
        path: '',
        component: CategoryListComponent
      },
      {
        path: 'roles',
        children: [
          {
            path: 'new',
            component: RoleDetailComponent
          },
          {
            path: ':id',
            component: RoleDetailComponent,
            resolve: {
              role: WorkflowRoleResolver
            }
          },
          {
            path: '',
            component: RoleListComponent
          }
        ],
        canActivate: [WorkflowRoleGuard]
      },
      {
        path: 'workflows',
        children: [
          {
            path: 'new',
            component: WorkflowDetailComponent
          },
          {
            path: ':id',
            component: WorkflowDetailComponent,
            resolve: {
              role: WorkflowResolver
            }
          },
          {
            path: '',
            component: WorkflowListComponent
          }
        ],
        canActivate: [WorkflowWorkflowGuard]
      },
      {
        path: 'categories',
        children: [
          {
            path: ':id',
            component: CategoryDetailComponent,
            resolve: {
              role: WorkflowCategoryResolver
            }
          },
          {
            path: '',
            component: CategoryListComponent
          }
        ],
        canActivate: [WorkflowCategoryGuard]
      },
      {
        path: 'crews',
        children: [
          {
            path: 'new',
            component: CrewDetailComponent
          },
          {
            path: ':id',
            component: CrewDetailComponent,
            resolve: {
              crew: WorkflowCrewResolver
            }
          },
          {
            path: '',
            component: CrewListComponent
          }
        ],
        canActivate: [WorkflowCrewGuard]
      },
      {
        path: 'groups',
        children: [
          {
            path: 'new',
            component: GroupDetailComponent
          },
          {
            path: ':id',
            component: GroupDetailComponent,
            resolve: {
              crew: WorkflowGroupResolver
            }
          },
          {
            path: '',
            component: GroupListComponent
          }
        ],
        canActivate: [WorkflowGroupGuard]
      }

    ];
  }
}

