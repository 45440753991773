import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogModule, DropdownModule, ProgressSpinnerModule, TooltipModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { DsvsSharedModule } from '../shared/shared.module';
import { TableComponent } from './components/table.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    ProgressSpinnerModule,
    DsvsSharedModule,
    DropdownModule,
    TooltipModule,
    ConfirmDialogModule
  ],
  declarations: [
    TableComponent
  ],
  exports: [
    TableComponent
  ]
})
export class DsvsTableModule {
}
