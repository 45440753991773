import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ClickOutsideModule} from 'ng-click-outside';
import {AutoCompleteModule, CalendarModule, DialogModule, DropdownModule, TooltipModule} from 'primeng/primeng';
import {DsvsSharedModule} from '../shared/shared.module';
import {InlineEditComponent} from './components/inline-edit.component';
import {InlineEditService} from './components/inline-edit.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    DsvsSharedModule,
    AutoCompleteModule,
    ClickOutsideModule,
    CalendarModule,
    DialogModule,
    TooltipModule
  ],
  declarations: [
    InlineEditComponent
  ],
  providers: [
    InlineEditService
  ],
  exports: [
    InlineEditComponent
  ]
})
export class DsvsInlineEditModule {
}
