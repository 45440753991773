import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableReadEntityService, DsvsSearchableReadEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { StateHalItem, StateHalPage, WorkflowState } from '@dsvs/workflow-generator-dto';
import { Observable } from 'rxjs';
import { MappedHalReadServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowStateService extends DsvsSearchableReadEntityService<WorkflowState> {
  getForWorkflowId(workflowId: string): Observable<StateHalPage>;
}

// @dynamic
@Injectable()
export class WorkflowStateServiceImpl
  extends MappedHalReadServiceImpl<WorkflowState, StateHalItem, StateHalPage>
  implements WorkflowStateService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'state', collection: 'states'},
      WorkflowGeneratorTypeMappingService.WorkflowState,
      typeResolver
    );
  }

  getForWorkflowId(workflowId: string): Observable<StateHalPage> {
    return super.search('', null, {name: 'workflow', data: {'workflowId': workflowId}});
  }

}

@Injectable()
export class WorkflowStatServiceMock
  extends DsvsSearchableReadEntityServiceMock<WorkflowState>
  implements WorkflowStateService {

  constructor() {
    super();

    this.data = [];

    for (let i = 1; i <= 100; i++) {
      this.data.push(
        <WorkflowState>{
          id: String(i),
          displayName: 'State ' + i,
          categories: [],
          forms: []
        }
      );
    }

  }

  getForWorkflowId(workflowId: string): Observable<StateHalPage> {
    return null;
  }

}
