import { NgModule } from '@angular/core';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    // {provide: WorkflowRoleServiceImpl, useClass: WorkflowRoleServiceMock},
    // {provide: WorkflowServiceImpl, useClass: WorkflowServiceMock},
    // {provide: WorkflowCrewServiceImpl, useClass: WorkflowCrewServiceMock},
    // {provide: WorkflowCategoryServiceImpl, useClass: WorkflowCategoryServiceMock},
    // {provide: WorkflowUserServiceImpl, useClass: WorkflowUserServiceMock},
    // {provide: WorkflowProductServiceImpl, useClass: WorkflowProductServiceMock},
    // {provide: WorkflowActionServiceImpl, useClass: WorkflowActionServiceMock},
    // {provide: WorkflowStateServiceImpl, useClass: WorkflowStatServiceMock}

  ]
})
export class WorkflowGeneratorMockServicesModule {

}
