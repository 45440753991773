import { InjectionToken } from '@angular/core';
import { HalClient } from './client.interface';

export interface HalConfiguration {
  rootUri: string;
}

export const HAL_CONFIGURATION_TOKEN = new InjectionToken<HalConfiguration>('HAL Client default config');

export interface HalClientFactory {
  getClient(path: string[]): HalClient;

  getClient(url: string): HalClient;
}

export const HAL_CLIENT_FACTORY_TOKEN = new InjectionToken<HalClientFactory>('HAL Client Factory');
