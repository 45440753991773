import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputErrorValidator } from '../../helper/input-error-validation.helper';

@Component({
  selector: 'dsvs-input-error-message',
  template: `
    <div
      class="ui-message ui-message-error ui-corner-all"
      *ngIf="errorMessage !== null">{{errorMessage}}
    </div>`
})
export class DsvsInputErrorComponent {
  @Input() control: FormControl;

  constructor() {
  }

  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return InputErrorValidator.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }

    return null;
  }
}
