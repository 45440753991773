import { Inject, Injectable } from '@angular/core';
import { TraversonService } from '@dsvs/traverson-ng';
import isArray from 'lodash/isArray';
import { HAL_CONFIGURATION_TOKEN, HalClientFactory, HalConfiguration } from './client-factory.interface';
import { HalClientImpl } from './client.impl';
import { HalClient } from './client.interface';

@Injectable({
    providedIn: 'root'
})
export class HalClientFactoryImpl implements HalClientFactory {
    constructor(
        @Inject(HAL_CONFIGURATION_TOKEN) private config: HalConfiguration,
        private clientFactory: TraversonService
    ) {

    }

    getClient(path: string | string[]): HalClient {
        if (isArray(path)) {
            let client = this.clientFactory
                .createClient(this.config.rootUri);

            client = client.follow.apply(client, path);

            return new HalClientImpl(client, this, <any>path);
        } else {
            const client = this.clientFactory
                .createClient(<string>path);
            return new HalClientImpl(client, this, []);
        }
    }
}
