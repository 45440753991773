import { SortEvent } from 'primeng/api';
import { defaultComparator } from '../../shared/helper/default-comparator.helper';
import { versionComparator } from '../../shared/helper/version-comparator.helper';

export function sortTableRows(event: SortEvent) {
  const data = event.data;
  if (event.field === 'version'
    || event.field === 'parentVersion'
    || event.field === 'releaseVersion'
  ) {
    data.sort((a, b) => versionComparator(a[event.field], b[event.field], event.order));
  } else {
    data.sort((a, b) => defaultComparator(a[event.field], b[event.field], event.order));
  }
}
