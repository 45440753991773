import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DsvsBreadcrumbService, DsvsTableColumn, DsvsToolbarItem, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {RoleHalPage, WorkflowRole} from '@dsvs/workflow-generator-dto';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import {WorkflowGeneratorConfigurationService} from '../../../../services/config/workflow-generator-configuration.service';
import {WorkflowPermissions} from '../../../../services/config/workflow-permissions.enum';
import {WorkflowRoleServiceImpl} from '../../../../services/data/workflow-role.service';
import {RoleDetailComponent} from '../role-detail/role-detail.component';

export enum RoleListActions {
  REFRESH = 'REFRESH', ADD = 'ADD', DELETE = 'DELETE', EDIT = 'EDIT'
}

@Component({
  selector: 'wfg-role-list',
  template: `<wfg-list
  [data]="data"
  [tableColumnOptions]="tableColumnOptions"
  (reloadData)="onReloadData($event)"
  (rowDetailClicked)="onRowDetailClicked($event)"
  (rowDeleteClicked)="onRowDeleteClicked($event)"
  (pageChanged)="onReloadData('', $event)"
  (sortChanged)="onReloadData('', $event)"
  (searchChanged)="onReloadData($event.searchTerm, $event)"
  [isLoading]="isLoading"
  [rowexpansionTemplate]="rowexpansionTemplate"
  [isDeletable]="isDeletable"
  title="Rollen"
  [toolbarItems]="initToolbarItems()"
>
  <ng-template #rowexpansionTemplate let-rowData="rowData" let-column="column">
    <div class="ui-g">
      <div class="ui-lg-2 ui-md-3 ui-g-12 col-label">
        Beschreibung
      </div>
      <div class="ui-lg-10 ui-md-9 ui-g-12 col-content">
        <dsvs-inline-edit type="text" [(value)]="rowData.data.description" [onSave]="saveInline"
                          propertyValue="description" [model]="rowData.data"></dsvs-inline-edit>
      </div>
    </div>
  </ng-template>

</wfg-list>

<p-dialog [header]="editRole ? 'Rolle Ã¤ndern' : 'Rolle erstellen'"
          [className]="'in-dialog-footer'"
          *ngIf="showEditModal"
          [(visible)]="showEditModal"
          width="800"
          height="auto"
          [modal]="true"
          [maximizable]="true"
          [dismissableMask]="true"
          [closable]="true">
  <wfg-role-detail
    [isInline]="true"
    [role]="editRole"
    (showEditModalEvent)="receiveShowEditModal($event)"
    #detailView>
  </wfg-role-detail>
</p-dialog>


<p-confirmDialog header="Confirmation" icon="" width="425" acceptLabel="Ja" rejectLabel="Nein"
                 responsive="true"></p-confirmDialog>

`,
  styles: [`.col-label{display:flex;align-items:center}`]
})
export class RoleListComponent implements OnInit {

  @ViewChild('detailView') detailView: RoleDetailComponent;

  tableColumnOptions: DsvsTableColumn[] = [];

  isLoading = false;
  showEditModal = false;
  data: RoleHalPage = null;
  editRole: any;
  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;
  isDeletable = false;

  receiveShowEditModal($event) {
    this.showEditModal = $event;
    this.onReloadData('');
  }

  constructor(private router: Router,
    public roleService: WorkflowRoleServiceImpl,
    private breadcrumbService: DsvsBreadcrumbService,
    private messageService: MessageService,
    private zone: NgZone,
    private configService: WorkflowGeneratorConfigurationService) {
  }

  ngOnInit() {

    this.isDeletable = this.configService.hasPermission(WorkflowPermissions.ROLE_DELETE);

    this.onReloadData('');

    this.tableColumnOptions = [
      {field: 'displayName', header: 'Name', sort: true, filter: '', hidden: false},
      {field: 'identifier', header: 'Key', sort: true, filter: '', hidden: false},
      {field: 'description', header: 'Beschreibung', sort: true, filter: '', hidden: false}
    ];

  }

  onReloadData(searchTerm: string, page?: Page) {
    this.isLoading = true;
    this.roleService.search(searchTerm, page).subscribe(result => {
      this.zone.run(() => {
        this.data = result;
        this.isLoading = false;
      });

    }, error2 => {
      console.error('error while isLoading roles - {}', error2);
    });
  }

  /********************************************
   * Toolbar
   *******************************************/

  /** Initialisierung der Toolbar items*/
  initToolbarItems(): DsvsToolbarItem[] {
    const tbItems: DsvsToolbarItem[] = [];

    if (this.configService.hasPermission(WorkflowPermissions.ROLE_CREATE)) {

      tbItems.push({
        id: RoleListActions.ADD,
        icon: 'add_box',
        disabled: false,
        tooltip: 'Rolle hinzufÃ¼gen',
        faicon: true,
        callback: () => {
          this.editRole = null;
          this.showEditModal = true;
        }
      });
    }
    return tbItems;
  }

  onRowDetailClicked(object: WorkflowRole) {
    // this.router.navigate(['/workflowgenerator/roles/' + object.id]);
    this.editRole = object;
    this.showEditModal = true;
  }

  onRowDeleteClicked(object) {
    this.roleService.delete(object.data).subscribe((result: any) => {

      if (result.statusCode && result.statusCode >= 400) {
        /*
        https://github.com/traverson/traverson-angular#how-http-status-code-are-handled

        In contrast to AngularJS' $http service, Traverson and traverson-angular do not interpret status codes outside of
        the 2xx range as an error condition. Only network problems (host not reachable, timeouts, etc.) lead to a rejection
        of the promise, that is, only those trigger the error callback. Completed HTTP requests, even those with status 4xx
        or 5xx are interpreted as a success and trigger the success callback. This applies only to the last request in a
        traversal, HTTP requests during the traversal that respond with 4xx/5xx are interpreted as an error (because the
        traversal can not continue).
         */

        switch (result.statusCode) {
          case 409:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: 'Die Rolle kann nicht gelÃ¶scht werden, da noch AbhÃ¤ngigkeiten bestehen.'
              });
            break;

          default:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: JSON.parse(result.error).message
              });
            break;
        }

      } else {

        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: '\"' + object.data.displayName + '\" wurde gelÃ¶scht'
        });
        this.onReloadData('');

      }

    }, error2 => {
      console.error(error2);
    });
  }

  saveInline = (value, component: InlineEditComponent): Observable<any> => {
    return this.roleService.update(component.model);
  };

  saveRole() {
    if (!this.editRole.id) {
      this.roleService.create(this.editRole).subscribe(result => {
        this.messageService.add({severity: 'success', summary: 'Erfolg', detail: 'Wert hinzugefÃ¼gt'});
      });
    } else {
      this.roleService.update(this.editRole).subscribe(result => {
        this.messageService.add({severity: 'success', summary: 'Erfolg', detail: 'Wert geÃ¤ndert'});
      });
    }
  }

}
