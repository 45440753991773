import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dsvs-showcase-layout',
  template: `<table style="width: 100%">
  <tr>
    <td>Name</td>
    <td>{{name}}</td>
  </tr>
  <tr>
    <td>Verantwortlicher</td>
    <td>{{author}}</td>
  </tr>
  <tr>
    <td>Beschreibung</td>
    <td>{{description}}</td>
  </tr>
</table>
<br/>
<ng-content></ng-content>
`,
  styles: [``]
})
export class ShowcaseLayoutComponent implements OnInit {

  @Input() author: String;
  @Input() name: String;
  @Input() description: String;

  constructor() {
  }

  ngOnInit() {
  }

}
