import {DsvsPanelComponent} from './components/dsvs-panel.component';
import {NgModule} from '@angular/core';

@NgModule({
  imports: [
  ],
  declarations: [
    DsvsPanelComponent
  ],
  exports: [
    DsvsPanelComponent
  ]
})
export class DsvsPanelModule {
}
