import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DsvsBreadcrumbService, DsvsTableColumn, DsvsToolbarItem} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {CrewHalPage, RoleHalItem, WorkflowCrew} from '@dsvs/workflow-generator-dto';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import {WorkflowGeneratorConfigurationService} from '../../../../services/config/workflow-generator-configuration.service';
import {WorkflowPermissions} from '../../../../services/config/workflow-permissions.enum';
import {WorkflowCrewServiceImpl} from '../../../../services/data/workflow-crew.service';
import {WorkflowRoleUserServiceImpl} from '../../../../services/data/workflow-role-users.service';
import {WorkflowRoleServiceImpl} from '../../../../services/data/workflow-role.service';
import {WorkflowUserServiceImpl} from '../../../../services/data/workflow-user.service';
import {CrewDetailComponent} from '../crew-detail/crew-detail.component';

export enum CrewListActions {
  ADD = 'ADD'
}

@Component({
  selector: 'wfg-crew-list',
  template: `<wfg-list
  [data]="data"
  [tableColumnOptions]="tableColumnOptions"
  (reloadData)="onReloadData($event)"
  (rowDetailClicked)="onRowDetailClicked($event)"
  (rowDeleteClicked)="onRowDeleteClicked($event)"
  (pageChanged)="reloadData('', $event)"
  (sortChanged)="reloadData('', $event)"
  (searchChanged)="reloadData($event.searchTerm, $event)"
  [isLoading]="isLoading"
  [isDeletable]="isDeletable"
  title="Ansprechpartner"
  [toolbarItems]="initToolbarItems()"
></wfg-list>

<p-dialog
  [header]="editCrew ? 'Ansprechpartner Ã¤ndern' : 'Ansprechpartner erstellen'"
  [className]="'in-dialog-footer'"
  *ngIf="showEditModal"
  [(visible)]="showEditModal"
  width="1200"
  height="800"
  [positionTop]="30"
  [modal]="true"
  [maximizable]="true"
  [dismissableMask]="true"
  [closable]="true"
>
  <wfg-crew-detail [crew]="editCrew" (showEditModalEvent)="receiveShowEditModal($event)"></wfg-crew-detail>
</p-dialog>
`,
  styles: [`.col-label{display:flex;align-items:center}.label{font-weight:700}`]
})
export class CrewListComponent implements OnInit {

  @ViewChild('detailView') detailView: CrewDetailComponent;

  tableColumnOptions: DsvsTableColumn[] = [];

  isLoading = false;
  data: CrewHalPage = null;
  editCrew: WorkflowCrew;
  showEditModal = false;
  roles: RoleHalItem[] = [];
  toolbarSubscription: Subscription;
  searchTerm: string;
  isDeletable = false;

  constructor(private router: Router,
              private crewService: WorkflowCrewServiceImpl,
              private roleService: WorkflowRoleServiceImpl,
              private roleUserService: WorkflowRoleUserServiceImpl,
              public userService: WorkflowUserServiceImpl,
              private breadcrumbService: DsvsBreadcrumbService,
              private messageService: MessageService,
              private configService: WorkflowGeneratorConfigurationService) {
  }

  ngOnInit() {

    this.isDeletable = this.configService.hasPermission(WorkflowPermissions.CREW_DELETE);

    this.reloadData('');

    this.tableColumnOptions = [
      {field: 'displayName', header: 'Name', sort: true, filter: '', hidden: false}
    ];

  }

  onReloadData($event: any) {
    this.searchTerm = $event.searchTerm;
    delete $event.searchTerm;

    this.reloadData(this.searchTerm, <Page>{
      size: $event.pagesize,
      number: $event.page
    });
  }

  reloadData(searchTerm: string, page?: Page) {
    this.isLoading = true;

    this.roleService
    .search(searchTerm, page)
    .subscribe(result => {
      this.roles = result.content;
    }, error2 => {
      console.error(error2);
    });

    this.crewService
    .search(searchTerm, page)
    .subscribe(result => {
      this.data = result;
      this.isLoading = false;
    }, error2 => {
      console.error(error2);
    });
  }

  onRowDetailClicked(object) {
    this.editCrew = object;
    this.showEditModal = true;
  }

  onRowDeleteClicked(object) {
    this.crewService.delete(object.data).subscribe((result: any) => {

      if (result.statusCode && result.statusCode >= 400) {
        /*
        https://github.com/traverson/traverson-angular#how-http-status-code-are-handled

        In contrast to AngularJS' $http service, Traverson and traverson-angular do not interpret status codes outside of
        the 2xx range as an error condition. Only network problems (host not reachable, timeouts, etc.) lead to a rejection
        of the promise, that is, only those trigger the error callback. Completed HTTP requests, even those with status 4xx
        or 5xx are interpreted as a success and trigger the success callback. This applies only to the last request in a
        traversal, HTTP requests during the traversal that respond with 4xx/5xx are interpreted as an error (because the
        traversal can not continue).
         */

        switch (result.statusCode) {
          case 409:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: 'Die Crew kann nicht gelÃ¶scht werden, da noch AbhÃ¤ngigkeiten bestehen.'
              });
            break;

          default:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: JSON.parse(result.error).message
              });
            break;
        }

      } else {

        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: '\"' + object.data.displayName + '\" wurde gelÃ¶scht'
        });
        this.reloadData(this.searchTerm);

      }

    }, error2 => {
      console.error(error2);
      this.messageService.add(
        {
          severity: 'error',
          summary: 'Fehler',
          detail: error2.message
        });
    });
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;
    this.reloadData(this.searchTerm);
  }

  /********************************************
   * Toolbar
   *******************************************/

  /** Initialisierung der Toolbar items*/
  initToolbarItems(): DsvsToolbarItem[] {
    const tbItems: DsvsToolbarItem[] = [];

    if (this.configService.hasPermission(WorkflowPermissions.CREW_CREATE)) {

      tbItems.push({
        id: CrewListActions.ADD,
        icon: 'add_box',
        disabled: false,
        tooltip: 'Crew hinzufÃ¼gen',
        faicon: true,
        callback: () => {
          this.editCrew = null;
          this.showEditModal = true;
        }
      });
    }

    return tbItems;
  }

  /********************************************
   * Data
   *******************************************/

  fetchUsers = (): Observable<any> => {
    return this.userService.search('');
  };

  fetchRoles = ($event): Observable<any> => {
    return this.roleService.search($event.query);
  };

  saveUsers = (value, component): Observable<any> => {
    return component.model.users.add(component.value);
  };

  saveRoles = (value, component): Observable<any> => {
    component.model.data.roleId = component.value.id;
    return this.roleUserService.update(component.model.data);
  };

}
