import { Injectable } from '@angular/core';
import { DsvsAuthorizationService } from './authorization.service';

export type PermissionOperator = 'AND' | 'OR';

@Injectable({
  providedIn: 'root'
})
export class DsvsPermissionService {

  constructor(private authService: DsvsAuthorizationService) {
  }

  public hasPermission(permissions: string[]): boolean {
    return this.hasPermissionAND(permissions);
  }

  public hasPermissionAND(permissions: string[]): boolean {
    return this.hasPermissionWithOperator(permissions, 'AND');
  }

  public hasPermissionOR(permissions: string[]): boolean {
    return this.hasPermissionWithOperator(permissions, 'OR');
  }

  public hasPermissionWithOperator(permissions: string[], operator: PermissionOperator = 'AND'): boolean {

    if (!(permissions instanceof Array)) {
      permissions = [permissions];
    }

    let hasPermission = false;

    for (const checkPermission of permissions) {

      if (this.authService.hasRight(checkPermission)) {
        hasPermission = true;

        if (operator === 'OR') {
          break;
        }
      } else {

        hasPermission = false;
        if (operator === 'AND') {
          break;
        }
      }
    }
    return hasPermission;

  }

}
