export class InputErrorValidator {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      'required': 'Dieses Feld wird benÃ¶tigt.',
      'minlength': `Minimale LÃ¤nge: ${validatorValue.requiredLength}`,
      'maxlength': `Maximale LÃ¤nge: ${validatorValue.requiredLength}`,
      'pattern': `Darf nur Zahlen enthalten.`,
      'email': 'Keine gÃ¼ltige Emailadresse.',
    };
    return config[validatorName];
  }
}
