import { Component, OnInit } from '@angular/core';
import { DsvsAuthorizationService } from '../../../authentication/services/authorization.service';
import { DsvsUserData } from '../../../authentication/interfaces/user-data';

@Component({
  selector: 'dsvs-user-info',
  template: `<div class="ui-g">
  <div class="ui-lg-6 ui-md-8 ui-sm-10 ui-lg-offset-3 ui-md-offset-2 ui-sm-offset-1">
    <div class="card">
      <p-panel header="Ihre persÃ¶nlichen Daten">
        <div class="text-large">
          <span>{{userdata.result.title}}</span><br>
          <span>{{userdata.result.firstname}}&nbsp;{{userdata.result.lastname}}</span><br>
          <span *ngIf="userdata.result.institute.name">{{userdata.result.institute.name}}</span><br>
          <div class="div-h-center">
            <i class="material-icons">email</i>&nbsp;<span>{{userdata.result.email}}</span><br>
          </div>
          <div class="div-h-center">
            <i class="material-icons">phone</i>&nbsp;<span>{{userdata.result.phone}}</span><br>
          </div>
        </div>
        <p-footer>
          Ãnderungen an Ihren persÃ¶nlichen Daten mÃ¼ssen zentral im Anwendungsportal durchgefÃ¼hrt werden.
        </p-footer>
      </p-panel>
    </div>
  </div>
</div>
`,
  styles: [``]
})
export class DsvsUserInfoComponent implements OnInit {

  userdata: DsvsUserData;

  constructor(private authService: DsvsAuthorizationService) { }

  ngOnInit() {
  }

}
