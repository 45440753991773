import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient, Page } from '@dsvs/hal-client';
import { ProductHalItem, ProductHalPage, WorkflowProduct } from '@dsvs/workflow-generator-dto';
import { Observable } from 'rxjs/Observable';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowProductService extends DsvsSearchableCrudEntityService<WorkflowProduct> {
  getForDataId(formId: string): Observable<ProductHalPage>;

  searchProcessable(searchTerm: string, options?: Page): Observable<ProductHalPage>;

}

// @dynamic
@Injectable()
export class WorkflowProductServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowProduct, ProductHalItem, ProductHalPage>
  implements WorkflowProductService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'product', collection: 'products'},
      WorkflowGeneratorTypeMappingService.WorkflowProduct,
      typeResolver
    );
  }

  searchProcessable(searchTerm: string, options?: Page): Observable<ProductHalPage> {
    return super.search(searchTerm, options, {name: 'processable'});
  }

  getForDataId(dataId: string): Observable<ProductHalPage> {
    return super.search('', null, {name: 'data', data: {'dataId': dataId}});
  }
}

@Injectable()
export class WorkflowProductServiceMock
  extends DsvsSearchableCrudEntityServiceMock<WorkflowProduct>
  implements WorkflowProductService {

  constructor() {
    super();

    this.data = [];

    for (let i = 1; i <= 100; i++) {
      this.data.push(
        <WorkflowProduct>{
          id: String(i),
          displayName: 'Form ' + i
        }
      );
    }

  }

  searchProcessable(searchTerm: string, options?: Page): Observable<ProductHalPage> {
    return null;
  }

  getForDataId(dataId: string): Observable<ProductHalPage> {
    return null;
  }
}
