import {Component, OnInit} from '@angular/core';
import {DsvsBreadcrumbService, DsvsTableColumn, DsvsToolbarItem} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {Workflow, WorkflowHalPage} from '@dsvs/workflow-generator-dto';
import {MessageService} from 'primeng/components/common/messageservice';
import {Subscription} from 'rxjs/index';
import {WorkflowGeneratorConfigurationService} from '../../../../services/config/workflow-generator-configuration.service';
import {WorkflowPermissions} from '../../../../services/config/workflow-permissions.enum';
import {WorkflowServiceImpl} from '../../../../services/data/workflow.service';
import {ConfirmationService} from 'primeng/api';
import {WorkflowStepServiceImpl} from '../../../../services/data/workflow-step.service';

export enum WorkflowListActions {
  ADD = 'ADD'
}

@Component({
  selector: 'wfg-workflow-list',
  template: `<wfg-list
  [data]="data"
  [tableColumnOptions]="tableColumnOptions"
  (reloadData)="onReloadData($event)"
  (rowDetailClicked)="onRowDetailClicked($event)"
  (rowDeleteClicked)="onRowDeleteClicked($event)"
  [isLoading]="isLoading"
  (pageChanged)="onReloadData('', $event)"
  (sortChanged)="onReloadData('', $event)"
  (searchChanged)="onReloadData($event.searchTerm, $event)"
  [rowexpansionTemplate]="rowexpansionTemplate"
  [isDeletable]="isDeletable"
  title="Workflows"
  [toolbarItems]="initToolbarItems()"
>
  <ng-template #rowexpansionTemplate let-rowData="rowData" let-column="column">
    <div class="ui-g">
      <div class="ui-lg-2 ui-md-3 ui-g-12 col-label">Anzahl Steps</div>
      <div class="ui-lg-10 ui-md-9 ui-g-12 col-content">
        <ng-container *ngIf="!rowData.steps.sync_loading && rowData.steps.sync.content">
          {{rowData.steps.sync.content.length}}
        </ng-container>
      </div>
    </div>
  </ng-template>

</wfg-list>

<!--Workaround: worklow cannot be closed, to avoid automatic creation on open-->

<p-dialog
  [className]="'in-dialog-footer'"
  [header]="editWorkflow ? 'Workflow Ã¤ndern' : 'Workflow erstellen'"
  [(visible)]="showEditModal"
  width="1400"
  height="600"
  [positionTop]="30"
  [modal]="true"
  [closable]="true"
  [maximizable]="true"
  (visibleChange)="deleteOrSaveNewWorfklow()"
  [dismissableMask]="true"
  [resizable]="true"
>
  <wfg-workflow-detail
    *ngIf="showEditModal"
    [workflow]="editWorkflow"
    (closeClicked)="resetEditWorkflow()"
    (setEditWorkflow)="setDeleteWorkflowFromDetail($event)"
  ></wfg-workflow-detail>
</p-dialog>

<p-confirmDialog #cd header="Speichern" appendTo="body" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="fas fas-trash" label="Verwerfen" (click)="cd.reject()"></button>
    <button type="button" pButton icon="fa ui-icon-save" label="Speichern" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
`,
  styles: [``]
})
export class WorkflowListComponent implements OnInit {

  isLoading = false;
  showEditModal = false;

  tableColumnOptions: DsvsTableColumn[] = [];

  editWorkflow: Workflow;
  deleteWorkflow: any;
  data: WorkflowHalPage = null;
  toolbarSubscription: Subscription;
  isDeletable = false;

  constructor(private workflowService: WorkflowServiceImpl,
              private workflowStepService: WorkflowStepServiceImpl,
              private breadcrumbService: DsvsBreadcrumbService,
              private messageService: MessageService,
              private configService: WorkflowGeneratorConfigurationService,
              private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit() {

    this.isDeletable = this.configService.hasPermission(WorkflowPermissions.WORKFLOW_DELETE);

    this.onReloadData('');

    this.tableColumnOptions = [
      {field: 'displayName', header: 'Name', sort: true},
      {field: 'type', header: 'Typ', sort: true, format: 'WORKFLOW_TYPE'}
    ];

  }

  onReloadData(searchTerm: string, page?: Page) {
    this.isLoading = true;
    this.workflowService.search(searchTerm, page).subscribe(result => {
      this.data = result;
      this.isLoading = false;
    }, error2 => {
      console.error(error2);
    });
  }

  onRowDetailClicked(object) {
    this.editWorkflow = object;
    this.showEditModal = true;
  }

  onRowDeleteClicked(object) {

    this.workflowService.delete(object.data).subscribe((result: any) => {

      if (result.statusCode && result.statusCode >= 400) {
        /*
        https://github.com/traverson/traverson-angular#how-http-status-code-are-handled

        In contrast to AngularJS' $http service, Traverson and traverson-angular do not interpret status codes outside of
        the 2xx range as an error condition. Only network problems (host not reachable, timeouts, etc.) lead to a rejection
        of the promise, that is, only those trigger the error callback. Completed HTTP requests, even those with status 4xx
        or 5xx are interpreted as a success and trigger the success callback. This applies only to the last request in a
        traversal, HTTP requests during the traversal that respond with 4xx/5xx are interpreted as an error (because the
        traversal can not continue).
         */

        switch (result.statusCode) {
          case 409:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: 'Der Workflow kann nicht gelÃ¶scht werden, da noch AbhÃ¤ngigkeiten bestehen.'
              });
            break;

          default:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: JSON.parse(result.error).message
              });
            break;
        }

      } else {

        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: '\"' + object.data.displayName + '\" wurde gelÃ¶scht.'
        });

        this.onReloadData('');

      }

    }, error2 => {

      this.messageService.add({
        severity: 'error',
        summary: error2.data.logref,
        detail: error2.data.message
      });
      console.error(error2);
    });

  }

  resetEditWorkflow() {
    this.showEditModal = false;
    this.editWorkflow = undefined;
    this.onReloadData('');
  }

  setDeleteWorkflowFromDetail(workflow: any) {
    this.deleteWorkflow = workflow;
  }

  deleteOrSaveNewWorfklow() {
    if (this.deleteWorkflow && !this.editWorkflow) {

      this.confirmationService.confirm({
        message: 'MÃ¶chtest du den neuen Workflow speichern?',
        header: 'Speichern',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.workflowService.create(this.deleteWorkflow.data).subscribe(() => {
            this.resetEditWorkflow();
          });
        },
        reject: () => {
          this.deleteWorkflow.steps.async.subscribe(result => {
            for (let i = 0; i < result.content.length; i++) {
              if (i === result.content.length - 1) {
                this.workflowStepService.delete(result.content[i].data).subscribe(() => {
                  this.workflowService.delete(this.deleteWorkflow.data).subscribe(() => {
                    this.resetEditWorkflow();
                  });
                }, error => {
                  console.error(error);
                });
              } else {
                this.workflowStepService.delete(result.content[i].data);
              }
            }
          });
        }
      });
    } else {
      this.resetEditWorkflow();
    }

  }

  /********************************************
   * Toolbar
   *******************************************/

  /** Initialisierung der Toolbar items*/
  initToolbarItems(): DsvsToolbarItem[] {
    const tbItems: DsvsToolbarItem[] = [];

    if (this.configService.hasPermission(WorkflowPermissions.WORKFLOW_CREATE)) {

      tbItems.push({
        id: WorkflowListActions.ADD,
        icon: 'add_box',
        disabled: false,
        tooltip: 'Workflow hinzufÃ¼gen',
        faicon: true,
        callback: () => {
          this.showEditModal = true;
        }
      });
    }
    return tbItems;
  }

}
