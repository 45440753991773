import { NgModule } from '@angular/core';

import { TraversonNgAdapter } from './traverson.adapter';
import { TraversonService } from './traverson.service';

@NgModule({
    imports: [],
    declarations: [],
    providers: [
        TraversonService,
        TraversonNgAdapter
    ],
    exports: []
})
export class TraversonNgModule {
}
