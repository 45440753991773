import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { StepHalItem, StepHalPage, WorkflowStep } from '@dsvs/workflow-generator-dto';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowStepService extends DsvsSearchableCrudEntityService<WorkflowStep> {

}

// @dynamic
@Injectable()
export class WorkflowStepServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowStep, StepHalItem, StepHalPage>
  implements WorkflowStepService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'step', collection: 'steps'},
      WorkflowGeneratorTypeMappingService.WorkflowStep,
      typeResolver
    );
  }
}

@Injectable()
export class WorkflowStepServiceMock extends DsvsSearchableCrudEntityServiceMock<WorkflowStep> implements WorkflowStepService {
  constructor() {
    super();

    this.data = [];

    for (let i = 1; i <= 100; i++) {
      this.data.push(
        <WorkflowStep>{
          id: String(i),
          displayName: 'Workflowstep ' + i
        }
      );
    }
  }
}
