import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableReadEntityService, DsvsSearchableReadEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { UserHalItem, UserHalPage, WorkflowUser } from '@dsvs/workflow-generator-dto';
import { MappedHalReadServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowUserService extends DsvsSearchableReadEntityService<WorkflowUser> {

}

// @dynamic
@Injectable()
export class WorkflowUserServiceImpl
  extends MappedHalReadServiceImpl<WorkflowUser, UserHalItem, UserHalPage>
  implements WorkflowUserService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'user', collection: 'users'},
      WorkflowGeneratorTypeMappingService.WorkflowUser,
      typeResolver
    );
  }
}

@Injectable()
export class WorkflowUserServiceMock extends DsvsSearchableReadEntityServiceMock<WorkflowUser> implements WorkflowUserService {

  constructor() {
    super();

    this.data = [
      {
        id: '1',
        displayName: 'Steffen Strobel'
      },
      {
        id: '2',
        displayName: 'Miguel Campos'
      },
      {
        id: '3',
        displayName: 'Martin MÃ¼ller'
      },
      {
        id: '4',
        displayName: 'Matthias Klingel'
      },
      {
        id: '5',
        displayName: 'Ralf GÃ¼nther'
      },
      {
        id: '6',
        displayName: 'Elias Waschin-Scholvin'
      },
      {
        id: '7',
        displayName: 'Niels Haar'
      },
      {
        id: '8',
        displayName: 'Joschka Burgmann'
      },
      {
        id: '9',
        displayName: 'Remi Tessier'
      }

    ];
  }

}
