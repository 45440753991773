import { Inject, Injectable } from '@angular/core';
import { DsvsItemResult, DsvsPage, DsvsPagedResult, DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { ContextHalItem, ContextHalPage, WorkflowContext, WorkflowState } from '@dsvs/workflow-generator-dto';
import { Observable } from 'rxjs/Observable';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowTypeMappingService } from '../config/workflow-type-mapping.service';

export interface WorkflowContextService extends DsvsSearchableCrudEntityService<WorkflowContext> {

  getStatus(id): Observable<WorkflowState[]>;
}

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class WorkflowContextServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowContext, ContextHalItem, ContextHalPage>
  implements WorkflowContextService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'context', collection: 'contexts'},
      WorkflowTypeMappingService.WorkflowContext,
      typeResolver
    );
  }

  getStatus(id): Observable<WorkflowState[]> {
    return Observable.of(null);
  }
}

@Injectable({
  providedIn: 'root'
})
export class WorkflowContextServiceMock implements WorkflowContextService {

  constructor() {

  }

  getById(id: string): Observable<DsvsItemResult<WorkflowContext>> {
    alert('Dont use Mock-Service! Implement real connection!');
    return null;

  }

  getStatus(id): Observable<WorkflowState[]> {
    alert('Dont use Mock-Service! Implement real connection!');
    return null;
  }

  create(role: WorkflowContext): Observable<DsvsItemResult<WorkflowContext>> {
    return undefined;
  }

  delete(role: WorkflowContext): Observable<never> {
    return undefined;
  }

  search(searchTerm: string, options?: DsvsPage): Observable<DsvsPagedResult<WorkflowContext>> {
    return undefined;
  }

  update(role: WorkflowContext): Observable<DsvsItemResult<WorkflowContext>> {
    return undefined;
  }

}
