import { TemplateParameters } from '@dsvs/traverson-ng';
import forOwn from 'lodash/forOwn';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import { HalClientFactory } from '../client-factory.interface';
import { HalClient, HalItemConstructor, HalPageConstructor } from '../client.interface';
import { HalItem } from './item.interface';
import { HalLinkImpl } from './link.impl';
import { HalLink } from './link.interface';
import { HalPage } from './page.interface';

export class HalItemImpl<T> implements HalItem<T> {
    protected _halLinks: any;
    protected _halEmbedded: any;
    protected links: Map<string, Array<HalLink>>;

    constructor(public readonly data: T,
                protected readonly client: HalClient,
                protected readonly factory: HalClientFactory) {
        this.extractHalData();
        this.getHalLinks();
        if (!this.client) {
            this.client = factory.getClient(this.getLink('self').getHref());
        }
    }

    reload(): void {
        this.client.resource('self').subscribe(
            res => {
                const newResource = <any>this;
                const result = (<any>res);
                newResource.data = result.data;
                newResource._halLink = result._halLinks;
                newResource._halEmbedded = result._halEmbedded;
                newResource.links = result.links;
                newResource.client = result.client;
                newResource.factory = result.factory;
            }
        );
    }

    getData(): T {
        return this.data;
    }

    getAllLinks(): Map<string, Array<HalLink>> {
        return this.getHalLinks();
    }

    getLink(rel: string): HalLink {
        const relArray = this.getHalLinks()[rel];
        if (!relArray || relArray.length !== 1) {
            throw new Error('HalItemImpl#getLink: Link ' + rel + ' is not present');
        }
        return relArray[0];
    }

    getLinks(rel: string): Array<HalLink> {
        const relArray = this.getHalLinks()[rel];
        return relArray || [];
    }

    getEmbedded<Y>(rel: string): Array<HalItem<Y>> {
        return this.getTypedEmbedded<Y, HalItem<Y>>(<any>HalItemImpl, rel);
    }

    getTypedEmbedded<Y, V extends HalItem<Y>>(itemConstructor: HalItemConstructor<Y, V>, rel: string): Array<V> {
        const embeddedItems: any[] = this._halEmbedded ? this._halEmbedded[rel] : [];
        const halItems: Array<V> = [];

        if (embeddedItems === undefined) {
            console.error(
                'Could not find item \'' + rel + '\'. Did you mean one of those: \'' + Object.keys(this._halEmbedded) + '\' ?'
            );
        } else {
            for (let i = 0; i < embeddedItems.length; i++) {
                halItems.push(new itemConstructor(embeddedItems[i], this.client.cloneAsEmbeddable(embeddedItems[i]), this.factory));
            }
        }

        return halItems;
    }

    getTypedEmbeddedPage<Y, Z extends HalItem<Y>, X extends HalPage<Y, Z>>(pageConstructor: HalPageConstructor<Y, Z, X>,
                                                                           itemConstructor: HalItemConstructor<Y, Z>,
                                                                           rel: string,
                                                                           embeddedName?: string): X {
        const embeddedItem: any = this._halEmbedded ? this._halEmbedded[rel] : {};
        let halItem: X = null;

        if (embeddedItem === undefined) {
            console.error(
                'Could not find item \'' + rel + '\'. Did you mean one of those: \'' + Object.keys(this._halEmbedded) + '\' ?'
            );
        } else {
            halItem = new pageConstructor(
                embeddedItem,
                this.client.cloneAsEmbeddable(embeddedItem),
                this.factory,
                embeddedName,
                itemConstructor
            );
        }

        return halItem;
    }

    getRelation<Y>(rel: string): Observable<HalItem<Y>> {
        return this.client.resource<Y>(rel);
    }

    getTypedRelation<Y, Z extends HalItem<Y>>(constructor: HalItemConstructor<Y, Z>, rel: string): Observable<Z> {
        return this.client.typedResource(constructor, rel, null);
    }

    getRelations<Y>(rel: string): Observable<HalPage<Y, HalItem<Y>>> {
        return this.client.resources<Y>(rel);
    }

    getTypedRelations<Y, Z extends HalItem<Y>, X extends HalPage<Y, Z>>(pageConstructor: HalPageConstructor<Y, Z, X>,
                                                                        itemConstructor: HalItemConstructor<Y, Z>,
                                                                        rel: string,
                                                                        templateVars?: TemplateParameters,
                                                                        embeddedName?: string): Observable<X> {
        return this.client.typedResources(pageConstructor, itemConstructor, rel, templateVars, embeddedName);
    }

    save(data?: T): Observable<HalItem<T>> {
        if (isNil(data)) {
            data = this.data;
        }
        return this.client.typedPut((<any>this.constructor), data);
    }

    addRelation(rel: string, varNameId: string, id: string): Observable<any> {
        const vars: TemplateParameters = <any>{};
        vars[varNameId] = id;
        return this.client.post(null, rel, vars);
    }

    deleteRelation(rel: string, varNameId: string, id: string): Observable<any> {
        const vars: TemplateParameters = <any>{};
        vars[varNameId] = id;
        return this.client.delete(rel, vars);
    }

    updateRelations(rel: string, ids: String[]): Observable<any> {
        const vars: TemplateParameters = <any>{};
        return this.client.post({relationIds: ids}, rel, vars);
    }

    private extractHalData() {
        // links
        this._halLinks = (<any>this.data)._links;
        delete (<any>this.data)._links;

        // embedded
        this._halEmbedded = (<any>this.data)._embedded;
        delete (<any>this.data)._embedded;
    }

    private getHalLinks(): Map<string, Array<HalLink>> {
        if (!this.links) {
            const links: any = {};

            if (!this._halLinks) {
                throw new Error('no hal-links found, this should never happen!');
            }

            forOwn(this._halLinks, (val, key) => {
                const linkEntries = [];

                if (isArray(val)) {
                    val.forEach(valEntry => {
                        linkEntries.push(new HalLinkImpl(valEntry));
                    });
                } else {
                    linkEntries.push(new HalLinkImpl(val));
                }

                links[key] = linkEntries;
            });

            this.links = links;
        }

        return this.links;
    }

}
