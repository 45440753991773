import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {FileUploadModule, ProgressSpinnerModule, SplitButtonModule, TooltipModule} from 'primeng/primeng';
import {FileDownloadComponent} from './components/file-download/file-download.component';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {IconButtonComponent} from './components/icon-button/icon-button.component';
import {DsvsInputErrorComponent} from './components/input-error/input-error.component';
import {StatusComponent} from './components/status/status.component';
import {TagBadgeComponent} from './components/tag-badge/tag-badge.component';
import {DsvsBoolToYesNoPipe} from './pipes/bool-to-yes-no.pipe';
import {DsvsShortenerPipe} from './pipes/shortener.pipe';
import {UserBadgeComponent} from './components/user-badge/user-badge.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FileUploadModule,
    SplitButtonModule,
    ProgressSpinnerModule,
    TooltipModule
  ],
  declarations: [
    DsvsBoolToYesNoPipe,
    DsvsShortenerPipe,
    StatusComponent,
    TagBadgeComponent,
    UserBadgeComponent,
    IconButtonComponent,
    FileDownloadComponent,
    FileUploadComponent,
    DsvsInputErrorComponent
  ],
  exports: [
    DsvsBoolToYesNoPipe,
    DsvsShortenerPipe,
    StatusComponent,
    TagBadgeComponent,
    UserBadgeComponent,
    IconButtonComponent,
    FileDownloadComponent,
    FileUploadComponent,
    DsvsInputErrorComponent
  ]
})
export class DsvsSharedModule {
}
