import { HalLink } from './link.interface';

export class HalLinkImpl implements HalLink {
  constructor(
    private linkData: any
  ) {
  }

  getHref(): string {
    return this.linkData.href;
  }

  getTemplated(): boolean {
    return this.linkData.templated;
  }

}
