import { Pipe, PipeTransform } from '@angular/core';
import { WorkflowType } from '@dsvs/workflow-generator-dto';

@Pipe({
  name: 'workflowtype'
})
export class WorkflowtypePipe implements PipeTransform {

  transform(value: WorkflowType): string {
    switch (value) {
      case 'CREATE':
        return 'Neuerstellung';
      case 'UPDATE':
        return 'Aktualisierung';
      case 'DELETE':
        return 'LÃ¶schung';
    }
  }
}
