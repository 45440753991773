import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/delay';
import { Observable } from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { DsvsItemResult } from '../interfaces/ItemResult';
import {
  DsvsSearchableReadEntityService,
  DsvsSearchableReadEntityServiceImpl,
  DsvsSearchableReadEntityServiceMock
} from './searchable-read-entity.service';

export interface DsvsSearchableCrudEntityService<T> extends DsvsSearchableReadEntityService<T> {

  create(role: T): Observable<DsvsItemResult<T>>;

  update(role: T): Observable<DsvsItemResult<T>>;

  delete(role: T): Observable<never>;

}

@Injectable()
export class DsvsSearchableCrudEntityServiceImpl<T> extends DsvsSearchableReadEntityServiceImpl<T> implements DsvsSearchableCrudEntityService<T> {

  constructor() {
    super();
  }

  create(role: T): Observable<DsvsItemResult<T>> {
    return null;
  }

  update(role: T): Observable<DsvsItemResult<T>> {
    return null;
  }

  delete(object: T): Observable<never> {
    return null;
  }

}

@Injectable()
export class DsvsSearchableCrudEntityServiceMock<T> extends DsvsSearchableReadEntityServiceMock<T> implements DsvsSearchableCrudEntityService<T> {
  constructor() {
    super();
  }

  create(object: T): Observable<DsvsItemResult<T>> {
    object['id'] = String(this.data.length + 1);
    this.data.push(object);
    // console.log(object);
    return Observable.of(<DsvsItemResult<T>>{data: object});
  }

  update(object: T): Observable<DsvsItemResult<T>> {
    return Observable.of(<DsvsItemResult<T>>{data: object});
  }

  delete(object: T): Observable<never> {
    const index = this.data.indexOf(object, 0);
    if (index > -1) {
      this.data.splice(index, 1);
      return EMPTY;
    } else {
      return ErrorObservable.create('error :)').delay(500);
    }
  }
}
