import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsvsLandingPageComponent } from './components/landing-page/landing-page.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DsvsLandingPageComponent,
    SafeHtmlPipe
  ],
  exports: [
    DsvsLandingPageComponent,
    SafeHtmlPipe
  ]
})
export class DsvsLandingPageModule { }
