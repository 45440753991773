import {Inject, Injectable} from '@angular/core';
import {DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock} from '@dsvs/dsvs-shared-ui-lib';
import {HalClient} from '@dsvs/hal-client';
import {HalRelation, MappedHalCrudServiceImpl, WG_V1_CLIENT_TOKEN} from '../../../hal';
import {WorkflowGeneratorTypeMappingService} from '../config/workflow-generator-type-mapping.service';
import {GroupHalItem, GroupHalPage, WorkflowGroup} from '@dsvs/workflow-generator-dto';

export interface WorkflowGroupService extends DsvsSearchableCrudEntityService<WorkflowGroup> {

}

// @dynamic
@Injectable()
export class WorkflowGroupServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowGroup, GroupHalItem, GroupHalPage>
  implements WorkflowGroupService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'group', collection: 'groups'},
      WorkflowGeneratorTypeMappingService.WorkflowGroup,
      typeResolver
    );
  }
}

@Injectable()
export class WorkflowGroupServiceMock
  extends DsvsSearchableCrudEntityServiceMock<WorkflowGroup>
  implements WorkflowGroupService {

  constructor() {
    super();

    this.data = [
      {
        id: '111'
      }];

  }
}
