import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Workflow } from '@dsvs/workflow-generator-dto';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { WorkflowServiceImpl } from '../services/data/workflow.service';

@Injectable()
export class WorkflowResolver implements Resolve<Workflow> {

  constructor(private workflowService: WorkflowServiceImpl) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Workflow> | Promise<Workflow> | Workflow {
    const id = route.params.id;
    if (id) {
      return this.workflowService.getById(id).map(result => {
        return result.data;
      });
    }

    return Observable.of(null);
  }
}
