import { Component, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DsvsSharedUiLibConfigService } from '../../../dsvs-shared-ui-lib-config.service';
import { DsvsToolbarItem } from '../../interfaces/toolbar-item';
import { DsvsBreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'dsvs-breadcrumb',
  template: `<div class="layout-breadcrumb">
  <ul>
    <li *ngIf="isHome">
      <i class="material-icons">home</i>
    </li>
    <li *ngIf="!isHome">
      <a routerLink="">
        <i class="material-icons">home</i>
      </a>
    </li>
    <li>/</li>
    <ng-template ngFor let-item let-last="last" [ngForOf]="menuItems">
      <li>
        <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
        <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
      </li>
      <li *ngIf="!last">/</li>
    </ng-template>
  </ul>

  <div class="layout-breadcrumb-options">
    <a routerLink="{{configService.config.routing.logout}}" pTooltip="Abmelden" tooltipPosition="bottom">
      <i class="material-icons">power_settings_new</i>
    </a>
  </div>

  <div *ngIf="showToolbar" class="layout-breadcrumb-options toolbar-menu">
    <span>Werkzeuge:</span>
    <ng-container *ngFor="let tbItem of toolbarItems">
      <a *ngIf="!tbItem.submenu" [dsvsToolbarTool]="tbItem" (toolbarItemClick)="toolClicked($event)" [pTooltip]="tbItem.tooltip"
         tooltipPosition="bottom"
         [ngClass]="{'tool-disabled': tbItem.disabled, 'tool-enabled': !tbItem.disabled, 'fas-stack': tbItem.iconstack}">
        <div *ngIf="tbItem.iconstack; then multi else single"></div>
        <ng-template #single>
          <i *ngIf="tbItem.faicon" [class]="tbItem.icon"></i>
          <i *ngIf="!tbItem.faicon" class="material-icons">{{ tbItem.icon }}</i>
        </ng-template>
        <ng-template #multi>
          <i [class]="tbItem.iconstack[0]"></i>
          <i [class]="tbItem.iconstack[1]"></i>
        </ng-template>
      </a>
      <ng-container *ngIf="tbItem.submenu">
        <a [dsvsToolbarTool]="tbItem" (toolbarItemClick)="toggleToolbarSubmenu($event)" [pTooltip]="tbItem.tooltip"
           tooltipPosition="bottom"
           [ngClass]="{'tool-disabled': tbItem.disabled, 'tool-enabled': !tbItem.disabled, 'fas-stack': tbItem.iconstack}">
          <div *ngIf="tbItem.iconstack; then multi else single"></div>
          <ng-template #single>
            <i *ngIf="tbItem.faicon" [class]="tbItem.icon"></i>
            <i *ngIf="!tbItem.faicon" class="material-icons">{{ tbItem.icon }}</i>
          </ng-template>
          <ng-template #multi>
            <i [class]="tbItem.iconstack[0]"></i>
            <i [class]="tbItem.iconstack[1]"></i>
          </ng-template>
        </a>
        <div class="toolbar-submenu" [ngClass]="{'active-toolbar-submenu':showToolbarSubmenu}"
             [ngStyle]="{'left':toolbarMainItem?.offsetLeft}">
          <div *ngFor="let tbItem of toolbarMainItem?.submenu">
            <a class="toolbar-subitem" [dsvsToolbarTool]="tbItem" (toolbarItemClick)="toolClicked($event)"
               [pTooltip]="tbItem.tooltip" tooltipPosition="bottom"
               [ngClass]="{'tool-disabled': tbItem.disabled, 'tool-enabled': !tbItem.disabled, 'fas-stack': tbItem.iconstack}">
              <div *ngIf="tbItem.iconstack; then multi else single"></div>
              <ng-template #single>
                <i *ngIf="tbItem.faicon" [class]="tbItem.icon"></i>
                <i *ngIf="!tbItem.faicon" class="material-icons">{{ tbItem.icon }}</i>
              </ng-template>
              <ng-template #multi>
                <i [class]="tbItem.iconstack[0]"></i>
                <i [class]="tbItem.iconstack[1]"></i>
              </ng-template>
            </a>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- Alte Toolbar-Steuerung
  <div *ngIf="showToolbar" class="layout-breadcrumb-options toolbar-menu">
    <span>Werkzeuge:</span>
    <a *ngFor="let tbItem of toolbarItems" [dsvsToolbarTool]="tbItem" (toolbarItemClick)="toolClicked($event)" [pTooltip]="tbItem.tooltip" tooltipPosition="bottom" [ngClass]="{'tool-disabled': tbItem.disabled, 'tool-enabled': !tbItem.disabled, 'fas-stack': tbItem.iconstack}">
      <div *ngIf="tbItem.iconstack; then multi else single"></div>
      <ng-template #single>
        <i *ngIf="tbItem.faicon" [class]="tbItem.icon"></i>
        <i *ngIf="!tbItem.faicon" class="material-icons">{{ tbItem.icon }}</i>
      </ng-template>
      <ng-template #multi>
        <i [class]="tbItem.iconstack[0]"></i>
        <i [class]="tbItem.iconstack[1]"></i>
      </ng-template>
    </a>
  </div>
  -->

</div>


`,
  styles: [`.layout-breadcrumb li{padding-left:2px;padding-right:2px}`]
})
export class DsvsBreadcrumbComponent implements OnDestroy {

  /** Breadcrumb MenuItems Subscription zur Steuerung des BreadCrumbs */
  bcItemsSubscription: Subscription;
  /** Toolbar MenuItems Subscription zur Steuerung des BreadCrumbs */
  tbItemsSubscription: Subscription;
  /** Toolbar visible Subscription zur Steuerung der Toolbar */
  tbSubscription: Subscription;
  /** Toolbar SubmenÃ¼ Reset */
  tbResetSubscription: Subscription;

  menuItems: MenuItem[];
  toolbarItems: DsvsToolbarItem[];
  toolbarMainItem: DsvsToolbarItem;
  showToolbar = false;
  showToolbarSubmenu = false;
  isHome = true;

  constructor(public breadcrumbService: DsvsBreadcrumbService, public configService: DsvsSharedUiLibConfigService) {
    this.bcItemsSubscription = breadcrumbService.menuItemsHandler.subscribe(response => {
      this.menuItems = response;
      this.isHome = (this.menuItems.length === 0);
    });
    this.tbItemsSubscription = breadcrumbService.toolbarItemsHandler.subscribe(response => {
      this.toolbarItems = response;
    });
    this.tbSubscription = breadcrumbService.showToolbarHandler.subscribe(response => {
      this.showToolbar = response;
      this.toolbarMainItem = null;
      this.showToolbarSubmenu = false;
    });
    this.tbResetSubscription = breadcrumbService.resetToolbarSubmenuHandler.subscribe(response => {
      if (response) {
        this.toolbarMainItem = null;
        this.showToolbarSubmenu = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.bcItemsSubscription) {
      this.bcItemsSubscription.unsubscribe();
    }
    if (this.tbItemsSubscription) {
      this.tbItemsSubscription.unsubscribe();
    }
    if (this.tbSubscription) {
      this.tbSubscription.unsubscribe();
    }
    if (this.tbResetSubscription) {
      this.tbResetSubscription.unsubscribe();
    }
  }

  /** Den Click-Event mit dem Toolbar-Item fÃ¼r die Subscriber im
   * BreadCrumb auslÃ¶sen
   * @param item
   */
  toolClicked(item: DsvsToolbarItem) {
    this.showToolbarSubmenu = false;
    this.toolbarMainItem = null;
    this.breadcrumbService.raiseToolClickedEvent(item);
    if (item.callback) {
      item.callback();
    }
  }

  /** Click-Event auf ein Toolbar-Item mit SubmenÃ¼s
   *  @param item */
  toggleToolbarSubmenu(item: DsvsToolbarItem) {
    if (this.toolbarMainItem) {
      if (this.toolbarMainItem === item) {
        this.showToolbarSubmenu = false;
        this.toolbarMainItem = null;
        this.breadcrumbService.mainToolbarItemClicked = false;
      } else {
        this.breadcrumbService.mainToolbarItemClicked = true;
        this.toolbarMainItem = item;
      }
    } else {
      this.toolbarMainItem = item;
      this.showToolbarSubmenu = true;
      this.breadcrumbService.mainToolbarItemClicked = true;
    }
  }
}
