import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { ActionContextHalItem, ActionHalItem, ContextHalItem, StepContextHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowActionDataDto } from '../../interfaces/WorkflowActionData.dto';
import { WorkflowActionExtension } from '../../interfaces/WorkflowActionExtension';
import { WorkflowActionExtensionService } from '../../services/config/workflow-action-extension.service';
import { WorkflowActionContextServiceImpl } from '../../services/data/workflow-action-context.service';
import { ViewInjectableComponent } from '../view-injectable.component';
import { WorkflowActionComponent } from '../workflow-action/workflow-action.component';

@Component({
  selector: 'wfg-workflow-action-factory',
  template: `<div class="wrapper">
  <div class="loading-overlay" *ngIf="loading && showSaveButton">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <div [class]="showSaveButton ? 'action-container' : ''">
    <ng-template wfgActionHost></ng-template>
  </div>

  <div *ngIf="showSaveButton" class="ui-g">
    <div class="action-button-container" style="justify-content: flex-end;">
      <button
        pButton
        type="button"
        label="Speichern"
        (click)="onSaveButtonClicked()"
      ></button>
    </div>
  </div>

</div>
`,
  styles: [`.loading-overlay{z-index:1000;background-color:#fff;opacity:.5;position:absolute;width:100%;height:100%;display:flex;align-items:center;justify-content:center}.wrapper{position:relative}.action-container{min-height:200px}.action-button-container{padding:1em;flex:1}`]
})
export class WorkflowActionFactoryComponent extends ViewInjectableComponent<WorkflowActionComponent> implements OnChanges {

  private _action: ActionHalItem;
  private _context: ContextHalItem;
  private _stepContext: StepContextHalItem;

  @Input()
  showSaveButton = true;

  @Input()
  action: ActionHalItem;

  @Input()
  context: ContextHalItem;

  @Input()
  stepContext: StepContextHalItem;

  @Output()
  success = new EventEmitter<ActionContextHalItem>();

  @Output()
  error = new EventEmitter<any>();

  loading: boolean;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    private workflowActionExtensionService: WorkflowActionExtensionService,
    private actionContextService: WorkflowActionContextServiceImpl
  ) {
    super(componentFactoryResolver);
  }

  ngOnChanges(changes: SimpleChanges) {

    const actionChange: SimpleChange = changes.action;
    const contextChange: SimpleChange = changes.context;
    const stepContextChange: SimpleChange = changes.stepContext;

    if (actionChange
      && actionChange.currentValue !== actionChange.previousValue
      && actionChange.currentValue
      && actionChange.currentValue.data) {
      // IMPORTANT: Set Instance first!
      this.extension = this.workflowActionExtensionService.get(actionChange.currentValue.data.alias);
    }

    if (this.inputInstance) {
      if (actionChange && actionChange.currentValue) {
        this._action = actionChange.currentValue;
        this.inputInstance.action = actionChange.currentValue;
      }
      if (contextChange && contextChange.currentValue) {
        this._context = contextChange.currentValue;
        this.inputInstance.context = contextChange.currentValue;
      }
      if (stepContextChange && stepContextChange.currentValue) {
        this._stepContext = stepContextChange.currentValue;
        this.inputInstance.stepContext = stepContextChange.currentValue;
      }

      this.inputInstance.saveAction = () => {
        this.onSaveButtonClicked();
      };
    }
  }

  protected viewOfExtension(extension: WorkflowActionExtension): any {
    return extension.actionComponent;
  }

  getInputInstance(): WorkflowActionComponent {
    return this.inputInstance;
  }

  collectActionData(): WorkflowActionDataDto {
    return this.inputInstance.collectActionData();
  }

  resetActionData(): WorkflowActionDataDto {
    return this.inputInstance.resetForm();
  }

  onSaveButtonClicked() {

    this.inputInstance.validate();
    if (this.inputInstance.isValid()) {
      this.saveActionData(this.inputInstance.collectActionData());
    }

  }

  saveActionData(data: any) {

    if (this._context && this._context.data && this._action && this._action.data) {

      this.isProcessing(true);

      this.actionContextService.submitAction(this._context.data.id, this._action.data.id, data).subscribe(
        result => {
          this.isProcessing(false);

          this.inputInstance.actionContext = result;
          this.inputInstance.onSuccess(result);
          this.success.emit(result);
        }, error => {
          this.isProcessing(false);

          this.inputInstance.onError(error);
          this.error.emit(error);
        });
    } else {
      console.error('Context or Action cannot be null!');
    }

  }

  isProcessing(processing: boolean) {
    if (processing) {
      this.inputInstance.disableControls();
      this.inputInstance.isProcessing = true;
      this.loading = true;
    } else {
      this.inputInstance.enableControls();
      this.inputInstance.isProcessing = false;
      this.loading = false;
    }
  }

}
