import {Component, Input} from '@angular/core';

@Component({
  selector: 'dsvs-tag-badge',
  template: `<div [ngStyle]="{'background': color}" class="ui-chips-token ui-corner-all dsvs-tag">
    <span *ngIf="tag != null">
      {{tag}}
    </span>
</div>
`,
  styles: [`.dsvs-tag{color:#f9fbff;padding:2px 4px}.ui-chips-token{border-radius:2px;background:#4f70b6;color:#f9fbff;font-size:12pt;padding:4px 12px;margin-bottom:8px;margin-right:8px}.ui-chips-token .ui-chips-token-icon{top:16px}.ui-chips-token .ui-chips-token-label{margin-right:16px;margin-bottom:1px}`]
})
export class TagBadgeComponent {

  _color = '#4f70b6';

  @Input() tag: string = null;

  // TODO: Replace with colors from SASS
  @Input() set color(color: string) {
    if (color) {
      if (color.startsWith('#')) {
        this._color = color;
      } else {
        switch (color.toLowerCase()) {
          case 'active' :
            this._color = '#7ED321';
            break;
          case 'inactive' :
            this._color = '#FC3A28';
            break;
          case 'new' :
            this._color = '#42A5F5';
            break;
          case 'uploaded' :
            this._color = '#F3E401';
            break;
          case 'released' :
            this._color = '#7ED321';
            break;
          case 'canceled' :
            this._color = '#FC3A28';
            break;
          default:
            this._color = '#42A5F5';
            break;
        }
      }
    }
  }

  get color(): string {
    return this._color;
  }
}
