import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { WorkflowRole } from '@dsvs/workflow-generator-dto';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { WorkflowRoleServiceImpl } from '../services/data/workflow-role.service';

@Injectable()
export class WorkflowRoleResolver implements Resolve<WorkflowRole> {

  constructor(private roleService: WorkflowRoleServiceImpl) {

  }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<WorkflowRole> | Promise<WorkflowRole> | WorkflowRole {
    const id = route.params.id;
    if (id) {
      return this.roleService.getById(id).map(result => {
        return result.data;
      });
    }

    return Observable.of(null);
  }
}
