import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { WorkflowGeneratorConfigurationService } from '../services/config/workflow-generator-configuration.service';
import { WorkflowPermissions } from '../services/config/workflow-permissions.enum';

@Injectable()
export class WorkflowCrewGuard implements CanActivate {

  constructor(private permissionService: WorkflowGeneratorConfigurationService) {
  }

  canActivate(): boolean {
    return this.permissionService.hasPermission(WorkflowPermissions.CREW_READ);
  }
}
