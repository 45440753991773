import { Component, Input, OnInit } from '@angular/core';
import { DsvsErrorHandler } from '../../../error/classes/dsvs-error-handler';

@Component({
  selector: 'dsvs-login-via-apo',
  template: `<div class="ui-g">
  <div class="ui-g-12">
    <div class="card">
      <div><h2>Login zum FES muss Ã¼ber das Anwendungsportal erfolgen!</h2></div>
      <div *ngIf="errorHandler.hasError">
        <span>Fehlertyp: {{errorHandler.errType}}</span><br>
        <span>Fehlertitel: {{errorHandler.msgTitle}}</span><br>
        <span>Fehlertext: </span>
        <span [innerHTML]="errorHandler.msgBody"></span>
      </div>
    </div>
  </div>
</div>
`,
  styles: [``]
})
export class DsvsLoginViaApoComponent implements OnInit {

  @Input() errorHandler: DsvsErrorHandler;

  constructor() { }

  ngOnInit() {
  }

}
