import { HalItem, HalItemConstructor, HalPage, HalPageConstructor } from '@dsvs/hal-client';
import { WorkflowBaseReadOnly } from '@dsvs/workflow-generator-dto';
import { TypeMappingService } from './type-mapping.service';

export abstract class TypeMappingServiceImpl implements TypeMappingService {

  private pageConstructors: Map<string, HalPageConstructor<any, any, any>>;
  private itemConstructors: Map<string, HalItemConstructor<any, any>>;

  protected constructor() {
    this.pageConstructors = new Map<string, HalPageConstructor<any, any, any>>();
    this.itemConstructors = new Map<string, HalItemConstructor<any, any>>();
  }

  registerType<T extends WorkflowBaseReadOnly,
    R extends HalItem<T>,
    U extends HalPage<T, R>>
  (
    type: string,
    itemTypeConstructor: HalItemConstructor<T, R>,
    pageTypeConstructor: HalPageConstructor<T, R, U>
  ) {
    this.setItemConstructor(type, itemTypeConstructor);
    this.setPageConstructor(type, pageTypeConstructor);
  }

  overwriteType<T extends WorkflowBaseReadOnly,
    R extends HalItem<T>,
    U extends HalPage<T, R>>
  (
    type: string,
    itemTypeConstructor: HalItemConstructor<T, R>,
    pageTypeConstructor: HalPageConstructor<T, R, U>
  ) {

    this.registerType(type, itemTypeConstructor, pageTypeConstructor);
  }

  getPageConstructor<T extends WorkflowBaseReadOnly,
    R extends HalItem<T>,
    U extends HalPage<T, R>>(type: string): HalPageConstructor<T, R, U> {
    const halPageConstructor = <HalPageConstructor<T, R, U>> this.pageConstructors.get(type);
    if (!halPageConstructor) {
      console.error(
        'Could not find matching PageConstructor for Type "' + type + '". Please register the corresponding Type.');
    } else {
      return halPageConstructor;
    }
  }

  setPageConstructor<T extends WorkflowBaseReadOnly,
    R extends HalItem<T>,
    U extends HalPage<T, R>>
  (type: string, typeConstructor: HalPageConstructor<T, R, U>) {
    this.pageConstructors.set(type, typeConstructor);
  }

  removePageConstructor(type: string) {
    this.pageConstructors.delete(type);
  }

  getItemConstructor<T extends WorkflowBaseReadOnly,
    R extends HalItem<T>>
  (type: string): HalItemConstructor<T, R> {
    const halItemConstructor = <HalItemConstructor<T, R>> this.itemConstructors.get(type);
    if (!halItemConstructor) {
      console.error(
        'Could not find matching ItemConstructor for Type "' + type + '". Please register the corresponding Type.');
    } else {
      return halItemConstructor;
    }
  }

  setItemConstructor<T extends WorkflowBaseReadOnly,
    R extends HalItem<T>>
  (type: string, typeConstructor: HalItemConstructor<T, R>) {
    this.itemConstructors.set(type, typeConstructor);
  }

  removeItemConstructor(type: string) {
    this.itemConstructors.delete(type);
  }

}
