import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DsvsToolbarItem } from '../interfaces/toolbar-item';

@Directive({
  selector: '[dsvsToolbarTool]'
})
export class DsvsToolbarToolDirective {

  @Input() dsvsToolbarTool: DsvsToolbarItem;
  @Output() toolbarItemClick = new EventEmitter();

  constructor() {
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    if (!this.dsvsToolbarTool.disabled) {
      this.dsvsToolbarTool.offsetLeft = event.target.offsetLeft - 9 + 'px';
      // console.log(this.dsvsToolbarTool.offsetLeft);
      this.toolbarItemClick.emit(this.dsvsToolbarTool);
    }
  }
}
