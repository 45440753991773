import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { RoleUserHalItem, RoleUserHalPage, WorkflowRoleUser } from '@dsvs/workflow-generator-dto';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowRoleUsersService extends DsvsSearchableCrudEntityService<WorkflowRoleUser> {

}

// @dynamic
@Injectable()
export class WorkflowRoleUserServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowRoleUser, RoleUserHalItem, RoleUserHalPage>
  implements WorkflowRoleUsersService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'roleuser', collection: 'roleusers'},
      WorkflowGeneratorTypeMappingService.WorkflowRoleUser,
      typeResolver
    );
  }
}

@Injectable()
export class WorkflowRoleUserServiceMock
  extends DsvsSearchableCrudEntityServiceMock<WorkflowRoleUser>
  implements WorkflowRoleUsersService {

  constructor() {
    super();

    this.data = [
      {
        id: '111'
      }];

  }
}
