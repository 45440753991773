import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MenuItem, Message, SelectItem} from 'primeng/api';
import {Table} from 'primeng/table';
import {Subscription} from 'rxjs';
import {DsvsAuthorizationService} from '../../../authentication/services/authorization.service';
import {DsvsErrorHandler} from '../../../error/classes/dsvs-error-handler';
import {DsvsToolbarItem} from '../../../layout/interfaces/toolbar-item';
import {DsvsBreadcrumbService} from '../../../layout/services/breadcrumb.service';
import {DsvsMenuService} from '../../../layout/services/menu.service';
import {DsvsTableColumn} from '../../../primeng/interfaces/table-column';
import {DsvsSettings} from '../../../settings/interfaces/dsvs-settings';
import {DsvsFileDownloadService} from '../../../user-file-download/services/file-download.service';
import {DsvsFileData} from '../../interfaces/file-data';
import {DsvsNotificationData} from '../../interfaces/notification-data';
import {DsvsNotificationService} from '../../services/notification.service';

@Component({
  selector: 'dsvs-notification-view',
  template: `<p-confirmDialog header="Confirmation" icon="" width="425" acceptLabel="Ja" rejectLabel="Nein"
                 responsive="true"></p-confirmDialog>

<dsvs-table
  [columns]="columns"
  [data]="sortedResults.length > 0 ?
    sortedResults :  (searchTerm === '' ? notificationService.notificationListTotal : searchResults)"
  [expandable]="false"
  [searchable]="true"
  [loading]="loading"
  [bodyTemplate]="bodyTemplate"
  (searchChanged)="onSearch($event)"
  (sortChanged)="onSort($event)"
  rowHeight="90"
  title="Benachrichtigungen"
  [toolbarItems]="initToolbar()"
>
</dsvs-table>

<ng-template #bodyTemplate
             let-rowData="rowData"
             let-column="column">
  <div [ngSwitch]="column.format">

    <ng-container *ngSwitchCase="'DATE'">{{rowData[column.field] | date : 'dd.MM.yyyy'}}
    </ng-container>
    <ng-container *ngSwitchCase="'YN'">{{rowData[column.field] | boolToYesNo }}</ng-container>
    <ng-container *ngSwitchCase="'NF_TYPE'">{{rowData[column.field]}}
    </ng-container>
    <ng-container *ngSwitchCase="'ICON'">
      <span class="notification-icon">
        <i [class]="rowData[column.field]"></i>
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'FILE'">
      <i
        *ngIf="rowData[column.field].name && rowData[column.field].id"
        (click)="downloadFile(rowData[column.field])"
        class="fa ui-icon-file-download">
      </i>
    </ng-container>
    <p-splitButton
      *ngSwitchCase="'ACTION'"
      class="icon-splitbutton"
      [icon]="!rowData['readType'] ? 'fas fas-eye' : 'fas fas-eye-slash'"
      (onClick)="!rowData['readType'] ? markRead(rowData) : markUnread(rowData)"
      appendTo="body"
      [model]="getSplitButtonItems(rowData)">
    </p-splitButton>

    <ng-container *ngSwitchDefault>
      {{rowData[column.field]}}
    </ng-container>

  </div>
</ng-template>
`,
  styles: [`.unread{font-weight:700}.read{font-weight:400}.notification-icon{font-size:1.7vw}.notification-icon i{font-size:inherit}`]
})
export class DsvsNotificationViewComponent implements OnInit, OnDestroy, AfterViewInit {
  /* Messages */
  msgs: Message[] = [];
  /* Statische User-Informationen */
  infos: Message[] = [];
  infoText: string;
  infoTextUpload: string;
  /* Error-Handler */
  error: DsvsErrorHandler = new DsvsErrorHandler();
  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;
  /* Click auf Notification MenÃ¼-Item bei aktiver Komponente */
  notificationSubscription: Subscription;
  /** Flags */
  loading: boolean;

  /** Parameter fÃ¼r die DataTable */
  dtrows: number;                   // Anzahl der Zeilen pro Seite aus den Settings
  dtrowsPerPage: number[];          // MÃ¶gliche Auswahl fÃ¼r Anzahl der Zeilen pro Seite aus den Settings

  /** Daten fÃ¼r die TurboTable */
  @ViewChild('ttable') turboTable: Table;
  columns: DsvsTableColumn[];
  totalRecords: number;
  filteredRecords: number;
  first = 0;
  selectedNotificationId: string;
  selectedNotification: DsvsNotificationData[];
  ynOptions: SelectItem[] = [];
  expandedRows: { [key: number]: number } = {};
  private jwt: string;
  searchTerm = '';
  searchResults: DsvsNotificationData[] = [];
  sortedResults: DsvsNotificationData[] = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    public breadcrumbService: DsvsBreadcrumbService,
    private authService: DsvsAuthorizationService,
    public fesSettings: DsvsSettings,
    public notificationService: DsvsNotificationService,
    private confirmationService: ConfirmationService,
    private fileDownloadService: DsvsFileDownloadService,
    private menuService: DsvsMenuService) {
  }

  ngOnInit() {
    this.loading = true;
    /** Flag fÃ¼r die Topbar Komponente falls ein Notification-Item geklickt wird */
    this.notificationService.isInNotificationView = true;
    this.jwt = this.authService.getJWT();
    /** Selected Notifications initialisieren */
    this.selectedNotification = [];
    /** Tabellenparameter */
    this.dtrows = +this.fesSettings.getOptionValue('DTROWS_FES', 10);
    /* any-Array zu number-array umwandeln */
    this.dtrowsPerPage = this.fesSettings.getOptionParameters('DTROWS_FES', [5, 10, 25, 100]).map(value => +value);
    this.columns = this.defineTableColumns();
    /**Toolbar initialisierung*/
    this.breadcrumbService.setMenuItems([
      {label: 'Benachrichtigungen'}
    ]);
    this.breadcrumbService.setToolbarItems(this.initToolbar());
    this.breadcrumbService.setToolbarVisible(true);
    /** Die vom Breadcrumb-Service ausgelÃ¶sten Toolbar-Events abonnieren */
    this.toolbarSubscription = this.breadcrumbService.toolClickedHandler.subscribe(item => {
      this.toolbarItemClicked(item);
    });
    /**Parameter fÃ¼r Boolean Filter in der Tabelle setzen*/
    this.ynOptions = [
      {label: '', value: null},
      {label: 'ja', value: 1},
      {label: 'nein', value: 0}
    ];
    /**Extendedrows auf 0 setzen aus Service Ã¼bernehmen*/
    this.setExpandedRows();
    /**Falls eine id in der Route angegeben ist (Beispielsweise nach klick auf eine Notification Ã¼ber die Topbar)
     * wird die entsprechende Notification in der Tabelle ausgewÃ¤hlt*/

    if (this.route.snapshot.params['id']) {
      this.selectedNotification.push(this.notificationService.notificationListTotal.find(
        item => item.id === +this.route.snapshot.params['id']));
      this.first = Math.floor(this.notificationService.notificationListTotal.findIndex(
        item => item.id === +this.route.snapshot.params['id']) / this.dtrows) * this.dtrows;
      this.changeToolbarItemStatus(false);
    }
    /*
    * Parameter fÃ¼r die Anzeige Ã¼ber die Anzahl der gefilterten Notifications in der Tabelle
    * **/
    this.totalRecords = this.notificationService.notificationListTotal.length;
    this.filteredRecords = this.totalRecords;
    setTimeout(() => {
      this.expandedRows[this.route.snapshot.params['id']] = 1;
    }, 350);
  }

  ngAfterViewInit() {
    /** Service fÃ¼r die Notification Auswahl im MenÃ¼ (Glocke) wenn Komponente aktiv ist */
    if (!this.notificationSubscription) {
      this.notificationSubscription = this.notificationService.notificationItemClicked.subscribe(nfID => {
        this.selectedNotification = [];
        this.selectedNotification.push(this.notificationService.notificationListTotal.find(item => item.id === nfID));
        this.first = Math.floor(this.notificationService.notificationListTotal.findIndex(
          item => item.id === nfID) / this.dtrows) * this.dtrows;
        setTimeout(() => {
          this.expandedRows[nfID] = 1;
          this.changeToolbarItemStatus(false);
        }, 250);
      });
    }
  }

  /**Unsubscribe auf die Toolbar*/
  ngOnDestroy() {
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
    if (!this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
    /** Flag fÃ¼r die Topbar Komponente falls ein Notification-Item geklickt wird */
    this.notificationService.isInNotificationView = false;
  }

  /** Ãbernimmt die regelmÃ¤Ãig aktualisierte notificationListTotal aus dem notificationService fÃ¼r den Bestand der Tabelle
   * Achtung: die Tabelle bei Ã¤nderungen an den Notifications nicht automatisch aktualisiert ohne das die notificationList nicht erneut
   * = der notificationListTotal aus dem Service gesetzt wird*/
  private setExpandedRows() {
    for (const notification of this.notificationService.notificationListTotal) {
      this.expandedRows[notification.id] = 0;
    }
    /** Da nun Daten vorhanden sind kann loading auf false gesetzt werden*/
    this.loading = false;
  }

  /** Beim klick auf den LÃ¶schen Button wird zunÃ¤chst der Confirmation Service ausgefÃ¼hrt*/
  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Wollen Sie die ausgewÃ¤hlten Benachrichtigungen lÃ¶schen?',
      header: 'BestÃ¤tigung',
      icon: '',

      accept: () => {
        let IdDelete: number[] = [];
        for (const entry of this.selectedNotification) {
          // console.log(entry.id);
          IdDelete.push(entry.id);
        }
        this.notificationService.markNotificationAsDeleted(this.jwt, IdDelete);

        this.selectedNotification = [];
        IdDelete = [];

        setTimeout(() => {
          this.menuService.addUserMenu();
        }, 1000);
      }
    });
  }

  /** Clickhandler fÃ¼r die Toolbar, aktuell wird falls notwendig die Tabelle mit einem timeout aktualisiert da die
   * korrekte Darstellung der Ã¤nderung von der Response Zeit des Backends abhÃ¤ngt, der timeout wert muss eventuell angepasst werden*/
  private toolbarItemClicked(item: DsvsToolbarItem) {
    switch (item.id) {
      case 'REFRESH':
        this.ngOnInit();
        break;
    }
  }

  /** Event - Tabellenzeile wurde angewÃ¤hlt */
  onRowSelect(event) {
    this.changeToolbarItemStatus(false);
    this.selectedNotificationId = event.data.id;
  }

  /** Event - Tabellenzeile wurde abgewÃ¤hlt */
  onRowUnselect(event) {
    if (this.selectedNotification.length < 1) {
      this.changeToolbarItemStatus(true);
    }
  }

  /** Ãndert den Disabled Status der angegebenen toolbar items*/
  changeToolbarItemStatus(disabled: boolean) {
    this.breadcrumbService.setToolbarItemDisabled('READ', disabled);
    this.breadcrumbService.setToolbarItemDisabled('UNREAD', disabled);
    this.breadcrumbService.setToolbarItemDisabled('DELETE', disabled);
  }

  /** Initialisierung der Toolbar items*/
  public initToolbar(): DsvsToolbarItem[] {
    const tbItems: DsvsToolbarItem[] = [];
    tbItems.push({
      id: 'REFRESH', icon: 'refresh', disabled: false, tooltip: 'Tabelle aktualisieren', faicon: true, callback: () => {
        this.notificationService.initializeUserNotifications(this.jwt);
      }
    });
    return tbItems;
  }

  onFilter(event) {
    this.selectedNotification = [];
    this.filteredRecords = event.filteredValue.length;
  }

  downloadFile(file: DsvsFileData) {
    this.fileDownloadService.downloadFilePath(this.jwt, 'files/' + file.name + '/download');
  }

  /** Tabellenspalten (werden ggf. spÃ¤ter Ã¼ber die Settings eingelesen
   * und sind dann vom Anwender festlegbar
   * @returns */
  private defineTableColumns(): DsvsTableColumn[] {
    const cols: DsvsTableColumn[] = [];
    cols.push(
      {field: 'createDate', header: 'Eingang', sort: true, filter: '', hidden: false, format: 'DATE', width: '10%'},
      {field: 'menu_icon', header: 'Art', sort: true, filter: '', hidden: false, format: 'ICON', width: '5%', centered: true},
      {field: 'readType', header: 'Gelesen', sort: true, filter: 'BOOL', hidden: false, format: 'YN', width: '10%'},
      {field: 'menu_title', header: 'Titel', sort: false, filter: 'STR', hidden: false, width: '21%'},
      {field: 'msg_text', header: 'Nachricht', sort: false, filter: 'STR', hidden: false, width: '32%'},
      {field: 'expireDate', header: 'VerfÃ¼gbar bis', sort: true, filter: '', hidden: false, format: 'DATE', width: '10%'},
      {field: 'file', header: '', sort: false, filter: '', format: 'FILE', hidden: false, width: '5%'},
      {field: '', header: '', format: 'ACTION', width: '7%'}
    );

    return cols;
  }

  getSplitButtonItems(notificationItem: DsvsNotificationData): MenuItem[] {
    const menuItems = [];

    menuItems.push(
      {
        label: 'gelesen',
        icon: 'fas fas-eye',
        command: () => {
          this.markRead(notificationItem);
        }
      });

    menuItems.push(
      {
        label: 'ungelesen',
        icon: 'fas fas-eye-slash',
        command: () => {
          this.markUnread(notificationItem);
        }
      });

    menuItems.push(
      {
        label: 'lÃ¶schen',
        icon: 'fas fas-trash-o',
        command: () => {
          this.selectedNotification.push(notificationItem);
          this.confirmDelete();
        }
      });

    return menuItems;
  }

  markRead(notificationItem: DsvsNotificationData) {
    const IdRead: number[] = [];
    IdRead.push(notificationItem.id);
    this.notificationService.markNotificationAsRead(this.jwt, IdRead);
    setTimeout(() => {
      this.notificationService.initializeUserNotifications(this.jwt);
      setTimeout(() => {
        this.menuService.addUserMenu();
      }, 1000);
    }, 350);
  }

  markUnread(notificationItem: DsvsNotificationData) {
    const IdRead: number[] = [];
    IdRead.push(notificationItem.id);
    this.notificationService.markNotificationAsUnread(this.jwt, IdRead);
    setTimeout(() => {
      this.notificationService.initializeUserNotifications(this.jwt);
      setTimeout(() => {
        this.menuService.addUserMenu();
      }, 1000);
    }, 350);
  }

  onSearch(event) {
    this.searchTerm = event.searchTerm;
    this.searchResults = this.notificationService.notificationListTotal.filter(item => {
      return item.msg_title.indexOf(event.searchTerm) !== -1 ||
        item.msg_text.indexOf(event.searchTerm) !== -1;
    });
  }

  onSort(event) {
    let sortedResultsHelper: DsvsNotificationData[];
    sortedResultsHelper = this.notificationService.notificationListTotal.sort((a, b) => {

      if (event.sort[0].order === 'ASC') {
        if (a[event.sort[0].property] < b[event.sort[0].property]) {
          return -1;
        }
        if (b[event.sort[0].property] > a[event.sort[0].property]) {
          return 1;
        }
      } else {

        if (a[event.sort[0].property] > b[event.sort[0].property]) {
          return -1;
        }
        if (b[event.sort[0].property] < a[event.sort[0].property]) {
          return 1;
        }
      }
      return 0;
    });
    this.sortedResults = [...sortedResultsHelper];
  }

}
