import { NgZone } from '@angular/core';
import { TemplateParameters } from '@dsvs/traverson-ng';
import isNil from 'lodash/isNil';
import 'rxjs/add/operator/take';
import { Observable } from 'rxjs/Observable';
import { defer } from 'rxjs/observable/defer';
import { HalItemConstructor, HalPageConstructor } from '../client.interface';
import { AppInjector } from '../hal-client.module';
import { HalItem } from './item.interface';
import { HalPage } from './page.interface';

const _ = require('lodash');

export abstract class CachedData<T> {
    private promise: Promise<T>;
    private observable: Observable<T>;
    private _data: T;
    private zone: NgZone;

    constructor() {
        this.zone = AppInjector.get(NgZone);
    }

    get async(): Observable<T> {
        this.initAsync();

        return this.observable;
    }

    get sync(): T {
        this.initSync();

        return this._data;
    }

    protected abstract getObservable(): Observable<T>;

    public reload(): void {
        this.promise = null;
        this.observable = null;
        this._data = null;
    }

    protected initAsync(): boolean {
        if (isNil(this.promise)) {
            this.promise = this.getObservable()
                .take(1)
                .toPromise();
        }
        if (isNil(this.observable)) {
            this.observable = defer(() => this.promise);
        }
        return true;
    }

    protected initSync(): boolean {

        if (isNil(this._data)) {

            this._data = this.getLoadingData();
            this.async
                .subscribe((data) => {
                    this.zone.run(() => {
                        this._data = data;
                    });
                });
        }
        return true;
    }

    protected getLoadingData(): any {
        return {_loading: true};
    }

}

export class HalRelationItem<T, R extends HalItem<T>> extends CachedData<R> {

    constructor(private readonly relation: string,
                private readonly item: HalItem<any>,
                private readonly itemConstructor: HalItemConstructor<T, R>) {
        super();
    }

    protected getObservable(): Observable<R> {
        return this.item.getTypedRelation<T, R>(this.itemConstructor, this.relation);
    }

    initAsync(): boolean {
        if (this.halLinkExists(this.relation)) {
            return super.initAsync();
        } else {
            return false;
        }

    }

    initSync(): boolean {
        if (this.halLinkExists(this.relation)) {
            return super.initSync();
        } else {
            return false;
        }
    }

    private halLinkExists(rel: string) {
        return this.item
            && this.item.getLinks(rel).length > 0;
    }
}

export class HalRelationPage<T, R extends HalItem<T>, U extends HalPage<T, R>> extends CachedData<U> {

    protected templateVars?: TemplateParameters;

    constructor(private readonly relation: string,
                private readonly item: HalItem<any>,
                private readonly pageConstructor: HalPageConstructor<T, R, U>,
                private readonly itemConstructor: HalItemConstructor<T, R>,
                private readonly idProperty?: string,
                private readonly embeddedName?: string) {
        super();
        if (!this.idProperty) {
            this.idProperty = 'id';
        }
    }

    public reload(): void {
        this.templateVars = {};
        super.reload();
    }

    public add(item: R): Observable<any> {
        return this.item.addRelation(this.relation + '-edit', 'relationId', (<any>item.data)[this.idProperty]);
    }

    public remove(item: R): Observable<any> {
        return this.item.deleteRelation(this.relation + '-edit', 'relationId', (<any>item.data)[this.idProperty]);
    }

    public update(items: R[]): Observable<any> {
        const itemIds: String[] = items.map((item) => (<any>item.data)[this.idProperty]);
        return this.item.updateRelations(this.relation, itemIds);
    }

    protected getObservable(): Observable<U> {
        return this.item.getTypedRelations<T, R, U>(this.pageConstructor, this.itemConstructor, this.relation, this.templateVars,
            this.embeddedName);
    }

    public setParams(params: TemplateParameters): HalRelationPage<T, R, U> {
        if (!this.templateVars) {
            this.templateVars = {};
        }
        if (!_.isEqual(params, this.templateVars)) {
            this.reload();
            this.templateVars = params;
        }
        return this;
    }

    public addParam(key: string, value: any): HalRelationPage<T, R, U> {
        if (!this.templateVars) {
            this.templateVars = {};
        }
        if (!(this.templateVars[key] && this.templateVars[key] === value)) {
            this.templateVars[key] = value;
        }
        return this;
    }

    initAsync(): boolean {
        if (this.halLinkExists(this.relation)) {
            return super.initAsync();
        } else {
            return false;
        }
    }

    initSync(): boolean {
        if (this.halLinkExists(this.relation)) {
            return super.initSync();
        } else {
            return false;
        }
    }

    private halLinkExists(rel: string) {
        return this.item
            && this.item.getLinks(rel).length > 0;
    }

}
