import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableReadEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import {
  ActionContextHalItem,
  ActionContextHalPage,
  MultipartAction,
  MultipartActionDefinition,
  WorkflowActionContext
} from '@dsvs/workflow-generator-dto';
import { Observable } from 'rxjs/Observable';
import { HalRelation, WG_V1_CLIENT_TOKEN, MappedHalReadServiceImpl } from '../../../hal';
import { WorkflowTypeMappingService } from '../config/workflow-type-mapping.service';

export interface WorkflowActionContextService extends DsvsSearchableReadEntityService<WorkflowActionContext> {

  submitAction(contextId: string, actionId: string, data: any): Observable<ActionContextHalItem>;

  submitActions(contextId: string, actions: Map<string, any>): Observable<ActionContextHalPage>;

  validateAction(contextId: string, actionId: string, data: any): Observable<void>;
}

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class WorkflowActionContextServiceImpl
  extends MappedHalReadServiceImpl<WorkflowActionContext, ActionContextHalItem, ActionContextHalPage>
  implements WorkflowActionContextService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowTypeMappingService,
    private http: HttpClient
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'actioncontext', collection: 'actioncontexts'},
      WorkflowTypeMappingService.WorkflowActionContext,
      typeResolver
    );
  }

  validateAction(contextId: string, actionId: string, data: any): Observable<void> {
    const formData = this.createActionFormData(contextId, actionId, data);
    return this.sendFormData<void>(formData, '/validate');
  }

  submitAction(contextId: string, actionId: string, data: any): Observable<ActionContextHalItem> {
    const formData = this.createActionFormData(contextId, actionId, data);
    return this.sendFormData<ActionContextHalItem>(formData);
  }

  submitActions(contextId: string, actions: Map<string, any>): Observable<ActionContextHalPage> {
    const _upload: MultipartAction = <MultipartAction>{
      workflowContextId: contextId,
      includedActions: <MultipartActionDefinition[]>[]
    };

    const formData: FormData = new FormData();

    actions.forEach((data, actionId) => {

      _upload.includedActions.push({
        actionId: actionId,
        uploadNames: []
      });

      this.appendData(formData, _upload.includedActions, actionId, data);

    });

    this.appendJson(formData, '_upload', _upload);

    return this.sendFormData(formData);
  }

  private sendFormData<T>(formData: FormData, urlParam: string = ''): Observable<T> {

    // TODO: USE 'REAL' HAL-CLIENT_IMPL, PROBLEM WAS THAT WE NEED TO CHANGE THE
    // MEDIATYPE TO 'MULTIPART/ETC', (RIGHT NOW: 'hal/json')
    // <any>v1Client.post(formData, this.relation.single)

    return this.execute<T>((v1Client: HalClient) => {
      const url = (<any>v1Client).apiClient.startUrl + '/' + this.relation.collection + urlParam;
      return this.http.post<T>(url, formData, {});
      }
    );
  }

  private createActionFormData(contextId: string, actionId: string, data: any) {
    const _upload: MultipartAction = <MultipartAction>{
      workflowContextId: contextId,
      includedActions: <MultipartActionDefinition[]>[{
        actionId: actionId,
        uploadNames: []
      }]
    };

    const formData: FormData = new FormData();

    this.appendData(formData, _upload.includedActions, actionId, data);

    this.appendJson(formData, '_upload', _upload);
    return formData;
  }

  private appendData(formData: FormData, includedActions: MultipartActionDefinition[], actionId: string, data: any) {
    try {
      Object.keys(data).forEach(key => {
        const dataField = data[key];
        if (dataField !== undefined && dataField !== null) {
          if (typeof dataField === 'object' && dataField instanceof Blob) {

            this.appendBlob(formData, actionId + '_' + key, dataField);

          } else if (typeof dataField === 'string') {

            this.appendString(formData, actionId + '_' + key, dataField);

          } else {

            this.appendJson(formData, actionId + '_' + key, dataField);

          }
          const includedAction = includedActions.find(i => i.actionId === actionId);
          if (includedAction) {
            includedAction.uploadNames.push(key);
          }
        }
      });
    } catch (e) {
      console.error('Could not transform action-context-data', e);
    }
  }

  private appendJson(formData: FormData, key: string, data: object) {
    formData.append(key, new Blob([JSON.stringify(data)], {type: 'application/json'}));
  }

  private appendString(formData: FormData, key: string, data: string) {
    formData.append(key, data);
  }

  private appendBlob(formData: FormData, key: string, data: Blob) {
    formData.append(key, data);
  }
}


