import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { WorkflowCrew } from '@dsvs/workflow-generator-dto';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { WorkflowCrewServiceImpl } from '../services/data/workflow-crew.service';

@Injectable()
export class WorkflowCrewResolver implements Resolve<WorkflowCrew> {

  constructor(private crewService: WorkflowCrewServiceImpl) {

  }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<WorkflowCrew> | Promise<WorkflowCrew> | WorkflowCrew {
    const id = route.params.id;
    if (id) {
      return this.crewService.getById(id).map(result => {
        return result.data;
      });
    }

    return Observable.of(null);
  }
}
