import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { ShowcaseExampleCodeComponent } from '../showcase-example-code/showcase-example-code.component';
import { ShowcaseExampleDemoComponent } from '../showcase-example-demo/showcase-example-demo.component';
import { ShowcaseExampleDescriptionComponent } from '../showcase-example-description/showcase-example-description.component';

@Component({
  selector: 'dsvs-showcase-example',
  template: `<p-panel [toggleable]="true" [collapsed]="collapsed">

  <p-header>
    {{title}}
  </p-header>

  {{description}}

  <ng-content></ng-content>

  <ng-content select="dsvs-showcase-example-description" *ngIf="descriptionFacet"></ng-content>
  <ng-content select="dsvs-showcase-example-demo" *ngIf="demoFacet"></ng-content>
  <ng-content select="dsvs-showcase-example-code" *ngIf="codeFacet"></ng-content>
</p-panel>
`,
  styles: [``]
})
export class ShowcaseExampleComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() collapsed = true;

  @ContentChild(ShowcaseExampleCodeComponent) codeFacet;
  @ContentChild(ShowcaseExampleDemoComponent) demoFacet;
  @ContentChild(ShowcaseExampleDescriptionComponent) descriptionFacet;

  constructor() {
  }

  ngOnInit() {
  }

}
