import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dsvs-showcase-example-demo',
  template: `<h3>Demo</h3>
<ng-content></ng-content>
`,
  styles: [``]
})
export class ShowcaseExampleDemoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
