import { Injectable } from '@angular/core';
import { DsvsSharedUiLibConfig } from './dsvs-shared-ui-lib-config';

@Injectable({
  providedIn: 'root'
})
export class DsvsSharedUiLibConfigService {
  // TODO: DsvsSharedUiLibConfigService as it is now is inherently flawed.
  //       It is easy to use to simply pass a value into the configuration,
  //       which will make it resistant to changes, after the initialization of a module.
  //       Changing this to an interface allows for injection with the right values from the get-go.
  //       This would prevent usage like `let backendEndpoint = DsvsSharedUiLibConfigService.config.communication.backend;`
  //       to be a mistake (which it currently is) and make its implementation static / readonly!.
  public config: DsvsSharedUiLibConfig = <DsvsSharedUiLibConfig>{
    production: false,
    communication: {
      scope: '',
      zapurl: '',
      apourl: '',
      backend: '',
      zapinit: '',
      loggerSystemId: ''
    },
    routing: {
      home: '/home',
      userinfo: '/userinfo',
      settings: '/settings',
      logout: '/logout'
    },
    layout: {
      topbar: {
        logoUrl: ''
      },
      footer: {
        logoUrl: 'assets/lib-dsvs/images/smslogo.svg'
      }
    }
  };

}
