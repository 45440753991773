import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { CrewHalItem, CrewHalPage, WorkflowCrew, WorkflowRole, WorkflowUser } from '@dsvs/workflow-generator-dto';
import { HalRelation, WG_V1_CLIENT_TOKEN, MappedHalCrudServiceImpl } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowCrewService extends DsvsSearchableCrudEntityService<WorkflowCrew> {

}

// @dynamic
@Injectable()
export class WorkflowCrewServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowCrew, CrewHalItem, CrewHalPage>
  implements WorkflowCrewService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'crew', collection: 'crews'},
      WorkflowGeneratorTypeMappingService.WorkflowCrew,
      typeResolver
    );
  }
}

@Injectable()
export class WorkflowCrewServiceMock extends DsvsSearchableCrudEntityServiceMock<WorkflowCrew> implements WorkflowCrewService {

  constructor() {
    super();

    this.data = [
      {
        id: '111',
        displayName: 'Crew 1',
        userRoleMap: [{
          user: [<WorkflowUser>{
            id: '1',
            displayName: 'User 1'
          }, <WorkflowUser>{
            id: '12',
            displayName: 'User 12'
          }],
          role: <WorkflowRole>{
            id: '111',
            displayName: 'Rolle 1',
            description: 'Description 1'
          }
        },
          {
            user: [<WorkflowUser>{
              id: '2',
              displayName: 'User 2'
            }, <WorkflowUser>{
              id: '22',
              displayName: 'User 22'
            }],
            role: <WorkflowRole>{
              id: '2',
              displayName: 'Rolle 2',
              description: 'Description 2'
            }
          }],
        forms: []
      },
      {
        id: '222',
        displayName: 'Crew 2',
        userRoleMap: [],
        forms: []
      },
      {
        id: '333',
        displayName: 'Crew 3',
        userRoleMap: [],
        forms: []
      }
    ];
  }

}
