import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowStep, WorkflowStepRole} from '@dsvs/workflow-generator-dto';
import {isNil} from 'lodash';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import {HalReadServiceImpl} from '../../../../../hal';
import {DtoHelper} from '../../../../classes/dto-helper';
import {WorkflowGeneratorConfigurationService} from '../../../../services/config/workflow-generator-configuration.service';
import {WorkflowPermissions} from '../../../../services/config/workflow-permissions.enum';
import {WorkflowActionServiceImpl} from '../../../../services/data/workflow-action.service';
import {WorkflowCategoryServiceImpl} from '../../../../services/data/workflow-category.service';
import {WorkflowRightServiceImpl} from '../../../../services/data/workflow-right.service';
import {WorkflowRoleServiceImpl} from '../../../../services/data/workflow-role.service';
import {WorkflowStateServiceImpl} from '../../../../services/data/workflow-state.service';
import {WorkflowStepRoleServiceImpl} from '../../../../services/data/workflow-step-role.service';
import {WorkflowStepServiceImpl} from '../../../../services/data/workflow-step.service';
import {WorkflowTagServiceImpl} from '../../../../services/data/workflow-tag.service';
import {WorkflowTransitionServiceImpl} from '../../../../services/data/workflow-transition.service';
import {WorkflowUserServiceImpl} from '../../../../services/data/workflow-user.service';
import {WorkflowServiceImpl} from '../../../../services/data/workflow.service';

@Component({
  selector: 'wfg-workflow-detail',
  template: `<div class="workflow-detail">

  <div class="ui-g" *ngIf="workflow && workflow.data">

    <!--HEADER-->
    <div class="ui-lg-12" style="display:flex;">
      <div class="ui-lg-4">
        <dsvs-inline-edit
          type="text"
          [(value)]="workflow.data.displayName"
          [model]="workflow"
          label="Workflowname"
          [onSave]="saveWorkflowName"
          [disabled]="!isEditable"
        ></dsvs-inline-edit>
      </div>

      <div class="ui-lg-3">
        <dsvs-inline-edit
          label="Art"
          type="select"
          [value]="workflow.data.type | workflowtype"
          [forceSelection]="true"
          [model]="workflow"
          [onFetch]="fetchWorkflowTypes"
          [onSave]="saveWorkflowType"
          [disabled]="!isEditable"
        >
        </dsvs-inline-edit>
      </div>

      <div class="ui-lg-2">
        <dsvs-inline-edit
          label="Herkunft"
          type="text"
          [(value)]="workflow.data.origin"
          [forceSelection]="true"
          [model]="workflow"
          [onSave]="saveWorkflowName"
          [disabled]="!isEditable"
          maxLength="3"
        >
        </dsvs-inline-edit>
      </div>

      <div class="ui-lg-3">
        <ng-container
          *ngIf="workflow.categories && workflow.categories.addParam('size', 20000).sync; let categories">
          <wfg-autocomplete-dialog
            label="Kategorien"
            [model]="workflow"
            [disabled]="!isEditable"
            [value]="categories.content"
            [onFetch]="fetchCategories"
            [onSave]="saveWorkflowCategory"
            [displayValue]="'displayName'"
            [isMultipleSelect]="true"
            [fetchService]="workflowCategoryService"
            [usePlusMore]="true"
            [plusMoreMaxValue]="3"></wfg-autocomplete-dialog>
        </ng-container>
      </div>
    </div>

    <div class="list-toolbar ui-lg-12">
      <i class="material-icons" (click)="addStep()"
         pTooltip="Schritt hinzufÃ¼gen">playlist_add</i>
    </div>

    <p-accordion
      *ngIf="workflow.steps && !workflow.steps.sync._loading"
      class="ui-lg-12"
      expandIcon="fa fa-caret-right"
      collapseIcon="fa fa-caret-down"
    >
      <p-accordionTab
        *ngFor="let step of workflow.steps.setParams({size: 20000}).sync.content | orderBy: 'data.order'"
        styleClass="wfg-accordion-tab no-padding"
      >

        <p-header>
          <span class="accordion-header">
            <span>Schritt {{step.data.order}} - {{step.data.displayName}}</span>

            <i class="material-icons delete-step"
               (click)="removeStep(step)" *ngIf="isEditable">delete</i>
          </span>
        </p-header>

        <p-tabView>
          <p-tabPanel header="Details">
            <ng-template pTemplate="content">
              <div class="ui-lg-12" style="display: flex;">
                <div class="ui-lg-3">

                  <dsvs-inline-edit
                    label="Name"
                    type="text"
                    [(value)]="step.data.displayName"
                    [model]="step"
                    [onSave]="saveStep"
                    [disabled]="!isEditable"
                  >
                  </dsvs-inline-edit>
                </div>

                <div class="ui-lg-3">
                  <ng-container
                    *ngIf="step.data.stateId && step.state.sync && !step.state.sync._loading; else emptyState">
                    <dsvs-inline-edit
                      label="Status"
                      type="select"
                      [(value)]="step.state.sync.data"
                      displayValue="displayName"
                      [model]="step"
                      [onSave]="setStateId"
                      [onFetch]="fetch"
                      fetchEntity="state"
                      [disabled]="!isEditable"
                    >
                    </dsvs-inline-edit>
                  </ng-container>
                  <ng-template #emptyState>
                    <dsvs-inline-edit
                      label="Status"
                      type="select"
                      [(value)]="step.state"
                      displayValue="displayName"
                      [model]="step"
                      [onSave]="setStateId"
                      [onFetch]="fetch"
                      fetchEntity="state"
                      [disabled]="!isEditable"
                    >
                    </dsvs-inline-edit>
                  </ng-template>
                </div>

                <div class="ui-g-6">
                  <dsvs-inline-edit
                    label="Beschreibung"
                    type="textarea"
                    [(value)]="step.data.description"
                    [model]="step"
                    [onSave]="saveStep"
                    [disabled]="!isEditable"
                  >
                  </dsvs-inline-edit>
                </div>
              </div>
              <div class="ui-g-12">
                <ng-container *ngIf="!step.tags.sync._loading; else emptyTag">
                  <dsvs-inline-edit
                    label="Tags"
                    type="autocomplete"
                    [(value)]="step.tags.sync.content"
                    displayValue="displayName"
                    [onFetch]="fetch"
                    fetchEntity="tag"
                    [model]="step"
                    [onSave]="saveTags"
                    [addTagService]="workflowTagService"
                    [disabled]="!isEditable"
                  ></dsvs-inline-edit>
                </ng-container>

                <ng-template #emptyTag>
                  <dsvs-inline-edit
                    label="Tags"
                    type="autocomplete"
                    [(value)]="step.tags"
                    displayValue="displayName"
                    [onFetch]="fetch"
                    fetchEntity="tag"
                    [model]="step"
                    [onSave]="saveTags"
                    [addTagService]="workflowTagService"
                    [disabled]="!isEditable"
                  ></dsvs-inline-edit>
                </ng-template>
              </div>

              <div class="ui-g-12">
                <dsvs-inline-edit
                  label="Aktionen"
                  type="autocomplete"
                  [(value)]="step.actions.sync.content"
                  displayValue="displayName"
                  [onFetch]="fetch"
                  fetchEntity="action"
                  [model]="step"
                  [forceSelection]="true"
                  [onSave]="saveStepActions"
                  [disabled]="true"
                  title="Diese Aktionen werden Ã¼ber den Rollen-Tab bearbeitet - hier nur zur Information"
                ></dsvs-inline-edit>
              </div>

            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="ÃbergÃ¤nge">
            <ng-template pTemplate="content">
              <ng-container *ngIf="!step.transitions.setParams({showAll: true}).sync._loading">
                <div class="table-toolbar ui-lg-12">
                  <i class="material-icons" (click)="showAddNewTransitionModal(step)"
                     pTooltip="Neuer Ãbergang">add_box</i>
                </div>
                <p-table [value]="step.transitions.setParams({showAll: true}).sync.content" class="ui-lg-12">
                  <!-- ÃbergÃ¤nge -->
                  <ng-template pTemplate="header">
                    <tr class="wfg-list-table-content" style="font-weight: bold;">
                      <th class="secondary" width="20%">ÃbergÃ¤nge</th>
                      <th class="secondary" width="25%">Bedingungen</th>
                      <th class="secondary" width="25%">Wird informiert</th>
                      <th class="secondary" width="25%">FÃ¼hrt zu</th>
                      <th class="secondary" width="5%"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-transition>

                    <!-- transition list -->
                    <tr class="wfg-list-table-content">
                      <td width="20%">
                        <dsvs-inline-edit
                          type="text"
                          [(value)]="transition.data.displayName"
                          [model]="transition"
                          [onSave]="saveTransition"
                          [disabled]="!isEditable"
                        >
                        </dsvs-inline-edit>
                      </td>
                      <td width="20%">
                        <ng-container *ngIf="!transition.actions.sync._loading">

                          <wfg-autocomplete-dialog
                            [model]="transition"
                            [disabled]="!isEditable"
                            [value]="transition.actions.setParams({size: 20000}).sync.content"
                            [onFetch]="fetchActions"
                            [onSave]="saveActions"
                            [displayValue]="'displayName'"
                            [isMultipleSelect]="true"
                            [fetchService]="workflowActionService"></wfg-autocomplete-dialog>

                        </ng-container>
                      </td>
                      <td width="20%">
                        <ng-container *ngIf="!transition.notificationUsers.sync._loading">

                          <wfg-autocomplete-dialog
                            label="Nutzer"
                            [model]="transition"
                            [disabled]="!isEditable"
                            [value]="transition.notificationUsers.setParams({size: 20000}).sync.content"
                            [onFetch]="fetchNotificationUsers"
                            [onSave]="saveNotificationUsers"
                            [displayValue]="'displayName'"
                            [isMultipleSelect]="true"
                            [useUserBadges]="true"
                            [fetchService]="userService"></wfg-autocomplete-dialog>

                        </ng-container>

                        <ng-container *ngIf="!transition.notificationRoles.sync._loading">

                          <wfg-autocomplete-dialog
                            label="Rollen"
                            [model]="transition"
                            [disabled]="!isEditable"
                            [value]="transition.notificationRoles.setParams({size: 20000}).sync.content"
                            [onFetch]="fetchNotificationRoles"
                            [onSave]="saveNotificationRoles"
                            [displayValue]="'displayName'"
                            [isMultipleSelect]="true"
                            [fetchService]="workflowRoleService"></wfg-autocomplete-dialog>

                        </ng-container>

                      </td>
                      <td width="20%">
                        <ng-container>
                          <wfg-autocomplete-dialog
                            *ngIf="workflow && workflow.steps"
                            [model]="transition"
                            [disabled]="!isEditable"
                            [value]="transition.step.sync.data"
                            [onFetch]="fetchSteps"
                            [onSave]="setStepId"
                            [displayValue]="'displayName'"
                            [isMultipleSelect]="false"
                            [fetchServiceHalRelationPage]="workflow.steps"></wfg-autocomplete-dialog>

                        </ng-container>
                      </td>
                      <td width="20%">
                        <i class="material-icons delete-icon"
                           (click)="removeTransition(step, transition)" *ngIf="isEditable">delete</i>
                      </td>
                    </tr>

                  </ng-template>
                </p-table>

                <!-- /ÃbergÃ¤nge-->
              </ng-container>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="Rollen">
            <ng-template pTemplate="content">
              <ng-container *ngIf="!step.steproles.sync._loading">

                <div class="table-toolbar ui-lg-12">
                  <i class="material-icons" (click)="showAddNewRoleModal(step)"
                     pTooltip="Neue Rolle">add_box</i>
                </div>

                <p-table [value]="step.steproles.setParams({showAll: true}).sync.content" class="ui-lg-12">
                  <!-- Rollen -->
                  <ng-template pTemplate="header">
                    <tr class="wfg-list-table-content" style="font-weight: bold;">
                      <th class="secondary" width="20%">Rollen</th>
                      <th class="secondary" width="30%">Aktionsberechtigungen</th>
                      <th class="secondary" width="20%">Sichtbarkeiten</th>
                      <th class="secondary" width="20%">Ãbergangsberechtigungen</th>
                      <th class="secondary" width="5%">Hauptrolle</th>
                      <th class="secondary" width="5%"></th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-role>
                    <tr class="wfg-list-table-content">
                      <td>
                        <ng-container
                          *ngIf="role.data.roleId && role.role.sync && !role.role.sync._loading">

                          <wfg-autocomplete-dialog
                            [model]="role"
                            [disabled]="!isEditable"
                            [value]="role.role.sync.data"
                            [onFetch]="fetchRoles"
                            [onSave]="setRoleId"
                            [displayValue]="'displayName'"
                            [onColorForValue]="fetchColor"
                            [isMultipleSelect]="false"
                            [fetchService]="workflowRoleService"></wfg-autocomplete-dialog>

                        </ng-container>

                      </td>
                      <td>
                        <ng-container *ngIf="!role.writeRights.sync._loading">

                          <wfg-autocomplete-dialog
                            [model]="role"
                            [disabled]="!isEditable"
                            [value]="role.writeRights.setParams({size: 20000}).sync.content"
                            [onFetch]="fetchActions"
                            [onSave]="saveWriteRights"
                            [displayValue]="'displayName'"
                            [onColorForValue]="fetchColor"
                            [isMultipleSelect]="true"
                            [fetchService]="workflowActionService"></wfg-autocomplete-dialog>

                        </ng-container>
                      </td>
                      <td>
                        <ng-container *ngIf="!role.readRights.setParams({size: 20000}).sync._loading">

                          <wfg-autocomplete-dialog
                            [model]="role"
                            [disabled]="!isEditable"
                            [value]="role.readRights.sync.content"
                            [onFetch]="fetchActions"
                            [onSave]="saveReadRights"
                            [displayValue]="'displayName'"
                            [onColorForValue]="fetchColor"
                            [isMultipleSelect]="true"
                            [fetchService]="workflowActionService"></wfg-autocomplete-dialog>

                        </ng-container>
                      </td>
                      <td>
                        <ng-container *ngIf="!role.transitions.sync._loading">

                          <wfg-autocomplete-dialog
                            *ngIf="step && step.transitions"
                            [model]="role"
                            [disabled]="!isEditable"
                            [value]="role.transitions.setParams({size: 20000}).sync.content"
                            [onFetch]="fetchStepTransitions"
                            [onSave]="addActionsFromTransition"
                            [displayValue]="'displayName'"
                            [isMultipleSelect]="true"
                            [view]=""
                            [fetchServiceHalRelationPage]="step.transitions"></wfg-autocomplete-dialog>

                        </ng-container>
                      </td>
                      <td>
                        <p-checkbox
                          [ngModel]="step?.data?.mainStepRoleId === role.data.id"
                          binary="true"
                          (onChange)="saveMainStepRole($event, step, role)"
                        ></p-checkbox>
                      </td>
                      <td>
                        <i class="material-icons delete-icon"
                           (click)="removeRole(step, role)" *ngIf="isEditable">delete</i>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

                <!-- /Rollen -->
              </ng-container>
            </ng-template>
          </p-tabPanel>
        </p-tabView>

      </p-accordionTab>
    </p-accordion>

  </div>
</div>

<p-confirmDialog #cd header="Verwerfen" appendTo="body" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="fa ui-icon-clear" label="Abbrechen" (click)="cd.reject()"></button>
    <button type="button" pButton icon="fas fas-trash" label="Verwerfen" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

<p-footer style="display: block; width: 100%; padding-top: 41px;
    position: sticky;
    bottom: 0;">
  <div class="footer-buttons" style="display: flex; justify-content: flex-end">
    <button
      *ngIf="isNew"
      (click)="onDelete()"
      pButton
      type="button"
      class="ui-button-text dsvs-btn ui-corner-all"
      label="Verwerfen"
      icon="fas fas-trash">
    </button>
    <button
      (click)="onSave()"
      pButton
      type="button"
      class="ui-button-text dsvs-btn ui-corner-all"
      [label]="isEditable ? 'Speichern' : 'SchlieÃen'"
      icon="fa ui-icon-save">
    </button>
  </div>
</p-footer>

<div *ngIf="categories.length === 0 && !workflow"
     style="display: flex; align-items: center; width: 100%; height: 100%; justify-content: center">
  <div style="display:flex;flex-direction: column">
    Bitte legen Sie erst eine Kategorie an, um einen Workflow zu erstellen.
    <button pButton type="button" style="height: 100%; margin-top:8px;" icon="fa fa-link"
            label="Zu den Kategorien"
            (click)="goToCategories()">
    </button>
  </div>
</div>

<p-dialog
  [className]="'in-dialog-footer'"
  [header]="'Neuer Ãbergang'"
  [(visible)]="editStep && showNewTransitionModal"
  width="1200"
  [positionTop]="30"
  [modal]="true"
  [closable]="true"
  [dismissableMask]="true"
  [resizable]="true"
  [appendTo]="'body'"
  (visibleChange)="hideModals()"
>
  <table *ngIf="newTransition && isEditable" width="100%" class="add-new-table">
    <!-- new transition -->

    <tr class="wfg-list-table-content">
      <td width="20%" style="padding-top: 14px;">
        <dsvs-inline-edit
          type="text"
          [(value)]="newTransition.data.displayName"
          [model]="newTransition"
          [disabled]="!isEditable"
          label="ÃbergÃ¤nge"
        >
        </dsvs-inline-edit>
      </td>
      <td width="25%">

        <wfg-autocomplete-dialog
          [model]="newTransition"
          [disabled]="!isEditable"
          [value]="newTransition.actions"
          [onFetch]="fetchActions"
          [displayValue]="'displayName'"
          [isMultipleSelect]="true"
          label="Bedingungen"
          [fetchService]="workflowActionService"></wfg-autocomplete-dialog>

      </td>
      <td width="30%">
        <wfg-autocomplete-dialog
          [model]="newTransition"
          [disabled]="!isEditable"
          [value]="newTransition.notificationUsers"
          [onFetch]="fetchNotificationUsers"
          [displayValue]="'displayName'"
          [isMultipleSelect]="true"
          label="Zu informierende Nutzer"
          [fetchService]="userService"></wfg-autocomplete-dialog>

        <wfg-autocomplete-dialog
          [model]="newTransition"
          [disabled]="!isEditable"
          [value]="newTransition.notificationRoles"
          [onFetch]="fetchNotificationRoles"
          [displayValue]="'displayName'"
          [isMultipleSelect]="true"
          label="Zu informierende Rollen"
          [fetchService]="workflowRoleService"></wfg-autocomplete-dialog>

      </td>
      <td width="25%">
        <wfg-autocomplete-dialog
          *ngIf="workflow && workflow.steps"
          [model]="newTransition"
          [disabled]="!isEditable"
          [value]="newTransition.step"
          [onFetch]="fetchSteps"
          [onSave]="setNewTransitionStep"
          [displayValue]="'displayName'"
          [isMultipleSelect]="false"
          label="FÃ¼hrt zu"
          [fetchServiceHalRelationPage]="workflow.steps"></wfg-autocomplete-dialog>

      </td>
    </tr>
  </table>
  <p-footer style="display: block; width: 100%">
    <div class="footer-buttons" style="display: flex; justify-content: flex-end">
      <button
        *ngIf="isEditable && newTransition"
        [disabled]="!newTransition.step || !newTransition.step['id']"
        (click)="saveNewTransition(editStep, newTransition)"
        pButton
        type="button"
        class="ui-button-text dsvs-btn ui-corner-all"
        label="HinzufÃ¼gen"
        icon="fas fas-plus">
      </button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  [className]="'in-dialog-footer'"
  [header]="'Neue Rolle'"
  [(visible)]="editStep && showNewRoleModal"
  width="1200"
  [positionTop]="30"
  [modal]="true"
  [closable]="true"
  [dismissableMask]="true"
  [resizable]="true"
  [appendTo]="'body'"
  (visibleChange)="hideModals()"
>
  <table *ngIf="newRole && isEditable" width="100%" class="add-new-table">
    <!-- new role -->
    <tr class="wfg-list-table-content">
      <td width="25%">

        <wfg-autocomplete-dialog
          [model]="newRole"
          [disabled]="!isEditable"
          [value]="newRole.role"
          [onFetch]="fetchRoles"
          [onSave]="setNewRoleRole"
          [displayValue]="'displayName'"
          [isMultipleSelect]="false"
          label="Rollen"
          [fetchService]="workflowRoleService"></wfg-autocomplete-dialog>

      </td>
      <td width="25%">
        <wfg-autocomplete-dialog
          [model]="newRole"
          [disabled]="!isEditable"
          [value]="newRole.writeRights"
          [onFetch]="fetchActions"
          [displayValue]="'displayName'"
          [isMultipleSelect]="true"
          label="Aktionsberechtigungen"
          [fetchService]="workflowActionService"></wfg-autocomplete-dialog>
      </td>
      <td width="25%">
        <wfg-autocomplete-dialog
          [model]="newRole"
          [disabled]="!isEditable"
          [value]="newRole.readRights"
          [onFetch]="fetchActions"
          [displayValue]="'displayName'"
          [isMultipleSelect]="true"
          label="Sichtbarkeiten"
          [fetchService]="workflowActionService"></wfg-autocomplete-dialog>
      </td>
      <td width="25%">
        <wfg-autocomplete-dialog
          *ngIf="editStep && editStep.transitions"
          [model]="newRole"
          [disabled]="!isEditable"
          [value]="newRole.transitions"
          [onFetch]="fetchStepTransitions"
          [onSave]="addActionsFromTransitionInNewRole"
          [displayValue]="'displayName'"
          [isMultipleSelect]="true"
          label="Ãbergangsberechtigungen"
          [fetchServiceHalRelationPage]="editStep.transitions"></wfg-autocomplete-dialog>
      </td>
    </tr>
  </table>

  <p-footer style="display: block; width: 100%">
    <div class="footer-buttons" style="display: flex; justify-content: flex-end">
      <button
        *ngIf="isEditable"
        (click)="saveNewRole(editStep, newRole)"
        pButton
        type="button"
        class="ui-button-text dsvs-btn ui-corner-all"
        label="HinzufÃ¼gen"
        icon="fas fas-plus">
      </button>
    </div>
  </p-footer>
</p-dialog>
`,
  styles: [`.workflow-detail{margin:0 24px;min-height:100%}::ng-deep .workflow-detail .ui-tabview-panel{min-height:140px}.add-new-table td{border:0;vertical-align:top}::ng-deep .ui-button:focus{background-color:#264b94!important}.table-toolbar{display:flex;justify-content:flex-end;margin-bottom:-15px;margin-top:-20px}.table-toolbar i{cursor:pointer}.list-toolbar{display:flex;justify-content:flex-end;align-items:center}.list-toolbar i{cursor:pointer}.delete-step{display:none;font-size:20px;position:absolute;right:10px;color:#ff6e6e}.delete-step:hover{color:#e22122}:host .delete-icon{font-size:20px;position:absolute;right:10px;color:#ff6e6e!important;margin-top:-10px}:host .delete-icon:hover{color:#e22122!important}`]
})
export class WorkflowDetailComponent implements OnInit {

  private _workflow: any;

  get workflow(): any {
    return this._workflow;
  }

  @Input()
  set workflow(val: any) {
    this.setEditWorkflow.emit(val);
    this._workflow = val;
  }

  @Output() closeClicked = new EventEmitter<boolean>();
  @Output() setEditWorkflow = new EventEmitter<any>();

  isEditable = false;

  colorForActionsOfTransitions = '#FF6E6E';

  isNew = false;
  data = [];
  forms;
  roles = [];
  states = [];
  types = [];
  categories = [];

  editStep: any;
  showNewTransitionModal = false;
  showNewRoleModal = false;

  newTransition: {
    data: any;
    actions: any[];
    notificationUsers: any[];
    notificationRoles: any[];
    step: any[];
  };

  newRole: any;

  initializeNewTransition() {
    this.newTransition = {
      data: {},
      actions: [],
      notificationUsers: [],
      notificationRoles: [],
      step: []
    };
  }

  initializeNewRole() {
    this.newRole = {
      role: {},
      writeRights: [],
      readRights: [],
      transitions: []
    };
  }

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    public userService: WorkflowUserServiceImpl,
    public workflowService: WorkflowServiceImpl,
    public workflowActionService: WorkflowActionServiceImpl,
    public workflowRightsService: WorkflowRightServiceImpl,
    public workflowRoleService: WorkflowRoleServiceImpl,
    public workflowStepRoleService: WorkflowStepRoleServiceImpl,
    public workflowStateService: WorkflowStateServiceImpl,
    public workflowStepService: WorkflowStepServiceImpl,
    public workflowTagService: WorkflowTagServiceImpl,
    public workflowTransitionService: WorkflowTransitionServiceImpl,
    public workflowCategoryService: WorkflowCategoryServiceImpl,
    private router: Router,
    private confirmationService: ConfirmationService,
    private configService: WorkflowGeneratorConfigurationService
  ) {
  }

  ngOnInit() {

    this.isEditable = this.isNew || this.configService.hasPermission(WorkflowPermissions.WORKFLOW_UPDATE);

    this.types = [
      {value: 'CREATE', label: 'Neuerstellung'},
      {value: 'UPDATE', label: 'Aktualisierung'},
      {value: 'DELETE', label: 'LÃ¶schung'}
    ];

    this.workflowStateService.search('').subscribe(result => {
      this.states = result.content.map((t) => t.data);
    });

    // always fetch roles, used when adding new role
    if (this.roles.length === 0) {
      this.workflowRoleService.getAll().subscribe(result => {
        this.roles = result.content;
      });
    }

    this.workflowCategoryService.search('').subscribe((categories: any) => {
      this.categories = categories.content;
      this.initializeWorkflow();
    }, error2 => {
      console.error(error2);
    });

  }

  fetchWorkflowTypes = (event): Observable<any[]> => {
    return Observable.of(this.types);
  };

  initializeWorkflow() {
    this.initializeNewTransition();
    this.initializeNewRole();

    if (this.workflow === undefined && this.types.length > 0 && this.categories.length > 0) {

      if (isNil(this.workflow)) {
        this.isNew = true;

        const helperWorkflow = DtoHelper.getWorkflow();
        helperWorkflow.data.displayName = 'Neuer Workflow';
        helperWorkflow.data.type = this.types[0].value;
        helperWorkflow.data.categoryId = this.categories[0].data.id;

        this.workflowService.create(helperWorkflow.data).subscribe(result => {
          this.workflow = result;
        }, error2 => {
          console.error(error2);
        });

      }

    }
  }

  addStep() {
    const order = this.workflow.steps.sync.content ? this.workflow.steps.sync.content.length : 0;
    const step: WorkflowStep = <WorkflowStep>{
      displayName: 'Step ' + order,
      description: '',
      stateId: '',
      order: order
    };

    this.workflowStateService
    .search('')
    .flatMap((state) => {
      step.stateId = state.content[0].data.id;
      step.workflowId = this.workflow.data.id;

      return this.workflowStepService.create(step);
    })
    .subscribe(resultStepAdded => {
      this.workflow.steps.reload();
    }, error => {
      console.error(error);
    });

  }

  removeStep(step) {

    this.confirmationService.confirm({
      message: 'Den Schritt sicher lÃ¶schen?',
      header: 'LÃ¶schen',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.workflow.steps.remove(step).subscribe(result => {

          if (result.statusCode && result.statusCode >= 400) {
            /*
            https://github.com/traverson/traverson-angular#how-http-status-code-are-handled

            In contrast to AngularJS' $http service, Traverson and traverson-angular do not interpret status codes outside of
            the 2xx range as an error condition. Only network problems (host not reachable, timeouts, etc.) lead to a rejection
            of the promise, that is, only those trigger the error callback. Completed HTTP requests, even those with status 4xx
            or 5xx are interpreted as a success and trigger the success callback. This applies only to the last request in a
            traversal, HTTP requests during the traversal that respond with 4xx/5xx are interpreted as an error (because the
            traversal can not continue).
             */

            switch (result.statusCode) {
              case 409:
                this.messageService.add(
                  {
                    severity: 'error',
                    summary: 'Fehler',
                    detail: 'Der Schritt kann nicht gelÃ¶scht werden, da noch AbhÃ¤ngigkeiten bestehen.'
                  });
                break;

              default:
                this.messageService.add(
                  {
                    severity: 'error',
                    summary: 'Fehler',
                    detail: JSON.parse(result.error).message
                  });
                break;
            }

          } else {

            this.workflow.steps.reload();

          }

        }, error => {
          console.error(error);
        });
      }
    });

  }

  removeTransition(step, transition) {
    step.transitions.remove(transition).subscribe(result => {

      if (result.statusCode && result.statusCode >= 400) {
        /*
        https://github.com/traverson/traverson-angular#how-http-status-code-are-handled

        In contrast to AngularJS' $http service, Traverson and traverson-angular do not interpret status codes outside of
        the 2xx range as an error condition. Only network problems (host not reachable, timeouts, etc.) lead to a rejection
        of the promise, that is, only those trigger the error callback. Completed HTTP requests, even those with status 4xx
        or 5xx are interpreted as a success and trigger the success callback. This applies only to the last request in a
        traversal, HTTP requests during the traversal that respond with 4xx/5xx are interpreted as an error (because the
        traversal can not continue).
         */

        switch (result.statusCode) {
          case 409:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: 'Der Ãbergang kann nicht gelÃ¶scht werden, da noch AbhÃ¤ngigkeiten bestehen.'
              });
            break;

          default:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: JSON.parse(result.error).message
              });
            break;
        }

      } else {

        step.transitions.reload();

      }

    }, error => {
      console.error(error);
    });
  }

  saveNewTransition(step, transition) {

    if (transition.step) {
      transition.data.fromStepId = step.data.id;
      transition.data.toStepId = transition.step.id;
    }

    this.workflowTransitionService.create(transition.data).subscribe(
      resultTransition => {
        resultTransition.actions.update(transition.actions);
        resultTransition.notificationUsers.update(transition.notificationUsers);
        resultTransition.notificationRoles.update(transition.notificationRoles);

        step.transitions.reload();
        this.hideModals();
        this.initializeNewTransition();
      }, error => {
        console.error(error);
      });

  }

  removeRole(step, role) {
    step.steproles.remove(role).subscribe(result => {

      if (result.statusCode && result.statusCode >= 400) {
        /*
        https://github.com/traverson/traverson-angular#how-http-status-code-are-handled

        In contrast to AngularJS' $http service, Traverson and traverson-angular do not interpret status codes outside of
        the 2xx range as an error condition. Only network problems (host not reachable, timeouts, etc.) lead to a rejection
        of the promise, that is, only those trigger the error callback. Completed HTTP requests, even those with status 4xx
        or 5xx are interpreted as a success and trigger the success callback. This applies only to the last request in a
        traversal, HTTP requests during the traversal that respond with 4xx/5xx are interpreted as an error (because the
        traversal can not continue).
         */

        switch (result.statusCode) {
          case 409:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: 'Die Rolle kann nicht gelÃ¶scht werden, da noch AbhÃ¤ngigkeiten bestehen.'
              });
            break;

          default:
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Fehler',
                detail: JSON.parse(result.error).message
              });
            break;
        }

      } else {

        step.steproles.reload();

      }

    }, error => {
      console.error(error);
    });
  }

  saveNewRole(step, role) {

    const newRole =
      <WorkflowStepRole>{
        displayName: '',
        stepId: step.data.id,
        roleId: role.role.id
      };

    this.workflowStepRoleService.create(newRole).subscribe(resultRole => {
      resultRole.readRights.update(role.readRights).subscribe(() => {
        resultRole.writeRights.update(role.writeRights).subscribe(() => {
          resultRole.writeRights.update(role.writeRights).subscribe(() => {
            resultRole.transitions.update(role.transitions).subscribe(() => {
              step.steproles.reload();
              this.hideModals();
              this.initializeNewRole();
            });
          });
        });
      });
    }, error => {
      console.error(error);
    });

  }

  // step inline save methods

  saveStep = ($event, component): Observable<any> => {
    return this.workflowStepService.update(component.model.data);
  };

  saveTags = ($event, component): Observable<any> => {
    return component.model.tags.update(component.value);
  };

  saveStepActions = ($event, component): Observable<any> => {
    return component.model.actions.update(component.value);
  };

  setStateId = ($event, component): Observable<any> => {
    component.model.data.stateId = component.value.id;
    return this.workflowStepService.update(component.model.data);
  };

  setRoleId = ($event, component): Observable<any> => {
    component.model.data.roleId = component.value.data.id;
    return this.workflowStepRoleService.update(component.model.data);
  };

  setStepId = ($event, component): Observable<any> => {
    component.model.data.toStepId = component.value.data.id;
    return this.workflowTransitionService.update(component.model.data);
  };

  setRoleTransitionId = ($event, component): Observable<any> => {
    component.model.data.transitionId = component.value.id;
    return Observable.of(null);
  };

  fetchStepTransitions = ($event, component): Observable<any> => {
    if (this.editStep) {
      return this.editStep.transitions.setParams({searchTerm: $event.query}).async;
    } else {
      return Observable.of(null);
    }
  };

  addActionsFromTransitionInNewRole = ($event, component): Observable<any> => {
    for (let i = 0; i < component.value.length; i++) {
      component.value[i].actions.async.subscribe(result => {
        for (let i2 = 0; i2 < result.content.length; i2++) {
          component.model.writeRights.push(result.content[i2]);
          component.model.readRights.push(result.content[i2]);
        }

      }, error => {
        console.error(error);
      });
    }

    return Observable.of(null);
  };

  addActionsFromTransition = ($event, component): Observable<any> => {
    component.model.transitions.update(component.value);

    setTimeout(function () {
      component.model.readRights.reload();
      component.model.writeRights.reload();
    }, 200);

    return Observable.of(null);
  };

  // transition inline save methods

  saveTransition = ($event, component): Observable<any> => {
    return this.workflowTransitionService.update(component.model.data);
  };

  saveActions = ($event, component): Observable<any> => {
    const result = component.model.actions.update(component.value);

    setTimeout(function () {
      component.model.actions.reload();
      if (component.model.steproles) {
        component.model.steproles.reload();
      }
    }, 200);

    return result;
  };

  saveNotificationUsers = ($event, component): Observable<any> => {
    return component.model.notificationUsers.update(component.value);
  };

  saveMainStepRole($event, step, role) {
    if ($event) {
      step.data.mainStepRoleId = role.data.id;
    } else {
      step.data.mainStepRoleId = null;
    }

    step.save().subscribe(
      stepHalItem => {
        step = stepHalItem;
      }
    );
  }

  saveNotificationRoles = ($event, component): Observable<any> => {
    return component.model.notificationRoles.update(component.value);
  };

  saveReadRights = ($event, component): Observable<any> => {
    const result = component.model.readRights.update(component.value);

    setTimeout(function () {
      component.model.readRights.reload();
      component.model.writeRights.reload();
    }, 200);

    return result;
  };

  saveWriteRights = ($event, component): Observable<any> => {
    const result = component.model.writeRights.update(component.value);

    setTimeout(function () {
      component.model.readRights.reload();
      component.model.writeRights.reload();
    }, 200);

    return result;
  };

  saveWorkflowName = ($event, component): Observable<any> => {
    return this.workflowService.update(component.model.data);
  };

  saveWorkflowCategory = ($event, component): Observable<any> => {
    return this.workflow.updateRelations('categories', component.modelValue.map(o => o.data.id));
  };

  saveWorkflowType = ($event, component): Observable<any> => {
    component.model.data.type = component.modelValue;
    return this.workflowService.update(component.model.data);
  };

  setNewTransitionStep = ($event, component): Observable<any> => {
    this.newTransition.step = component.modelValue.data;
    return Observable.of(null);
  };

  setNewRoleRole = ($event, component): Observable<any> => {
    this.newRole.role = component.modelValue.data;
    return Observable.of(null);
  };

  fetch = ($event, inlineEdit): Observable<any> => {
    let service: HalReadServiceImpl<any, any, any>;

    switch (inlineEdit.fetchEntity) {
      case 'state':
        service = this.workflowStateService;
        break;
      case 'action':
        service = this.workflowActionService;
        break;
      case 'user':
        service = this.userService;
        break;
      case 'role':
        service = this.workflowRoleService;
        break;
      case 'steprole':
        service = this.workflowStepRoleService;
        break;
      case 'rightsWrite':
        service = this.workflowRightsService;
        break;
      case 'rightsRead':
        service = this.workflowRightsService;
        break;
      case 'permissions':
        service = this.workflowRightsService;
        break;
      case 'tag':
        service = this.workflowTagService;
        break;
      case 'category':
        service = this.workflowCategoryService;
        break;
      case 'type':
        return Observable.of(this.types);
    }

    return service.search($event.query).map(value => {
      return value.content;
    });

  };

  fetchCategories = ($event, inlineEdit): Observable<any> => {
    return this.workflowCategoryService.search($event.query).map(value => {
      return value.content;
    }, error => {
      console.error(error);
    });
  };

  fetchActions = ($event, inlineEdit): Observable<any> => {
    return this.workflowActionService.search($event.query).map(value => {
      return value.content;
    }, error => {
      console.error(error);
    });
  };

  fetchNotificationUsers = ($event, inlineEdit): Observable<any> => {
    return this.userService.search($event.query).map(value => {
      return value.content;
    }, error => {
      console.error(error);
    });
  };

  fetchNotificationRoles = ($event, inlineEdit): Observable<any> => {
    return this.workflowRoleService.search($event.query).map(value => {
      return value.content;
    }, error => {
      console.error(error);
    });
  };

  fetchSteps = ($event, inlineEdit): Observable<any> => {
    return this.workflowStepService.search($event.query).map(value => {
      return value.content;
    }, error => {
      console.error(error);
    });
  };

  fetchRoles = ($event, inlineEdit): Observable<any> => {
    return this.workflowRoleService.search($event.query).map(value => {
      return value.content;
    }, error => {
      console.error(error);
    });
  };

  fetchColor = (value, component): string => {

    const stepRole = component.model;

    // get current step handling on
    const workflow_steps = this.workflow.steps.sync.content;
    if (workflow_steps) {
      for (let wf_idx = 0; wf_idx < workflow_steps.length; wf_idx++) {
        const stepItem = workflow_steps[wf_idx];
        if (stepItem.data.id === component.model.data.stepId) {

          // transitions from current StepRole
          const stepRole_transitions = stepRole.transitions.sync.content;
          if (stepRole_transitions) {
            for (let srt_idx = 0; srt_idx < stepRole_transitions.length; srt_idx++) {
              const stepRole_transition = stepRole_transitions[srt_idx];

              // transitions from step
              const stepItem_transitions = stepItem.transitions.sync.content;
              if (stepItem_transitions) {
                const stepItem_transition = stepItem_transitions.find(
                  o => o.data.id === stepRole_transition.data.id);
                if (stepItem_transition) {

                  // actions from step
                  const stepItem_actions = stepItem_transition.actions.sync.content;
                  if (stepItem_actions) {

                    // is Step.Action == current value
                    if (stepItem_actions.find(action => action.data.id === value.data.id)) {

                      // yes? display red
                      return this.colorForActionsOfTransitions;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return '#85A0D9';
  };

  onSave() {
    this.closeClicked.emit(true);
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Den Workspace sicher verwerfen?',
      header: 'Verwerfen',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.workflowService.delete(this.workflow.data).subscribe(
          res => {
            this.closeClicked.emit(true);
          }
        );
      }
    });
  }

  goToCategories() {
    this.router.navigateByUrl('/workflowgenerator/categories');
  }

  showAddNewTransitionModal(step: any) {
    this.editStep = step;
    this.showNewTransitionModal = true;
  }

  showAddNewRoleModal(step: any) {
    this.editStep = step;
    this.showNewRoleModal = true;
  }

  hideModals() {
    this.editStep = undefined;
    this.showNewTransitionModal = false;
    this.showNewRoleModal = false;
  }
}
