import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsvsUserInfoComponent } from './components/user-info/user-info.component';
import { SharedModule } from 'primeng/shared';
import { PanelModule } from 'primeng/panel';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PanelModule
  ],
  declarations: [DsvsUserInfoComponent],
  exports: [DsvsUserInfoComponent]
})
export class DsvsUserInfoModule { }
