import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule, DropdownModule, SplitButtonModule} from 'primeng/primeng';
import {TableModule} from 'primeng/table';
import {DsvsSharedModule} from '../shared/shared.module';
import {DsvsFileDownloadService} from '../user-file-download/services/file-download.service';
import {DsvsNotificationViewComponent} from './components/notification-view/notification-view.component';
import {DsvsNotificationTypePipe} from './pipes/notification-type.pipe';
import {DsvsNotificationService} from './services/notification.service';
import {DsvsTableModule} from '../table/table.module';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ConfirmDialogModule,
    DropdownModule,
    DsvsSharedModule,
    HttpClientModule,
    DsvsTableModule,
    SplitButtonModule
  ],
  declarations: [
    DsvsNotificationViewComponent,
    DsvsNotificationTypePipe
  ],
  exports: [
    DsvsNotificationViewComponent,
    DsvsNotificationTypePipe
  ],
  providers: [
    ConfirmationService,
    DsvsNotificationService,
    DsvsFileDownloadService
  ]
})
export class DsvsNotificationModule {
}
