import { TemplateParameters, TraversonClient, TraversonResponse } from '@dsvs/traverson-ng';
import isString from 'lodash/isString';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { Observable, ObservableInput } from 'rxjs/Observable';
import { _throw } from 'rxjs/observable/throw';
import { HalClientFactory } from './client-factory.interface';
import { HalClient, HalItemConstructor, HalPageConstructor } from './client.interface';
import { HalItemImpl } from './wrapper/item.impl';
import { HalItem } from './wrapper/item.interface';
import { HalPageImpl } from './wrapper/page.impl';
import { HalPage } from './wrapper/page.interface';
import { VndErrorItemImpl } from './wrapper/vnd-error-item.impl';

type EmptyWrapperMethod<T> = (data: T) => Observable<void>;
type ItemWrapperMethod<T, R extends HalItem<T>> = (data: T) => Observable<R>;
type PageWrapperMethod<T, R extends HalItem<T>, U extends HalPage<T, R>> = (data: T) => Observable<U>;

export class HalClientImpl implements HalClient {
    constructor(private apiClient: TraversonClient,
                private readonly clientFactory: HalClientFactory,
                private readonly path: Array<string>) {
    }

    resource<T>(rel: string, templateVars?: TemplateParameters): Observable<HalItem<T>> {
        return this.typedResource<T, HalItemImpl<T>>(HalItemImpl, rel, templateVars);
    }

    typedResource<T, R extends HalItem<T>>(constructor: HalItemConstructor<T, R>,
                                           rel: string,
                                           templateVars?: TemplateParameters): Observable<R> {
        const request = this.newRequest(rel, templateVars).get<T>();
        return request.result
            .catch(this.toError)
            .flatMap(this.toItem(constructor, request));
    }

    resources<T>(rel: string, templateVars?: TemplateParameters): Observable<HalPage<T, HalItem<T>>> {
        return this.typedResources<T, HalItem<T>, HalPage<T, HalItem<T>>>(HalPageImpl, HalItemImpl, rel, templateVars);
    }

    typedResources<T, R extends HalItem<T>, U extends HalPage<T, R>>(pageConstructor: HalPageConstructor<T, R, U>,
                                                                     itemConstructor: HalItemConstructor<T, R>,
                                                                     rel: string,
                                                                     templateVars?: TemplateParameters,
                                                                     embeddedName?: string): Observable<U> {
        const request = this.newRequest(rel, templateVars).get<T>();
        return request.result
            .catch(this.toError)
            .flatMap(this.toItems(pageConstructor, itemConstructor, request, embeddedName || rel));
    }

    selfUrl(): Observable<string> {
        return this.url('self');
    }

    url(rel: string, templateVars?: TemplateParameters): Observable<string> {
        const request = this.newRequest(rel, templateVars).getUrl();
        return request.result
            .catch(this.toError);
    }

    post<T>(body: T, rel?: string, templateVars?: TemplateParameters): Observable<HalItem<T>> {
        return this.typedPost<T, HalItem<T>>(HalItemImpl, body, rel, templateVars);
    }

    typedPost<T, R extends HalItem<T>>(itemConstructor: HalItemConstructor<T, R>,
                                       body: T,
                                       rel?: string,
                                       templateVars?: TemplateParameters): Observable<R> {

        const request = this.newRequest(rel, templateVars).post<T>(body);
        return request.result
            .catch(this.toError)
            .flatMap(this.toItem(itemConstructor, request));
    }

    put<T>(body: T, rel?: string, templateVars?: TemplateParameters): Observable<HalItem<T>> {
        return this.typedPut<T, HalItem<T>>(HalItemImpl, body, rel);
    }

    typedPut<T, R extends HalItem<T>>(itemConstructor: HalItemConstructor<T, R>,
                                      body: T,
                                      rel?: string,
                                      templateVars?: TemplateParameters): Observable<R> {

        const request = this.newRequest(rel, templateVars).put<T>(body);
        return request.result
            .catch(this.toError)
            .flatMap(this.toItem(itemConstructor, request));
    }

    patch<T>(body: T, rel?: string, templateVars?: TemplateParameters): Observable<HalItem<T>> {
        return this.typedPatch<T, HalItem<T>>(HalItemImpl, body, rel);
    }

    typedPatch<T, R extends HalItem<T>>(itemConstructor: HalItemConstructor<T, R>,
                                        body: T,
                                        rel?: string,
                                        templateVars?: TemplateParameters): Observable<R> {

        const request = this.newRequest(rel, templateVars).patch<T>(body);
        return request.result
            .catch(this.toError)
            .flatMap(this.toItem(itemConstructor, request));
    }

    delete(rel?: string, templateVars?: TemplateParameters): Observable<void> {
        const request = this.newRequest(rel, templateVars)
            .delete();
        return request.result
            .catch(this.toError)
            .flatMap(this.checkForError(request));
    }

    private newRequest(rel: string, templateVars: TemplateParameters): TraversonClient {
        let client = this.apiClient.newRequest();

        if (this.path.length !== 0) {
            client = client.follow.apply(client, this.path);
        }

        if (!!rel) {
            client = client.follow(rel);
        }
        if (!!templateVars) {
            client = client.withTemplateParameters(templateVars);
        }

        return client;
    }

    private checkForError = <T>(response: TraversonResponse<T>): EmptyWrapperMethod<T> => {
        return (data: T): Observable<void> => {
            // Traverson does only consider an http error during traversal (meaning a get request) as a reason for an error.
            // So we need to check for http-errors here instead ;-)
            if (this.isHttpError(data)) {
                const extractedData = this.extractData(data);
                return _throw(new VndErrorItemImpl(<any>extractedData));
            }
            return Observable.of(null);
        };
    };

    private toItem = <T, R extends HalItem<T>>(itemConstructor: HalItemConstructor<T, R>,
                                               response: TraversonResponse<T>): ItemWrapperMethod<T, R> => {
        return (data: T): Observable<R> => {
            const extractedData = this.extractData(data);

            // Traverson does only consider an http error during traversal (meaning a get request) as a reason for an error.
            // So we need to check for http-errors here instead ;-)
            if (this.isHttpError(data)) {
                return _throw(new VndErrorItemImpl(<any>extractedData));
            }

            return response.continue().map(
                (reqResponse) => {
                    const newItem = <any>reqResponse;
                    newItem.templateParameters = {};
                    return new itemConstructor(
                        extractedData,
                        new HalClientImpl(newItem, this.clientFactory, this.path),
                        this.clientFactory
                    );
                }
            );
        };
    };

    private toItems = <T, R extends HalItem<T>, U extends HalPage<T, R>>(pageConstructor: HalPageConstructor<T, R, U>,
                                                                         itemConstructor: HalItemConstructor<T, R>,
                                                                         response: TraversonResponse<T>,
                                                                         rel: string): PageWrapperMethod<T, R, U> => {
        return (data: T): Observable<U> => {
            const extractedData = this.extractData(data);

            // Traverson does only consider an http error during traversal (meaning a get request) as a reason for an error.
            // So we need to check for http-errors here instead ;-)
            if (this.isHttpError(data)) {
                return _throw(new VndErrorItemImpl(<any>extractedData));
            }

            return response.continue().map(
                (reqResponse) => {
                    const newItem = <any>reqResponse;
                    newItem.templateParameters = {};
                    return new pageConstructor(
                        <any>extractedData,
                        new HalClientImpl(newItem, this.clientFactory, this.path),
                        this.clientFactory,
                        rel,
                        itemConstructor
                    );
                }
            );
        };
    };

    private toError = <T>(err: any, caught: Observable<T>): ObservableInput<T> => {
        if (err.name === 'HTTPError') {
            const extractedData = this.extractData(err);
            return _throw(new VndErrorItemImpl(<any>extractedData));
        }

        // Only log the error, if it is NOT an http error (for tracing needs)
        console.error('HalClientImpl#toError: Error caught ', err);

        return _throw(err);
    };

    private isHttpError<T>(data: T) {
        return (<any>data).statusCode < 200 || (<any>data).statusCode >= 300;
    }

    private extractData<T>(data: T): T {
        let extractedData: T = data;
        if (!!(<any>data).body) {
            extractedData = (<any>data).body;
        }

        if (isString(extractedData)) {
            extractedData = JSON.parse(<any>extractedData);
        }

        return extractedData;
    }

    public cloneAsEmbeddable(item: any): HalClient {
        const originalApiClient = <any>this.apiClient;

        const newApiClient = <any>this.apiClient.newRequest();

        // save original response
        const originalHttpResponse = originalApiClient.continuation.step.response;
        // clone response
        const clonedHttpResponse = originalHttpResponse.clone();
        clonedHttpResponse.url = item._links.self.href;
        clonedHttpResponse.body = JSON.stringify(item);

        // reset response
        originalApiClient.continuation.step.response = null;
        // clone continuation
        const clonedContinuation = JSON.parse(JSON.stringify(originalApiClient.continuation));

        // reset response
        originalApiClient.continuation.step.response = originalHttpResponse;

        newApiClient.templateParameters = {};
        newApiClient.continuation = clonedContinuation;
        newApiClient.continuation.step.response = clonedHttpResponse;
        newApiClient.continuation.step.url = item._links.self.href;
        newApiClient.startUrl = item._links.self.href;

        return <any>new HalClientImpl(newApiClient, this.clientFactory, this.path);
    }

}
