import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Observable, Subject } from 'rxjs';
import { DsvsToolbarItem } from '../interfaces/toolbar-item';

@Injectable()
export class DsvsBreadcrumbService {

  private menuItemsSource = new Subject<MenuItem[]>();
  private toolbarItems: DsvsToolbarItem[];
  private toolbarItemsSource = new Subject<DsvsToolbarItem[]>();
  private showToolbar = new Subject<boolean>();
  private toolClicked = new Subject<DsvsToolbarItem>();
  private resetToolbarSubmenu = new Subject<boolean>();
  private _mainToolbarItemClicked: boolean;

  menuItemsHandler = this.menuItemsSource.asObservable();
  toolbarItemsHandler = this.toolbarItemsSource.asObservable();
  showToolbarHandler = this.showToolbar.asObservable();
  toolClickedHandler = this.toolClicked.asObservable();
  resetToolbarSubmenuHandler = this.resetToolbarSubmenu.asObservable();

  private unusedObservable = null;

  constructor() {
    /* required becaus of import of Observable, otherwise import will be removed and error occure becaus auf .asObservable() */
    this.unusedObservable = new Observable();
  }

  /** MenÃ¼-Items (Sidebar) initialisieren
   * @param items */
  setMenuItems(items: MenuItem[]) {
    this.menuItemsSource.next(items);
    this.showToolbar.next(false);
  }

  /** Toolbar-Items initialisieren
   * @param items */
  setToolbarItems(items: DsvsToolbarItem[]) {
    this.toolbarItems = items;
    this.toolbarItemsSource.next(items);
  }

  /** Toolbar-Items hinzufÃ¼gen
   * @param items */
  addToolbarItems(items: DsvsToolbarItem[]) {
    this.toolbarItems.push(...items);
    this.toolbarItemsSource.next(this.toolbarItems);
  }

  /** Toolbar-Items entfernen
   * @param items*/
  removeToolbarItems(items: DsvsToolbarItem[]) {
    this.toolbarItems = this.toolbarItems.filter(item => items.find(i => item.id === i.id) === undefined);
    this.toolbarItemsSource.next(this.toolbarItems);
  }

  /** Sichtbarkeit der Toolbar
   * @param visible */
  setToolbarVisible(visible: boolean) {
    this.showToolbar.next(visible);
  }

  /** Event fÃ¼r die Subscriber auslÃ¶sen
   * @param item */
  raiseToolClickedEvent(item: DsvsToolbarItem) {
    this.toolClicked.next(item);
  }

  /** Event fÃ¼r die Subscriber durch Ã¼bergeben einer ID auslÃ¶sen
   * @param id
   * @param payload */
  raiseToolClickedEventById(id: string, payload: object = {}) {
    const nextEvent = this.toolbarItems.find(item => item.id === id);
    if (nextEvent) {
      nextEvent.payload = payload;
      this.toolClicked.next(nextEvent);
    } else {
      console.warn('Konnte Toolbar-Event ' + id + 'nicht finden.');
    }

  }

  /** Den disabled-Status eine Toolbar-Items Ã¤ndern
   * @param id
   * @param disabled
   * @param subitem */
  setToolbarItemDisabled(id: string, disabled: boolean, subitem: boolean = false) {
    if (subitem) {
      for (const tbItem of this.toolbarItems) {
        if (tbItem.submenu) {
          const actItem: DsvsToolbarItem = tbItem.submenu.find(item => item.id === id);
          if (actItem) {
            tbItem.submenu.find(item => item.id === id).disabled = disabled;
          }
        }
      }
    } else {
      this.toolbarItems.find(item => item.id === id).disabled = disabled;
    }
    this.setToolbarItems(this.toolbarItems);
  }

  /** Submenu in der Toolbar wieder ausblenden
   *  @param reset */
  resetSubmenu(reset: boolean) {
    if (!this.mainToolbarItemClicked) {
      this.resetToolbarSubmenu.next(reset);
    }
    this.mainToolbarItemClicked = false;
  }

  /** Flag wenn ein Toolbar-Item mit einem SubmenÃ¼ abgeklickt wurde
   * damit nicht auch der Wrapper-Click ausgelÃ¶st wird und somit
   * das SubmenÃ¼ nicht gleich wieder geschlossen wird
   * @returns
   */
  get mainToolbarItemClicked(): boolean {
    return this._mainToolbarItemClicked;
  }

  set mainToolbarItemClicked(value: boolean) {
    this._mainToolbarItemClicked = value;
  }
}
