import { Injectable } from '@angular/core';
import { DsvsAppSettings } from '../interfaces/app-settings';
import { Resolve } from '@angular/router';
import { DsvsSettingsService } from '../services/settings-service';
import { Observable } from 'rxjs';

@Injectable()
export class DsvsSettingsResolver implements Resolve<DsvsAppSettings> {

  constructor(private optService: DsvsSettingsService) { }

  /** Resolver zum Preload der Anwendungseinstellungen
   * @returns
   */
  resolve(): Observable<DsvsAppSettings> {
    return this.optService.getAppSettings();
  }

}
