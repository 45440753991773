import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { UploadPartHalItem, UploadPartHalPage, WorkflowUploadPart } from '@dsvs/workflow-generator-dto';
import { MappedHalCrudServiceImpl, HalRelation, WG_V1_CLIENT_TOKEN } from '../../../hal';
import { WorkflowGeneratorTypeMappingService } from '../config/workflow-generator-type-mapping.service';

export interface WorkflowUploadPartService extends DsvsSearchableCrudEntityService<WorkflowUploadPart> {

}

// @dynamic
@Injectable()
export class WorkflowUploadPartServiceImpl
  extends MappedHalCrudServiceImpl<WorkflowUploadPart, UploadPartHalItem, UploadPartHalPage>
  implements WorkflowUploadPartService {

  constructor(
    @Inject(WG_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    typeResolver: WorkflowGeneratorTypeMappingService
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'uploadpart', collection: 'uploadparts'},
      WorkflowGeneratorTypeMappingService.WorkflowUploadPart,
      typeResolver
    );
  }
}

@Injectable()
export class WorkflowUploadPartServiceMock
  extends DsvsSearchableCrudEntityServiceMock<WorkflowUploadPart>
  implements WorkflowUploadPartService {
  constructor() {
    super();

    this.data = [];

    for (let i = 1; i <= 100; i++) {
      this.data.push(
        <WorkflowUploadPart>{
          id: String(i),
          displayName: ' ' + i
        }
      );
    }
  }
}
