import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DsvsSharedUiLibConfigService } from '../../dsvs-shared-ui-lib-config.service';
import { DsvsErrorHandler } from '../../error/classes/dsvs-error-handler';
import { DsvsInstituteData } from '../interfaces/institute-data';
import { DsvsUserData } from '../interfaces/user-data';

@Injectable()
export class DsvsAuthorizationService {

  /** Error-Handling */
  errorHandler: DsvsErrorHandler;
  /** Flag und Subject fÃ¼r Login-Status */
  isLoggedIn: boolean;
  /** Anwenderdaten  */
  userData: DsvsUserData;
  /** Services URL fÃ¼r Backend */
  private endpointRoot: string;
  private loginState = new Subject();
  private onLogoutSubject = new Subject<void>();

  constructor(private http: HttpClient, private configService: DsvsSharedUiLibConfigService) {
  }

  /** Methode zur Abfrage des aktuellen Login-Zustands und der
   * PrÃ¼fung des Status bei erfolgtem Login
   * @returns
   */
  checkLogin(): boolean {
    this.errorHandler = new DsvsErrorHandler();
    if (sessionStorage.getItem('currentUser')) {
      /** UserData-Object in SessionStorage verfÃ¼gbar */
      this.userData = JSON.parse(sessionStorage.getItem('currentUser'));
      if (this.userData.success) {
        this.isLoggedIn = true;
        sessionStorage.setItem('jwt', this.userData.result.jwt);
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
        this.errorHandler.hasError = true;
        this.errorHandler.errType = 'error';   // ZAP-Anmeldung fehlgeschlagen
        this.errorHandler.msgTitle = 'Die Anmeldung im ZAP ist fehlgeschlagen.';
        this.errorHandler.msgBody = this.userData.msg;
      }
      return this.isLoggedIn;
    } else {
      return false;
    }
  }

  /** Methode fÃ¼r das Nutzerlogin
   * @param ssoid die vom APO Ã¼bergebene SSOID*/
  login(ssoid: string) {

    this.errorHandler = new DsvsErrorHandler();
    this.setLoginDone(false);
    /** Auth-Key als base-64 encoded string vorbereiten */
    const authKey = btoa(this.configService.config.communication.scope + ':' + this.configService.config.communication.zapinit);
    const headers = {
      'authorization': `Basic ${authKey}`,
      'content-type': 'application/json; charset=utf-8'
    };

    // tslint:disable-next-line
    const zapUrl = this.configService.config.communication.zapurl + 'getssodata/portal/' + this.configService.config.communication.scope + '/';

    // TODO: https://stackoverflow.com/questions/47261758/angular-httpclient-request-with-retry-and-delay-is-sending-an-extra-request-then
    this.http.get<DsvsUserData>(zapUrl + ssoid, {headers: headers})/*.retry(3)*/.subscribe(udata => {
      this.userData = udata;
      // console.log(this.userData);
      sessionStorage.setItem('currentUser', JSON.stringify(this.userData));
      this.isLoggedIn = this.userData.success;
    }, (e: HttpErrorResponse) => {
      this.isLoggedIn = false;
      this.errorHandler.hasError = true;
      this.errorHandler.errType = 'error';
      this.errorHandler.msgTitle = 'Beim Aufruf des ZAP-Sevices fÃ¼r das Single-Sign-On ist ein Fehler aufgetreten:';
      this.errorHandler.msgBody = e.message;
      this.setLoginDone(true);
    }, () => {
      this.setLoginDone(true);
    });
  }

  /** Methode zur Initialisierung des Observables fÃ¼r
   * das Triggern des Abschlusses des Login-Prozesses */
  setLoginDone(state: boolean) {
    this.loginState.next(state);
  }

  /** Observable fÃ¼r die Ãberwachung des Login-Prozesses */
  getLoginDone(): Observable<any> {
    return this.loginState.asObservable();
  }

  onLogout(): Observable<void> {
    return this.onLogoutSubject.asObservable();
  }

  /** Methode fÃ¼r das Logout des angemeldeten Nutzers */
  logout(): Promise<boolean> {
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('jwt');
    this.isLoggedIn = false;
    this.errorHandler.clear();
    /* ggf. TODO Backend Ã¼ber Logout informieren */

    this.onLogoutSubject.next();
    return Promise.resolve(this.isLoggedIn);
  }

  /** Nutzerdaten auf Basis des UserData-Interfaces aus dem Session-Storage abrufen */
  getUserData(): DsvsUserData {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  /** Institutsdaten auf Basis des UserData-Interfaces aus dem Session-Storage abrufen */
  getInstituteData(): DsvsInstituteData {
    const udata: DsvsUserData = JSON.parse(sessionStorage.getItem('currentUser'));
    return udata.result.institute;
  }

  /** JSON Web-Token abrufen */
  getJWT(): string {
    return sessionStorage.getItem('jwt');
  }

  /** Nutzer-Anrede in der Form "Vorname Nachname" abrufen */
  getSalutation(): string {
    if (this.checkLogin()) {
      return this.getUserData().result.firstname + ' ' + this.getUserData().result.lastname;
    } else {
      return 'Gast';
    }
  }

  /** Die Liste mit allen Nutzerrechten abrufen */
  getUserRights(): string[] {
    if (this.getUserData().success) {
      return this.getUserData().result.userrights;
    }
  }

  /** Ein spezifisches Nutzerrecht abfrageb */
  hasRight(right: string): boolean {
    const userData: DsvsUserData = this.getUserData();
    if (userData && userData.result) {
      return (this.getUserData().result.userrights.indexOf(right.toUpperCase()) > -1);
    }
    return false;
  }

  /** !!!!!!!!!!!!!!!!!!!!!!! ENTWICKLERTESTS !!!!!!!!!!!!!!!!!!!!!!!!!
   * NOPRODUCTION
   */
  createDummyNotification() {
    let headers = new HttpHeaders().set('authorization', `Bearer ${this.getJWT()}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get<boolean>(this.endpointRoot + 'notification/user/generatedefault', {headers}).subscribe();
  }

}
