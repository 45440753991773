import { Component, Input, OnInit } from '@angular/core';
import { ActionContextHalItem, ProcessedActionHalItem } from '@dsvs/workflow-generator-dto';

@Component({
  selector: 'wfg-workflow-action-history',
  template: `<p>
  workflow-action-history works!
</p>
`,
  styles: [``]
})
export class WorkflowActionHistoryComponent implements OnInit {

  @Input() actionContext: ActionContextHalItem;

  payload: any;
  processedAction: ProcessedActionHalItem;

  ngOnInit() {
    if (this.actionContext) {
      this.actionContext.processedactions.async.subscribe(
        actionEvents => {

          // Get first actionEvent for alias
          const actionEvent = actionEvents.content.find(
            ae => ae.data && this.actionContext.data && ae.data.alias === this.actionContext.data.alias);

          this.processedAction = actionEvent;
          if (actionEvent) {
            try {

              this.payload = JSON.parse(actionEvent.data.payload);
              this.payloadChanged(this.payload);

            } catch (e) {
              console.error('Could not parse ActionHistoryEvent: ', e);
            }
          }
        }
      );
    } else {
      console.error('No ActionContext found');
    }
  }

  payloadChanged(payload) {
  }

}
