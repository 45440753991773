import {Injectable} from '@angular/core';
import {
  ActionHalItem,
  ActionHalPage,
  ActionRightHalItem,
  ActionRightHalPage,
  CategoryHalItem,
  CategoryHalPage,
  CrewHalItem,
  CrewHalPage,
  GroupHalItem,
  GroupHalPage,
  ProductHalItem,
  ProductHalPage,
  RightsHalItem,
  RightsHalPage,
  RoleHalItem,
  RoleHalPage,
  RoleUserHalItem,
  RoleUserHalPage,
  StateHalItem,
  StateHalPage,
  StepHalItem,
  StepHalPage,
  StepRoleHalItem,
  StepRoleHalPage,
  TagHalItem,
  TagHalPage,
  TransitionHalItem,
  TransitionHalPage,
  UploadPartHalItem,
  UploadPartHalPage,
  UserHalItem,
  UserHalPage,
  WorkflowHalItem,
  WorkflowHalPage
} from '@dsvs/workflow-generator-dto';
import {TypeMappingServiceImpl} from '../../../hal/type-mapping-impl.service';

// Needed to register Service Types to corresponding Workflow-Models
@Injectable({
  providedIn: 'root'
})
export class WorkflowGeneratorTypeMappingService extends TypeMappingServiceImpl {
  public static readonly Workflow = 'Workflow';
  public static readonly WorkflowAction = 'WorkflowAction';
  public static readonly WorkflowActionRight = 'WorkflowActionRight';
  public static readonly WorkflowCategory = 'WorkflowCategory';
  public static readonly WorkflowCrew = 'WorkflowCrew';
  public static readonly WorkflowRoleUser = 'WorkflowRoleUser';
  public static readonly WorkflowGroup = 'WorkflowGroup';
  public static readonly WorkflowProduct = 'WorkflowProduct';
  public static readonly WorkflowRight = 'WorkflowRight';
  public static readonly WorkflowRole = 'WorkflowRole';
  public static readonly WorkflowState = 'WorkflowState';
  public static readonly WorkflowStep = 'WorkflowStep';
  public static readonly WorkflowStepRole = 'WorkflowStepRole';
  public static readonly WorkflowTag = 'WorkflowTag';
  public static readonly WorkflowTransition = 'WorkflowTransition';
  public static readonly WorkflowUser = 'WorkflowUser';
  public static readonly WorkflowUploadPart = 'WorkflowUploadPart';

  constructor() {
    super();
    this.registerType(WorkflowGeneratorTypeMappingService.Workflow, WorkflowHalItem, WorkflowHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowAction, ActionHalItem, ActionHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowActionRight, ActionRightHalItem, ActionRightHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowCategory, CategoryHalItem, CategoryHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowCrew, CrewHalItem, CrewHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowGroup, GroupHalItem, GroupHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowRoleUser, RoleUserHalItem, RoleUserHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowProduct, ProductHalItem, ProductHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowRight, RightsHalItem, RightsHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowRole, RoleHalItem, RoleHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowState, StateHalItem, StateHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowStep, StepHalItem, StepHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowStepRole, StepRoleHalItem, StepRoleHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowTag, TagHalItem, TagHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowTransition, TransitionHalItem, TransitionHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowUser, UserHalItem, UserHalPage);
    this.registerType(WorkflowGeneratorTypeMappingService.WorkflowUploadPart, UploadPartHalItem, UploadPartHalPage);
  }
}
