import { Injectable } from '@angular/core';
import 'rxjs/add/operator/last';
import { AsyncSubject } from 'rxjs/AsyncSubject';
import * as JsonHalAdapter from 'traverson-hal/browser/dist/traverson-hal';
import * as traverson from 'traverson/browser/dist/traverson';
import { AbortHandle } from './abort-handle';
import { TraversonNgAdapter } from './traverson.adapter';
import { TraversonClient, TraversonResponse } from './traverson.definitions';

@Injectable({
    providedIn: 'root'
})
export class TraversonService {
    private configuredClient: TraversonClient;

    constructor(
        private traversonNgAdaptor: TraversonNgAdapter
    ) {
        this.configuredClient = null;
        this.registerHal();
        this.extendTraverson();
    }

    createClient(url: string): TraversonClient {
        return (<any>traverson
        .from(url)
        .jsonHal())
        .useAngularHttp();
    }

    private registerHal() {
        traverson.registerMediaType(JsonHalAdapter.mediaType, JsonHalAdapter);
    }

    private extendTraverson() {
        let promisify = this.promisify;
        let Builder = (<any>traverson)._Builder;
        let traversonNgAdaptor = this.traversonNgAdaptor;

        if (Builder.prototype.useAngularHttp != undefined) {
            console.warn(
                'Traverson was already extended - skipping TraversonService#extendTraverson(). ' +
                'If this message is printed outside of a test context, you are loading this library more than once.'
            );
        } else {
            let originalMethods = {
                get: Builder.prototype.get,
                getResource: Builder.prototype.getResource,
                getUrl: Builder.prototype.getUrl,
                post: Builder.prototype.post,
                put: Builder.prototype.put,
                patch: Builder.prototype.patch,
                delete: Builder.prototype.delete
            };

            Builder.prototype.get = function () {
                return promisify(this, originalMethods.get, []);
            };

            Builder.prototype.getResource = function () {
                return promisify(this, originalMethods.getResource, []);
            };

            Builder.prototype.getUrl = Builder.prototype.getUri = function () {
                return promisify(this, originalMethods.getUrl, []);
            };

            Builder.prototype.post = function (body) {
                return promisify(this, originalMethods.post, [body]);
            };

            Builder.prototype.put = function (body) {
                return promisify(this, originalMethods.put, [body]);
            };

            Builder.prototype.patch = function (body) {
                return promisify(this, originalMethods.patch, [body]);
            };

            Builder.prototype.delete = Builder.prototype.del = function () {
                return promisify(this, originalMethods.delete, []);
            };
        }

        Builder.prototype.useAngularHttp = function () {
            this.withRequestLibrary(traversonNgAdaptor);
            return this;
        };
    }

    private promisify(that, originalMethod: Function, argsArray): TraversonResponse<any> {
        let bSubject = new AsyncSubject<any>();

        argsArray = argsArray || [];

        let traversal;
        let callback = function (err, result, _traversal) {
            if (err) {
                err.result = result;
                bSubject.error(err);
                bSubject.complete();
            } else {
                traversal = _traversal;
                bSubject.next(result);
                bSubject.complete();
            }
        };

        argsArray.push(callback);

        let traversalHandler: AbortHandle = originalMethod.apply(that, argsArray);
        const lastValue = bSubject.last();

        return {
            result: lastValue,
            continue: () => lastValue.map(() => traversal.continue()),
            abort: traversalHandler.abort,
            subscribe: lastValue.subscribe.bind(bSubject)
        };
    }
}
