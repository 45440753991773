import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {interval, Observable, Subject, Subscription} from 'rxjs';
import {DsvsSharedUiLibConfigService} from '../../dsvs-shared-ui-lib-config.service';
import {DsvsErrorHandler} from '../../error/classes/dsvs-error-handler';
import {DsvsNotificationData} from '../interfaces/notification-data';

@Injectable()
export class DsvsNotificationService {

  /** Error-Handler fÃ¼r Fehlermeldungen */
  errorHandler: DsvsErrorHandler = new DsvsErrorHandler();

  /** Flag ob der Notification-Service bereits initialisiert wurde
   * und die Gesamtliste der Benachrichtigung nach der Anmeldung ausgelesen wurde
   */
  isInitialized: boolean;

  /** Flag ob die Notification-View Komponente aktiv ist
   * falls ja muss in der Topbar kein Routing erfolgen
   * wenn ein Notification-MenuItem geclickt wird, es muss
   * dann aber der notificationItemClicked getriggert werden
   */
  isInNotificationView: boolean;

  /** Settings fÃ¼r den Notification-Service ggf. spÃ¤ter Ã¼ber
   * die globalen App-Settings aus ZAP auslesen */
  private pollInterval: number;   // Polling alle 15 Sekunden

  /** private Subjects fÃ¼r die Notification Ãberwachung */
  private notificationIncomeTrigger = new Subject<Boolean>();       // Es sind zur Laufzeit neue Notifications eingegangen
  private notificationRefreshTrigger = new Subject<Boolean>();      // Das Notifications-MenÃ¼ muss aktualisiert werden
  private notificationItemClickedTrigger = new Subject<number>();  // Im Notification-MenÃ¼ wurde ein Item angeclickt

  /** private Subscriptions fÃ¼r die Notification Ãberwachung */
  private pollTimer: Observable<any>;
  private pollTimerSubscription: Subscription;
  private pollServiceSubscription: Subscription;

  /** Service HÃ¤ndler, werden von den Komponenten Abonniert */
  notificationAlert = this.notificationIncomeTrigger.asObservable();            // Es sind zur Laufzeit neue Benachrichtigungen eingegangen
  notificationRefresh = this.notificationRefreshTrigger.asObservable();         // Das Notifications-MenÃ¼ muss aktualisiert werden
  notificationItemClicked = this.notificationItemClickedTrigger.asObservable(); // Ein Item im Notifications-MenÃ¼ wurde abgeclickt die
                                                                                // aktuelle ID wird eingestellt (fÃ¼r die automatische
                                                                                // Auswahl in der Notification View)

  /** Gesamt-Liste der Benachrichtigungen */
  notificationListTotal: DsvsNotificationData[] = [];
  /** Liste der ungelesenen Nachrichten */
  notificationListUnread: DsvsNotificationData[] = [];
  /** Anzahl der ungelesenen neuen in der Liste Nachrichten */
  newNotificationCount: number;

  constructor(private http: HttpClient, private configService: DsvsSharedUiLibConfigService) {
  }

  /** Services-URL fÃ¼r Backend */
  private getEndpoint(): string {
    return this.configService.config.communication.backend;
  }

  /** Alle Notifications fÃ¼r den angemeldeten User auslesen
   * @param jwt
   */
  initializeUserNotifications(jwt: string) {
    this.stopPolling();

    this.newNotificationCount = 0;
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get<DsvsNotificationData[]>(this.getEndpoint() +
      'notification/user/getall?sort=createdDate,DESC', {headers}).subscribe(data => {
      if (data.length > 0) {
        /** Alle Nachrichten als abgeholt markieren */
        this.markNotificationsAsFetched(jwt);
      }
      this.notificationListTotal = [];
      this.notificationListUnread = [];
      for (const notify of data) {
        if (notify.readType === 0) {
          /** alte ungelesene Nachrichten */
          this.notificationListUnread.push(notify);
        }
        if (notify.readType === -1) {
          /** neue nachrichten */
          this.newNotificationCount++;
          notify.readType = 0;
          this.notificationListUnread.push(notify);
        }
        this.notificationListTotal.push(notify);
      }
      if (!this.isInitialized) {
        this.isInitialized = true;
        this.notificationRefreshTrigger.next(true);
      }
      this.startPolling(jwt);
    }, (e: HttpErrorResponse) => {
      this.errorHandler.hasError = true;
      this.errorHandler.errType = 'warn';
      this.errorHandler.msgTitle = 'Benachrichtigungsservice';
      // tslint:disable-next-line
      this.errorHandler.msgBody = 'Die Initialisierung des Services ist fehlgeschlagen. Sollte dies wiederholt auftreten, so wenden Sie sich bitte an den Support.<br>Fehler: ' + e.message;
      // console.log(e);
      this.notificationRefreshTrigger.next(false);
      this.stopPolling();
    });
  }

  /** Polling des Benachrichtigungsservices starten
   * @param jwt
   */
  startPolling(jwt: string) {
    // console.log('Notification Polling gestartet!');
    this.pollInterval = 15000;
    this.pollTimer = interval(this.pollInterval);
    this.pollTimerSubscription = this.pollTimer.subscribe(() => {
      // console.log('trigger');
      this.pollServiceSubscription = this.checkNewNotifications(jwt).subscribe(result => {
        if (result) {
          // console.log('Neue Notifications vorhanden');
          this.notificationIncomeTrigger.next(true);
        }
        this.pollServiceSubscription.unsubscribe();
      }, (e: HttpErrorResponse) => {
        // console.log(e);
        this.pollServiceSubscription.unsubscribe();
      });
    });
  }

  /** Polling des Benachrichtigungsservices stoppen */
  stopPolling() {
    if (this.pollTimerSubscription) {
      this.pollTimerSubscription.unsubscribe();
      // console.log('Notification Polling gestoppt!');
    }
  }

  /** REST-Service Aufruf zur PrÃ¼fung ob neue Nachrichten vorhanden sind
   * @param jwt
   * @returns
   */
  checkNewNotifications(jwt: string): Observable<boolean> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<boolean>(this.getEndpoint() + 'notification/user/hasnew', {headers});
  }

  /** Die wÃ¤hrend der Session neu eingegangenen Notifications abrufen
   * @param jwt
   * @returns
   */
  getNewNotifications(jwt: string): Observable<DsvsNotificationData[]> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<DsvsNotificationData[]>(this.getEndpoint() + 'notification/user/getnew', {headers});
  }

  /** Die zuvor Ã¼ber den Abonennten (topbar.component.ts) abgefragten neuen Benachrichtigungen
   * mÃ¼ssen zur Anzeige in der Topbar Ã¼ber diese Funktion an den Anfang der NotificationListen
   * eingefÃ¼gt werden
   * @param  newNotifications
   */
  addNewNotifications(newNotifications: DsvsNotificationData[]) {
    this.notificationListTotal = [...newNotifications, ...this.notificationListTotal];
    this.notificationListUnread = [...newNotifications, ...this.notificationListUnread];
  }

  /** Neue abgeholte Notifications als "abgeholt" amrkieren
   * @param jwt
   * @returns
   */
  markNotificationsAsFetched(jwt: string) {
    // console.log('Mark as fetched');
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get(this.getEndpoint() + 'notification/user/markasfetched', {headers}).subscribe(result => {
      /** ggf. TODO */
      if (result) {
        /* Nachrichten wurden erfolgreich als abgeholt markiert */
      } else {
        /* Nachrichten konnten nicht erfolgreich als abgeholt markiert werden, ggf. das Polling stoppen und Hinweis bringen */
      }
    });
  }

  /** Benachrichtigung als gelesen markieren (rekursiv bei mehreren Notifications)
   * @param jwt
   * @param nfID
   * @param index
   */
  markNotificationAsRead(jwt: string, nfID: number[], index: number = 0) {
    const maxIndex: number = nfID.length - 1;
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get(this.getEndpoint() + 'notification/user/markasread/' + nfID[index], {headers}).subscribe(result => {
      if (result) {
        if (index < maxIndex) {
          index++;
          this.markNotificationAsRead(jwt, nfID, index);
        } else {
          this.stopPolling();
          this.initializeUserNotifications(jwt);
        }
      } else {
        /* TODO Nachrichten konnten nicht als gelesen markiert werden, ggf. das Polling stoppen und Hinweis bringen */
      }
    });
  }

  /** Benachrichtigung als ungelesen markieren (rekursiv bei mehreren Notifications)
   * @param jwt
   * @param nfID
   * @param index
   */
  markNotificationAsUnread(jwt: string, nfID: number[], index: number = 0) {
    const maxIndex: number = nfID.length - 1;
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get(this.getEndpoint() + 'notification/user/markasunread/' + nfID[index], {headers}).subscribe(result => {
      if (result) {
        if (index < maxIndex) {
          index++;
          this.markNotificationAsUnread(jwt, nfID, index);
        } else {
          this.stopPolling();
          this.initializeUserNotifications(jwt);
        }
      } else {
        /* TODO Nachrichten konnten nicht als ungelesen markiert werden, ggf. das Polling stoppen und Hinweis bringen */
      }
    });
  }

  /** Benachrichtigung als gelÃ¶scht markieren (rekursiv bei mehreren Notifications)
   * @param jwt
   * @param nfID
   * @param index
   */
  markNotificationAsDeleted(jwt: string, nfID: number[], index: number = 0) {
    const maxIndex: number = nfID.length - 1;
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get(this.getEndpoint() + 'notification/user/markasdeleted/' + nfID[index], {headers}).subscribe(result => {
      // if (result) {
      if (index < maxIndex) {
        index++;
        this.markNotificationAsDeleted(jwt, nfID, index);
      } else {
        this.stopPolling();
        this.initializeUserNotifications(jwt);
      }
      // } else {
      //   /* TODO Nachrichten konnten nicht gelÃ¶scht werden, ggf. das Polling stoppen und Hinweis bringen */
      // }
    }, error1 => {
      /* TODO Nachrichten konnten nicht gelÃ¶scht werden, ggf. das Polling stoppen und Hinweis bringen */
    });
  }

  /** Den Event fÃ¼r den Click eif ein Notification-Menu-Item (Glocke) triggern
   * @param nfID
   */
  triggerMenuItemClick(nfID: number) {
    this.notificationItemClickedTrigger.next(nfID);
  }
}
